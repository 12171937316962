import { Typography } from 'components';

import { CloseCircleIcon } from 'lib/Icons';
import { FC, HTMLAttributes, MouseEvent, PropsWithChildren, ReactNode } from 'react';
import { cn } from 'utils/cn';

interface ITag extends PropsWithChildren<HTMLAttributes<HTMLDivElement>> {
  onMouseOver?: (e: MouseEvent<HTMLDivElement>) => void;
  onMouseLeave?: (e: MouseEvent<HTMLDivElement>) => void;
  onRemove?: (tagToTemove: string) => void;
  onClick?: () => void;
  classNameTypography?: string;
  classNameCloseIcon?: string;
  classNameRemove?: string;
  startAdornment?: ReactNode;
}

const Tag: FC<PropsWithChildren<ITag>> = ({
  onRemove,
  onMouseOver,
  onMouseLeave,
  onClick,
  className,
  classNameTypography,
  classNameCloseIcon,
  classNameRemove,
  startAdornment,
  children
}) => {
  const handleOnRemove = (e: MouseEvent<HTMLOrSVGElement>) => {
    e.stopPropagation();
    onRemove?.(children as string);
  };

  return (
    <div
      className={cn(
        'cursor-pointer select-none transition-all ease-out hover:scale-[1.02]',
        'tolq-tag flex items-center justify-between rounded-md p-[2px] px-2',
        'bg-secondary-red',
        className
      )}
      onMouseOver={onMouseOver}
      onMouseLeave={onMouseLeave}
      onClick={onClick}
    >
      {startAdornment && <>{startAdornment}</>}
      <Typography
        variant="regular5"
        className={cn('w-fit break-all !p-0 text-white', classNameTypography)}
      >
        {children}
      </Typography>
      {!!onRemove && (
        <>
          <div className={cn('relative h-2 w-4', classNameRemove)}>
            <CloseCircleIcon
              onClick={handleOnRemove}
              className={cn(
                'absolute bottom-[-8px] scale-[0.7] !text-white  duration-75 hover:scale-[0.75]',
                classNameCloseIcon
              )}
            />
          </div>
        </>
      )}
    </div>
  );
};

export default Tag;
