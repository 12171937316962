'use client';

import { HoverPopover, Typography } from 'components';

import { useTranslations } from 'next-intl';
import { FC, useCallback, useEffect } from 'react';
import Next from './Next';
import Prev from './Prev';

export interface PaginationSimpleProps {
  totalPages: number;
  page: number;
  changePage: (page: number) => void;
}

interface IPaginationSimple extends PaginationSimpleProps {
  className?: string;
  textPopover?: string;
}

const PaginationSimple: FC<IPaginationSimple> = ({ totalPages, page, className, changePage }) => {
  const t = useTranslations();

  const handlerChangePage = useCallback(
    (page: number) => page > 0 && page <= totalPages && changePage(page),
    [changePage, totalPages]
  );

  const handleKeyPress = useCallback(
    (e: KeyboardEvent) => {
      if (e.key === 'ArrowLeft') return handlerChangePage(page - 1);
      if (e.key === 'ArrowRight') return handlerChangePage(page + 1);
      return;
    },
    [handlerChangePage, page]
  );

  useEffect(() => {
    document.addEventListener('keydown', handleKeyPress);

    return () => document.removeEventListener('keydown', handleKeyPress);
  }, [handleKeyPress, handlerChangePage, page]);

  if (totalPages < 2) return null;

  const paginationPaginationSimpleProps = {
    totalPages,
    page,
    changePage: handlerChangePage
  };

  return (
    <nav className={className}>
      <ul className={`flex !h-[28px] flex-row !gap-0`}>
        <HoverPopover msg={t('components.paging_simple.popover')} positions={['top']}>
          <Prev {...paginationPaginationSimpleProps} />
        </HoverPopover>
        <Typography variant="medium4" className="select-none text-tertiary-200">
          {page}/{totalPages}
        </Typography>
        <HoverPopover msg={t('components.paging_simple.popover')} positions={['top']}>
          <Next {...paginationPaginationSimpleProps} />
        </HoverPopover>
      </ul>
    </nav>
  );
};

export default PaginationSimple;
