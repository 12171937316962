import Appsignal from '@appsignal/javascript';
// import { plugin as pluginBreadcrumbsConsole } from '@appsignal/plugin-breadcrumbs-console';
import { plugin as pluginPathDecorator } from '@appsignal/plugin-path-decorator';
import { ESpansException } from 'types/ESpans';

export const appsignal = new Appsignal({
  key: process.env.NEXT_PUBLIC_APPSIGNAL_KEY,
  revision: process.env.NEXT_PUBLIC_VERCEL_GIT_COMMIT_SHA
});

export const notifyException = (
  error: any,
  name: ESpansException = ESpansException.GenericException,
  params?: any
) => {
  const isError = error instanceof Error;
  const errorResult = isError ? error : new Error('Unknown error');
  const span = appsignal.createSpan((span) => {
    if (!isError) span.setParams(error);

    if (params) span.setParams(params);

    if (error?.name === 'Error') error.name = name;
    else span.setAction(name);

    if (error?.code)
      span.setTags({
        name: error.code
      });

    return span.setError(errorResult);
  });

  try {
    appsignal.send(span);
  } catch (e) {
    appsignal.sendError(error);
  }
};

appsignal.use(pluginPathDecorator());
// appsignal.use(pluginBreadcrumbsConsole());
