'use client';
import {
  ComboboxButton,
  Combobox as ComboboxHeadlessUI,
  ComboboxInput,
  ComboboxOption,
  ComboboxOptions,
  Transition
} from '@headlessui/react';
import { CheckIcon, ChevronUpDownIcon } from '@heroicons/react/20/solid';
import { Typography } from 'components';
import { CloseIcon } from 'lib/Icons';
import { useTranslations } from 'next-intl';
import { cn } from 'utils/cn';

import {
  ChangeEvent,
  FC,
  Fragment,
  HTMLAttributes,
  PropsWithChildren,
  useEffect,
  useMemo,
  useState
} from 'react';

import { BLANK_OPTION } from 'constants/blank';
import { FieldError } from 'react-hook-form';
import { Option } from 'types/Option';
import { compareObj } from 'utils/compareObj';
import './styles.scss';

export interface ICombobox
  extends Omit<PropsWithChildren<HTMLAttributes<HTMLSelectElement>>, 'onChange'> {
  /*
   * Error of the input
   * accepts FieldError
   *
   * @example
   * {
   *  type: 'required',
   * message: 'This field is required'
   * }
   *
   */
  error?: FieldError | boolean;
  /**
   * Options
   * accepts array of object with label and value both as string
   */
  options?: Option[];
  /**
   * Selected Options
   * accepts array of object with label and value both as string
   */
  selectedOptions?: string[];
  /**
   * Selected value
   * @default ''
   * */
  defaultOption?: Option;
  /**
   * Placeholder
   * @default undefined
   * */
  placeholder?: string;
  /**
   * Widest height
   * @default undefined
   * */
  wideHeight?: boolean;
  /**
   * Hide close feature
   * @default undefined
   * */
  noClose?: boolean;
  /**
   * Selected value
   * @default undefined
   * */
  selectedValue?: string | boolean | object;
  /**
   * Button disabled
   * @default false
   */
  disabled?: boolean;
  /**
   * Clean on select
   * @default false
   */
  cleanOnSelect?: boolean;
  /**
   * Optional change handler
   * @default () => {}
   */
  onChange?(value?: Option): void;
}

const Combobox: FC<ICombobox> = ({
  selectedValue,
  options: unprocessedOptions,
  className,
  defaultOption,
  selectedOptions,
  wideHeight,
  noClose,
  placeholder,
  cleanOnSelect = false,
  error,
  disabled,
  onChange
}) => {
  const t = useTranslations();
  const options = useMemo(
    () => (!!unprocessedOptions?.length ? unprocessedOptions : [BLANK_OPTION]),
    [unprocessedOptions]
  );

  const availableFirstOption =
    options.find(
      (option) => !option.hidden && !option.disabled && !selectedOptions?.includes(option.label)
    ) ?? (options?.at(0) as Option);

  const initialOption = placeholder ? undefined : defaultOption ?? availableFirstOption;

  const [selected, setSelected] = useState(initialOption);
  const [query, setQuery] = useState('');

  const isDefault =
    (defaultOption && selected?.label === defaultOption?.label) ||
    (!defaultOption && selected?.label === availableFirstOption?.label);

  const filtredOptions =
    query === '' || query === ' '
      ? options
      : options.filter((person) => {
          return (
            !person.hidden &&
            !person.disabled &&
            (String(person.value).toLowerCase().includes(query.toLowerCase()) ||
              String(person.label).toLowerCase().includes(query.toLowerCase()))
          );
        });

  const handleOnChange = (value?: Option | null) => {
    if (cleanOnSelect && query) setQuery('');
    if (value) setSelected(value);

    if (value === null) return onChange?.(undefined);

    return onChange?.(value);
  };

  function compareBy(a: Option, b: Option) {
    return String(a?.value).toLowerCase() === String(b?.value).toLowerCase();
  }

  const handleClean = () => handleOnChange(initialOption);

  const handleQuery = (e: ChangeEvent<HTMLInputElement>) => setQuery(e.target.value);

  const handleDisplay = (option: Option) => {
    if (option?.value === '') return '';

    return String(option?.label);
  };

  useEffect(() => {
    if (selectedValue || typeof selectedValue === 'boolean') {
      const selectedOption = options.find((option) => {
        if (!option.hidden && !option.disabled && !selectedOptions?.includes(option.label)) {
          if (typeof option.value === 'boolean') return option.value === selectedValue;

          if (typeof option.value === 'string') return option.value === selectedValue;

          if (
            typeof option.value === 'object' &&
            option.value !== null &&
            !Array.isArray(option.value)
          )
            return compareObj(option.value, selectedValue as object);
        }

        return undefined;
      });

      if (selectedOption) return setSelected(selectedOption);
    }

    return initialOption && setSelected(initialOption);
  }, [initialOption, options, selectedOptions, selectedValue]);

  return (
    <div className="container-input">
      <>
        <ComboboxHeadlessUI
          value={cleanOnSelect ? undefined : selected}
          onChange={handleOnChange}
          by={selected ? compareBy : undefined}
          disabled={disabled}
          immediate
        >
          <div className={cn('relative mb-2 w-full', className)}>
            <div className={cn('combobox-container', error ? 'error-combobox' : '')}>
              <ComboboxInput
                className="combobox-input"
                displayValue={handleDisplay}
                onChange={handleQuery}
                placeholder={placeholder}
                autoComplete="off"
                autoCorrect="off"
                {...(cleanOnSelect && { value: query })}
              />
              <ComboboxButton className="absolute inset-y-0 right-0 flex items-center pr-[6px]">
                <ChevronUpDownIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
              </ComboboxButton>
            </div>
            <Transition
              as={Fragment}
              leave="transition ease-in duration-100"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <ComboboxOptions className={cn('option-list', wideHeight && '!max-h-[25rem]')}>
                {filtredOptions.length === 0 && query !== '' ? (
                  <Typography
                    variant="light4"
                    className="flex cursor-default select-none justify-center bg-tertiary-700 py-[6px] pr-4 text-tertiary-200"
                  >
                    {t('components.combobox.nothing_found')}
                  </Typography>
                ) : (
                  <>
                    {filtredOptions.map((option, optionsId) => {
                      if (option.hidden) return null;

                      const isSelected = selectedOptions?.includes(option.label);
                      const disabled = option.disabled || isSelected;

                      return (
                        <ComboboxOption
                          key={optionsId}
                          className={({ active }) =>
                            `relative cursor-pointer select-none py-2 pl-10 pr-4 ${
                              active ? 'bg-blue-100  text-primary-blue-100' : 'text-gray-900'
                            }`
                          }
                          value={option}
                          disabled={disabled}
                        >
                          {({ selected }) => (
                            <>
                              <Typography
                                variant={selected ? 'regular4' : 'light4'}
                                className="text-gray-900"
                              >
                                {option.label}
                              </Typography>
                              {selected || isSelected ? (
                                <span className="absolute bottom-0 left-0 top-1 flex items-center pl-3 text-primary-blue-100">
                                  <CheckIcon className="h-5 w-5" aria-hidden="true" />
                                </span>
                              ) : null}
                            </>
                          )}
                        </ComboboxOption>
                      );
                    })}
                  </>
                )}
              </ComboboxOptions>
            </Transition>
          </div>
        </ComboboxHeadlessUI>
        {!noClose && !isDefault && (
          <div onClick={handleClean} className="input-icon-close">
            <span>
              <CloseIcon />
            </span>
          </div>
        )}
      </>
    </div>
  );
};

export default Combobox;
