'use client';
import { FC, useCallback, useMemo } from 'react';
import Checkbox from '../Checkbox';

export interface ISelectItemInput {
  /*
   * value of the input
   */
  value?: string[];
  /*
   * onChange function of the input
   */
  code?: string;
  /*
   * code it's the identifier of the input
   */
  name?: string;
  /*
   * name it's the name of the input
   */
  onChange?: (values: string[]) => void;
}

const SelectItemInput: FC<ISelectItemInput> = ({
  value = [],
  code,
  name,
  onChange
}): JSX.Element => {
  const valueMemoized = useMemo(() => value, [value]);

  const handleOnChange = useCallback(() => {
    if (code) {
      const result = valueMemoized.includes(code)
        ? valueMemoized.filter((valueMemoized) => valueMemoized !== code)
        : [...valueMemoized, code];

      if (onChange) onChange(result);
    }
  }, [code, onChange, valueMemoized]);

  const checked = useMemo(() => {
    if (!code) return false;

    return valueMemoized.includes(code);
  }, [code, valueMemoized]);

  return <Checkbox checked={checked} onChange={handleOnChange} name={name} />;
};

export default SelectItemInput;
