import { getDayEnd } from './getDayEnd';

const dateIsYesterday = (timestamp = '', showYesterday?: boolean) => {
  if (!showYesterday) false;

  const today = new Date();
  const yesterday = new Date(today);
  yesterday.setDate(today.getDate() - 1);

  const dateIsEnd = getDayEnd(new Date(timestamp).toISOString());
  const dateYesterdayEnd = getDayEnd(yesterday.toISOString());

  return dateIsEnd === dateYesterdayEnd;
};

export const dateTimeShort = (
  timestamp = '',
  showYesterday?: boolean,
  t?: (key: string) => string
) => {
  const [date, time] = new Intl.DateTimeFormat('en-US', {
    month: 'short',
    day: 'numeric',
    hour: 'numeric',
    minute: 'numeric',
    hour12: true
  })
    .format(new Date(timestamp ?? ''))
    ?.split(',');

  const [month, day] = date.split(' ');

  const dateWritten = dateIsYesterday(timestamp, showYesterday)
    ? t?.('utils.yesterday')
    : `${day} ${month}`;

  return `${dateWritten}, ${time}`;
};
