'use client';

import axios, { AxiosHeaders, AxiosRequestConfig, RawAxiosRequestConfig } from 'axios';
import { DataToast } from 'components';
import extractFiles from 'extract-files/extractFiles.mjs';
import isExtractableFile from 'extract-files/isExtractableFile.mjs';
import { first } from 'lodash';
import { toast } from 'react-toastify';
import { notifyException } from 'services/appsignal';
import { ESpansException } from 'types/ESpans';
import { TError } from 'types/TError';
import { TGenericError } from 'types/TGenericError';
import { buildHeadersUtil } from 'utils/buildHeadersUtil';
import { getLocale } from './getLocale';
import i18nUtil from './i18nUtil';

type GraphqlPayload = {
  operationName: string;
  query: string;
  variables: any;
};

type FileMap = {
  [n: number]: [string];
};

const findOperationName = (gql: string) => {
  const indexOfParenthesis = gql.indexOf('(');
  const indexOfBraces = gql.indexOf('{');

  if (
    indexOfParenthesis !== -1 ||
    (indexOfParenthesis !== -1 && indexOfParenthesis < indexOfBraces)
  ) {
    const indexOfSpaceNearParenthesis = Array(...gql).reduce(
      (acc, curr, index) => (curr === ' ' && indexOfParenthesis - index > acc ? index : acc),
      0
    );

    return gql
      .substring(indexOfSpaceNearParenthesis + 1, indexOfParenthesis)
      .split(' ')
      .join('')
      .split('{\n')
      .join('');
  }

  const indexOfQuery = gql.indexOf('query ');

  const result = gql
    .substring(indexOfQuery + 5, indexOfBraces)
    .split(' ')
    .join('')
    .split('{\n')
    .join('');

  return result;
};

const getGenericData = (data: GraphqlPayload) => {
  const { files } = extractFiles(data?.variables, isExtractableFile);

  if (!files.size) return data;

  let i = 0;
  const formData = new FormData();
  const fileMap: FileMap = {};

  files.forEach((paths: string[], file: any) => {
    const currentInddex = ++i;

    formData.append(`${currentInddex}`, file as Blob, file.name);

    const path = first(paths);
    fileMap[currentInddex] = [`variables.${path}`];
  });

  formData.append('map', JSON.stringify(fileMap));
  formData.append('operations', JSON.stringify(data));

  return formData;
};

const getConfig = (config: AxiosRequestConfig<{ gql: string; variables?: object }>) => {
  if (!config?.data?.gql) return {};

  const data: GraphqlPayload | FormData = {
    operationName: findOperationName(config?.data?.gql as string),
    query: config?.data?.gql as string,
    variables: config?.data?.variables
  };

  return {
    method: 'post',
    url: '/graphql',
    data: getGenericData(data)
  };
};

const clientUtil = (baseURL?: string) => {
  const api = axios.create({
    headers: {},
    baseURL: baseURL ?? process.env.NEXT_PUBLIC_API_URL
  });

  api.interceptors.request.use(
    async (config: RawAxiosRequestConfig<{ gql: string; variables?: object }>) => {
      // Do something before request is sent

      const headers = await buildHeadersUtil();

      return {
        ...config,
        headers: {
          ...config?.headers,
          ...headers
        } as AxiosHeaders,
        ...getConfig(config)
      };
    },
    function (error) {
      // Do something with request error
      return Promise.reject(error);
    }
  );

  //TODO: Add a response interceptor
  api.interceptors.response.use(
    async function (response) {
      // Any status code that lie within the range of 2xx cause this function to trigger
      // Do something with response data
      if (response.data?.errors?.length) {
        const errors = (response.data?.errors as TError[]).map((e) => e.message);

        const payload = { success: false, errors, errorCode: response?.data?.errorCode };
        notifyException(payload, ESpansException.APIErrorWith200);

        return Promise.reject(payload);
      }

      const data = response.data?.data && Object.entries(response.data?.data);
      const contentData = data?.at(0)?.at(1) as unknown as TGenericError;

      const content: TGenericError =
        !contentData && response.config.url === '/graphql'
          ? { success: false, errors: [(await i18nUtil(getLocale())).t('messages.error.unknown')] }
          : contentData;

      if (content?.success === false) {
        notifyException(content, ESpansException.APIErrorWith200);

        return Promise.reject(content);
      }

      if (data?.length === 1 && response.config.url === '/graphql') return contentData as any;
      if (data?.length > 1 && response.config.url === '/graphql') return response.data?.data as any;

      return response;
    },
    async function (error) {
      // Any status codes that falls outside the range of 2xx cause this function to trigger
      // Do something with response error
      if (error?.response?.status === 401) {
        if (typeof window !== 'undefined' && !window.location.href.includes('/auth')) {
          toast.error(DataToast, {
            data: {
              content: (await i18nUtil(getLocale())).t('users.member_remove.errors.unauthorized')
            }
          });
          // window.location.href = '/auth/sign-out';
        }
      }

      notifyException(error, ESpansException.APIErrorWithout200);
      return Promise.reject(error);
    }
  );

  return api;
};

export default clientUtil;
