'use client';
import { Tab } from '@headlessui/react';
import { Tag, Typography } from 'components';
import { TypographyVariant } from 'components/Typography';
import { cn } from 'utils/cn';

import { useRouter } from 'next/navigation';
import { FC, HTMLAttributes, PropsWithChildren, useEffect } from 'react';

interface ITab extends Omit<PropsWithChildren<HTMLAttributes<HTMLElement>>, 'onClick'> {
  /**
   * key
   */
  index: number;

  /**
   * Text contents
   */
  label: string;
  /**
   * Button disabled
   */
  disabled?: boolean;
  /**
   * Optional click handler
   */
  onClick?: (index?: number) => void;
  /**
   * Optional href
   */
  href?: string;
  /**
   * Scale variant a number that comprehend the major of the variant
   */
  scaleVariant?: string;
  /**
   * Badges style of tags
   */
  badges?: boolean;
  /**
   * SmallGap reduce the size of gap
   */
  smallGap?: boolean;
}

const TabCustom: FC<ITab> = ({
  index,
  label,
  onClick,
  href = '',
  scaleVariant = '2',
  smallGap,
  badges,
  ...rest
}) => {
  const router = useRouter();

  const handleClick = () => {
    if (href) return router.push(href);

    if (onClick) return onClick(index);
  };

  useEffect(() => {
    if (href) router.prefetch(href);
  }, [href, router]);

  return (
    <Tab
      className={cn('focus:outline-none active:outline-none')}
      key={index}
      onClick={handleClick}
      {...rest}
    >
      {({ selected }) => (
        <>
          {!badges ? (
            <div
              className={cn(
                'flex flex-col',
                'mb-0 border-b-4 border-transparent focus:outline-none active:outline-none dark:text-white',
                'min-w-fit transition-colors duration-500 ease-in-out',
                selected
                  ? 'border-b-primary-green-100'
                  : 'border-transparent hover:border-b-tertiary-600/50',
                smallGap ? 'h-9 gap-1 ' : 'h-10 gap-6'
              )}
            >
              <div className="flex flex-col">
                <Typography
                  variant={
                    (selected
                      ? `medium${scaleVariant}`
                      : (`regular${scaleVariant}` as any)) as TypographyVariant
                  }
                  className={cn(
                    selected ? `text-tertiary-100 dark:text-white ` : 'text-tertiary-300  ',
                    'inline-block max-w-full truncate '
                  )}
                >
                  {label}
                </Typography>
                <Typography variant={'medium2'} className={'inline-block h-0 max-w-full truncate'}>
                  {label}
                </Typography>
              </div>
            </div>
          ) : (
            <Tag
              className={cn(
                'w-fit rounded-4xl hover:!scale-[1]',
                selected ? 'bg-primary-green-100' : 'bg-tertiary-600'
              )}
              classNameTypography={cn('!text-sm', selected ? '˜text-white' : '!text-tertiary-200')}
              classNameCloseIcon={cn('!text-sm', selected ? '˜text-white' : '!text-tertiary-200')}
            >
              {label}
            </Tag>
          )}
        </>
      )}
    </Tab>
  );
};

export default TabCustom;
