'use client';

import { Menu, Transition } from '@headlessui/react';
import { DARK } from 'constants/constants';
import { DARK_MODE_PERSISTED_STATE } from 'constants/persistedStates';
import usePersistentContext from 'hooks/usePersistenceState';
import Link from 'next/link';
import { cloneElement, FC, Fragment, ReactElement } from 'react';
import UseAnimations from 'react-useanimations/';
import { Animation } from 'react-useanimations/utils';
import theme from 'theme.json';
import { cn } from 'utils/cn';

interface IDropdown {
  children?: ReactElement<{ open?: boolean }>;
  header?: ReactElement<{ open?: boolean }>;
  items?: {
    name?: string;
    href?: string;
    icon?: Animation;
    content?: ReactElement;
    onClick?: () => void;
  }[];
  className?: string;
  hidden?: boolean;
  noHighlight?: boolean;
  itemsRow?: boolean;
  arrow?: boolean;
}

const Dropdown: FC<IDropdown> = ({
  children,
  header,
  items,
  noHighlight,
  itemsRow,
  arrow,
  className,
  hidden
}) => {
  const [darkMode] = usePersistentContext(DARK_MODE_PERSISTED_STATE);
  const isDarkMode = darkMode === DARK;

  return (
    <Menu as="div" className={cn('relative inline-block text-left', { 'opacity-0': hidden })}>
      <Menu.Button
        className={cn(
          'outline-none',
          'group flex w-full items-center rounded-md p-2 text-sm',
          noHighlight ? '' : 'hover:bg-primary-blue-200 hover:bg-opacity-25'
        )}
      >
        {(props) => <>{cloneElement(children ?? <></>, props)}</>}
      </Menu.Button>
      <Transition
        as={Fragment}
        enter="transition ease-out duration-600"
        enterFrom="transform opacity-0 scale-95"
        enterTo="transform opacity-100 scale-100"
        leave="transition ease-in duration-75"
        leaveFrom="transform opacity-100 scale-100"
        leaveTo="transform opacity-0 scale-95"
      >
        <Menu.Items
          className={cn(
            'absolute right-0 z-50 w-56 origin-top-right',
            'divide-y',
            'divide-gray-100 rounded-4xl bg-white shadow-lg dark:bg-gray-700',
            'outline-none ring-black ring-opacity-5',
            'text-gray-700 dark:text-gray-200',
            'cursor-pointer',
            'mt-4',
            className
          )}
        >
          <div className="flex flex-col">
            {arrow && (
              <div
                className={cn('absolute rotate-45 bg-white', '!h-5 !w-5', 'right-4', 'top-[-8px]')}
              />
            )}
            {header}
            <div
              className={cn(
                'flex p-1',
                itemsRow ? 'flex-row flex-wrap gap-2 !p-2 pt-0' : 'flex-col'
              )}
            >
              {items?.map(({ href, name, icon, content, onClick }, index) => (
                <Menu.Item key={'menu-item' + index} as={Fragment}>
                  {({ active }) => {
                    const Content = () =>
                      content ? (
                        <>{content}</>
                      ) : (
                        <>
                          {name}
                          {icon && (
                            <UseAnimations
                              animation={icon}
                              className="pointer-events-none"
                              strokeColor={
                                isDarkMode
                                  ? theme.extend.colors.tertiary['600']
                                  : theme.extend.colors.quaternary['100']
                              }
                            />
                          )}
                        </>
                      );

                    const classname = cn(
                      'ui-active:bg-blue-500 ui-active:text-white ui-not-active:bg-white ui-not-active:text-black',
                      'group items-center  rounded-md text-sm',
                      'flex w-full justify-between  dark:hover:text-white',
                      noHighlight ? '' : 'p-2 hover:bg-gray-100 dark:hover:bg-gray-600'
                    );

                    return (
                      <span>
                        {href ? (
                          <Link
                            href={href}
                            className={cn(
                              classname,
                              '!text-tertiary-100',
                              active ? 'bg-gray-100 dark:bg-gray-600 ' : ''
                            )}
                          >
                            <Content />
                          </Link>
                        ) : (
                          <button
                            onClick={onClick}
                            className={cn(classname, active ? 'bg-gray-100 dark:bg-gray-600' : '')}
                          >
                            <Content />
                          </button>
                        )}
                      </span>
                    );
                  }}
                </Menu.Item>
              ))}
            </div>
          </div>
        </Menu.Items>
      </Transition>
    </Menu>
  );
};

export default Dropdown;
