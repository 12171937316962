import { FC, HTMLAttributes, PropsWithChildren } from 'react';
import { cn } from 'utils/cn';

type IHint = HTMLAttributes<HTMLSpanElement>;

const Hint: FC<PropsWithChildren<IHint>> = ({ children, className, ...rest }) => {
  return (
    <span
      className={cn('inline-block text-[0.72rem] text-tertiary-200 capitalize-first ', className)}
      dangerouslySetInnerHTML={{ __html: children ?? '' }}
      {...rest}
    />
  );
};

export default Hint;
