'use client';

import { AnimatePresence, motion, Variants } from 'framer-motion';
import { FC, HTMLAttributes, PropsWithChildren } from 'react';

interface ITransition extends PropsWithChildren<HTMLAttributes<HTMLDivElement>> {
  yInitial?: number;
  duration?: number;
  delay?: number;
}

const Transition: FC<PropsWithChildren<ITransition>> = ({
  yInitial = 100,
  duration = 0.75,
  delay = 0.25,
  children,
  ...rest
}) => {
  const variants: Variants = {
    inactive: {
      opacity: 1,
      y: 0,
      transition: {
        duration,
        delay
      }
    },
    out: {
      opacity: 0,
      y: -1 * yInitial,
      transition: {
        duration,
        delay
      }
    },
    in: {
      y: yInitial,
      opacity: 0,
      transition: {
        duration,
        delay
      }
    }
  };

  return (
    <div className="overflow-hidden" {...rest}>
      <AnimatePresence mode="wait">
        <motion.div variants={variants} initial="in" animate="inactive" exit="out">
          {children}
        </motion.div>
      </AnimatePresence>
    </div>
  );
};

export default Transition;
