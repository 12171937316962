import { useQuery, UseQueryOptions } from '@tanstack/react-query';
import useAuth from 'hooks/useAuth';
import { TGenericError } from 'types/TGenericError';

type TData = string;

export const useCurrentAuthTime = (
  options: Omit<UseQueryOptions<unknown, TGenericError, TData>, 'queryKey'> = {}
) => {
  const auth = useAuth();

  return useQuery<unknown, TGenericError, TData>({
    ...options,
    queryKey: ['currentAuthTime'],
    queryFn: async () => {
      const currentSession = await auth.currentSession();
      return new Date(currentSession.getAccessToken().payload.auth_time * 1000).toISOString();
    }
  });
};
