'use client';
import { FC, ReactNode } from 'react';

import { ToastContentProps, TypeOptions } from 'react-toastify';
import Typography from './Typography';

export interface IDataToast {
  title?: string;
  content?: string | ReactNode;
}

const DataToast: FC<ToastContentProps<IDataToast>> = ({ data, toastProps: { type } }) => {
  const typesText = {
    success: 'Success',
    info: 'Info',
    error: 'Error',
    warning: 'Warning'
  } as Record<TypeOptions, string>;

  const title = data?.title ?? typesText[type];

  return (
    <div className="mb-4 mt-1 flex cursor-pointer flex-col">
      <Typography
        variant="medium1"
        className="cursor-pointer select-none leading-8 text-tertiary-100 dark:text-white"
      >
        {title}!
      </Typography>
      <Typography variant="light4" className="cursor-pointer select-none leading-4">
        {typeof data?.content === 'object' ? (
          data?.content
        ) : (
          <span dangerouslySetInnerHTML={{ __html: String(data?.content) }} />
        )}
      </Typography>
    </div>
  );
};

export default DataToast;
