'use client';

import {
  Combobox as ComboboxHeadlessUI,
  Input,
  Popover,
  Select,
  Transition
} from '@headlessui/react';
import { CheckIcon } from '@heroicons/react/20/solid';
import { Tag, Typography } from 'components';
import { BLANK_OPTION } from 'constants/blank';
import { BACKSPACE, ENTER } from 'constants/keyboard';
import { ArrowSelectIcon, CloseIcon, SearchIcon } from 'lib/Icons';
import { useTranslations } from 'next-intl';
import {
  ChangeEvent,
  FC,
  Fragment,
  HTMLAttributes,
  KeyboardEvent,
  PropsWithChildren,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState
} from 'react';
import { FieldError } from 'react-hook-form';
import { Option } from 'types/Option';
import { cn } from 'utils/cn';

import './styles.scss';

export interface ISelectTags
  extends Omit<PropsWithChildren<HTMLAttributes<HTMLSelectElement>>, 'onChange'> {
  /*
   * Error of the input
   * accepts FieldError
   *
   * @example
   * {
   *  type: 'required',
   * message: 'This field is required'
   * }
   *
   */
  error?: FieldError | boolean;
  /**
   * Options
   * accepts array of object with label and value both as string
   */
  options?: Option[];
  /**
   * Selected Options
   * accepts array of object with label and value both as string
   */
  max?: number;
  /**
   * Max number of selected options
   * @default Infinity
   * */
  removeFrom?: number;
  /**
   * Min number of index, if less than min, it will not have remove
   * @default Infinity
   * */
  selectedOptions?: string[];
  /**
   * Show the selected tags.
   * @default true
   * */
  showSelected?: boolean;
  /**
   * Placeholder
   * @default undefined
   * */
  placeholder?: string;
  /**
   * Placeholder search
   * @default undefined
   * */
  placeholderSearch?: string;
  /**
   * Widest height
   * @default undefined
   * */
  labelCount?: string;
  /**
   * Label count in below of search
   * @default undefined
   * */
  categoryOptions?: Option[];
  /**
   * Label of options to be selected in below of search
   * @default undefined
   * */
  category?: string;
  /**
   * Selected category to be selected in below of search
   * @default undefined
   * */
  wideHeight?: boolean;
  /**
   * Hide close feature
   * @default undefined
   * */
  noClose?: boolean;
  /**
   * Button disabled
   * @default false
   */
  disabled?: boolean;
  /**
   * Clean on select
   * @default false
   */
  noBlankOption?: boolean;
  /**
   * Empty options
   * @default false
   */
  cleanOnSelect?: boolean;
  /**
   * Hide arrow
   * @default false
   */
  noArrow?: boolean;
  /**
   * Optional change handler
   * @default false
   */
  classNameTags?: string;
  /**
   * Optional className for tags
   * @default ''
   */
  classNameTagsTypography?: string;
  /**
   * Optional className for tags typography
   * @default ''
   */
  classNamePanel?: string;
  /**
   * Optional className for Panel
   * @default ''
   */
  behaviorSelectedOptions?: 'filter' | 'highlight';
  /**
   * Optional behavior of selected optios
   * @default 'filter'
   */
  onChange?(value?: string[]): void;
  /**
   * Optional delete handler
   * @default () => {}
   */
  onDelete?(value?: string): void;
  /**
   * Optional add handler
   * @default () => {}
   */
  onAdd?(value?: string): void;
  /**
   * Optional add tag handler
   * @default undefined
   */
  onAddTag?(value?: string): void;
  /**
   * Optional add tag handler
   * @default undefined
   */
  onSelectCategory?(value?: string): void;
}

const SelectTags: FC<PropsWithChildren<ISelectTags>> = ({
  options: unprocessedOptions,
  selectedOptions = [],
  behaviorSelectedOptions = 'filter',
  showSelected = true,
  wideHeight,
  noClose,
  disabled: disabledIncoming,
  placeholder,
  placeholderSearch,
  labelCount,
  categoryOptions,
  category,
  cleanOnSelect = false,
  max = Infinity,
  className,
  classNameTags,
  classNamePanel,
  classNameTagsTypography,
  error,
  removeFrom = -1,
  noBlankOption,
  noArrow,
  onChange,
  onDelete,
  onAdd,
  onSelectCategory,
  onAddTag
}) => {
  const t = useTranslations();
  const options = useMemo(
    () => (!!unprocessedOptions?.length ? unprocessedOptions : noBlankOption ? [BLANK_OPTION] : []),
    [unprocessedOptions, noBlankOption]
  );

  const [selected, setSelected] = useState<Option | undefined>();
  const [hasMoreThanOneLine, setHasMoreThanOneLine] = useState(false);
  const [inputFocused, setInputFocused] = useState(false);
  const [query, setQuery] = useState('');
  const [input, setInput] = useState('');

  const refButton = useRef<HTMLButtonElement>(null);
  const refPopButton = useRef<HTMLButtonElement>(null);

  const disabled = selectedOptions?.length >= (max ?? Infinity) || disabledIncoming;

  const filteredSelectedOptions = useMemo(
    () =>
      behaviorSelectedOptions === 'filter'
        ? options.filter((o) => !selectedOptions.includes(String(o?.value)))
        : options,
    [options, selectedOptions, behaviorSelectedOptions]
  );

  const filtredOptions =
    query === '' || query === ' '
      ? filteredSelectedOptions
      : filteredSelectedOptions.filter(
          (person) =>
            !person?.hidden &&
            !person?.disabled &&
            (String(person?.value).toLowerCase().includes(query.toLowerCase()) ||
              String(person?.label).toLowerCase().includes(query.toLowerCase()))
        );

  const handleInputKeyDown = useCallback(
    async (e: KeyboardEvent<HTMLButtonElement>) => {
      if (onAddTag) {
        e.preventDefault();
        if (e.key?.length === 1) setInput((oldState) => oldState + e.key);

        if (e.key === BACKSPACE) setInput((oldState) => oldState.slice(0, -1));

        if (e.key === ENTER) {
          onAddTag?.(input);
          setInput('');
        }
      }
    },
    [onAddTag, input]
  );

  const handleInputOnBlur = useCallback(() => {
    if (onAddTag) {
      onAddTag?.(input);
      setInput('');
      setInputFocused(false);
    }
  }, [setInput, input]);

  const handleInputOnClick = () => setInputFocused(true);

  const handlerSelectTags = (option: Option) => {
    onAdd?.(option?.value as string);
    onChange?.([...selectedOptions, String(option?.value)]);
  };

  const handlerRemoveTags = (option: Option) => () => {
    onDelete?.(option?.value as string);
    onChange?.(selectedOptions.filter((o) => o !== option?.value));
  };

  const handleOnChange = useCallback(
    (value?: Option) => {
      setQuery('');
      if (selectedOptions.includes(String(value?.value))) {
        setSelected(undefined);
        return handlerRemoveTags(value as Option)();
      }

      if (value) {
        setSelected(value);
        return handlerSelectTags(value);
      }
    },
    [selectedOptions, setSelected, handlerRemoveTags, handlerSelectTags]
  );

  function compareBy(a: unknown, z: unknown) {
    return !!(
      a &&
      z &&
      String((a as Option)?.value).toLowerCase() === String((z as Option)?.value).toLowerCase()
    );
  }

  const handleClean = () => handleOnChange();

  const handleQuery = (e: ChangeEvent<HTMLInputElement>) =>
    e.target.value && e.target.value !== 'undefined' && setQuery(e.target.value);

  const handleSelectCategory = (e: ChangeEvent<HTMLSelectElement>) =>
    onSelectCategory?.(e.target.value);

  const handleDisplay = (option: Option) => {
    if (option?.value === '') return '';

    return String(option?.label);
  };

  const handleOpen = () => refButton.current && refButton.current.click();

  useEffect(() => {
    setHasMoreThanOneLine(
      !!(
        !!selectedOptions?.length &&
        refPopButton?.current?.clientHeight &&
        refPopButton?.current?.clientHeight > 36
      )
    );
  }, [selectedOptions?.length]);

  return (
    <Popover className={cn('relative w-full')}>
      {({ open }) => (
        <>
          <Popover.Button
            as={'button'}
            className={cn(
              'select-tags-button',
              error ? 'error-tags' : '',
              hasMoreThanOneLine ? 'py-[6px]' : '',
              className
            )}
            ref={refPopButton}
            onKeyDown={handleInputKeyDown}
            onBlur={handleInputOnBlur}
            disabled={disabled}
          >
            <div className="flex flex-1 flex-wrap gap-2 py-0">
              {showSelected &&
                selectedOptions?.map((option, idx) => {
                  const notNewOption = onAddTag
                    ? { label: option, value: option }
                    : options.find((o) => o?.value === option);

                  if (!notNewOption) return null;

                  return (
                    <Tag
                      key={notNewOption?.label}
                      onRemove={idx > removeFrom ? handlerRemoveTags(notNewOption) : undefined}
                      className={cn('bg-tertiary-600', classNameTags)}
                      classNameTypography={cn('!text-tertiary-200', classNameTagsTypography)}
                      classNameCloseIcon={cn('!text-tertiary-200', classNameTagsTypography)}
                    >
                      {notNewOption?.label}
                    </Tag>
                  );
                })}
              {!onAddTag && (
                <Typography
                  variant="light4"
                  className="select-none text-tertiary-400 dark:text-tertiary-200"
                >
                  {placeholder}
                </Typography>
              )}
              {onAddTag && (
                <Input
                  type="text"
                  placeholder={placeholder}
                  value={input || ''}
                  className={cn(
                    'font-content font-light',
                    '!box-border h-6 min-w-[100px] rounded-full bg-tertiary-800 !p-2 outline-none',
                    'focus:bg-tertiary-900 focus:ring-1 focus:ring-primary-blue-900',
                    inputFocused || input?.length > 0
                      ? 'bg-tertiary-900 ring-1 ring-primary-blue-900'
                      : ''
                  )}
                  autoFocus
                  autoComplete="off"
                  style={{ width: `${input?.length}ch` }}
                  onClick={handleInputOnClick}
                  disabled={disabled}
                />
              )}
            </div>
            {!noArrow && (filtredOptions?.length > 0 || (!filtredOptions?.length && !onAddTag)) && (
              <div
                className={cn(
                  'arrow-select-tags transition-rotate relative shrink-0 duration-500 ease-out',
                  open ? 'rotate-180' : 'rotate-0'
                )}
              >
                <ArrowSelectIcon className="absolute" />
              </div>
            )}
          </Popover.Button>
          {(options?.length > 0 || (!options?.length && !onAddTag)) && (
            <Transition
              show={!disabled && open}
              as={Fragment}
              enter="transition ease-out duration-200"
              enterFrom="opacity-0 translate-y-1"
              enterTo="opacity-100 translate-y-0"
              leave="transition ease-in duration-150"
              leaveFrom="opacity-100 translate-y-0"
              leaveTo="opacity-0 translate-y-1"
            >
              <Popover.Panel
                className={cn('absolute left-1/2 z-10 w-full -translate-x-1/2', classNamePanel)}
              >
                <div className="mb-4 mt-1 overflow-hidden rounded-md shadow-custom-light">
                  <div className="relative flex gap-8 bg-white p-2">
                    {!!options?.length || onSelectCategory ? (
                      <div className="relative w-full">
                        <ComboboxHeadlessUI
                          onChange={handleOnChange}
                          by={selected ? compareBy : undefined}
                        >
                          <div className={cn('relative w-full', className)}>
                            <div className="select-tags-container">
                              <ComboboxHeadlessUI.Input
                                className="select-tags-input pr-16"
                                value={query || ''}
                                displayValue={handleDisplay}
                                onChange={handleQuery}
                                onClick={handleOpen}
                                placeholder={placeholderSearch}
                                autoComplete="off"
                                autoCorrect="off"
                                {...(cleanOnSelect && { value: query })}
                              />
                              <ComboboxHeadlessUI.Button
                                className="absolute inset-y-0 right-0 flex items-center pr-[6px]"
                                ref={refButton}
                                disabled={disabled}
                              >
                                <SearchIcon />
                              </ComboboxHeadlessUI.Button>
                              {!noClose && (
                                <ComboboxHeadlessUI.Button
                                  onClick={handleClean}
                                  className="input-icon-close z-[100]"
                                >
                                  <span>
                                    <CloseIcon />
                                  </span>
                                </ComboboxHeadlessUI.Button>
                              )}
                            </div>
                            <Transition
                              as={Fragment}
                              show
                              enter="transition duration-100 ease-out"
                              enterFrom="transform scale-95 opacity-0"
                              enterTo="transform scale-100 opacity-100"
                              leave="transition duration-75 ease-out"
                              leaveFrom="transform scale-100 opacity-100"
                              leaveTo="transform scale-95 opacity-0"
                            >
                              <ComboboxHeadlessUI.Options className={'overflow-hidden'} static>
                                {labelCount && (
                                  <div className="sticky flex justify-between">
                                    <div className="mt-2 flex gap-2">
                                      <Typography variant="light5" className=" text-tertiary-200">
                                        {labelCount}:
                                      </Typography>
                                      <Typography variant="medium5" className="text-tertiary-100">
                                        {selectedOptions?.length ?? 0}
                                      </Typography>
                                    </div>
                                  </div>
                                )}
                                {!!categoryOptions?.length && (
                                  <div className="sticky flex justify-between font-light">
                                    <div className="mt-2 flex gap-2">
                                      <Select
                                        onChange={handleSelectCategory}
                                        className="font-content text-xs text-tertiary-200 outline-none"
                                        value={category}
                                        disabled={disabled}
                                      >
                                        {categoryOptions?.map(({ label, value }) => (
                                          <option key={label} value={value as string}>
                                            {label}
                                          </option>
                                        ))}
                                      </Select>
                                    </div>
                                  </div>
                                )}
                                <div
                                  className={cn(
                                    'option-list !relative !m-0 !mt-2 !p-0 !shadow-none',
                                    wideHeight && '!max-h-[25rem]'
                                  )}
                                >
                                  {filtredOptions.length === 0 &&
                                  (query !== '' || category !== '') ? (
                                    <Typography
                                      variant="light4"
                                      className="flex cursor-default select-none justify-center bg-tertiary-700 py-[6px] pr-4 text-tertiary-200"
                                    >
                                      {t('components.select_tags.nothing_found')}
                                    </Typography>
                                  ) : (
                                    <>
                                      {filtredOptions.map((option, optionsId) => {
                                        const isSelected = selectedOptions?.includes(
                                          String(option.value)
                                        );
                                        const isDisabled = option.disabled;

                                        if (option.hidden) return null;

                                        return (
                                          <ComboboxHeadlessUI.Option
                                            disabled={isDisabled}
                                            key={optionsId}
                                            className={({ active }) =>
                                              cn(
                                                'relative cursor-pointer select-none py-2 pl-1 pr-4',
                                                active
                                                  ? 'bg-blue-100  text-primary-blue-100'
                                                  : 'text-gray-900',
                                                behaviorSelectedOptions === 'highlight'
                                                  ? 'pl-3'
                                                  : 'pl-1'
                                              )
                                            }
                                            value={option}
                                          >
                                            {({ selected }) => (
                                              <>
                                                <span
                                                  className={cn(
                                                    'absolute left-0 top-2.5 flex items-center pl-3 transition-all',
                                                    (selected || isSelected) &&
                                                      behaviorSelectedOptions === 'highlight'
                                                      ? 'opacity-1'
                                                      : 'opacity-0'
                                                  )}
                                                >
                                                  <CheckIcon
                                                    className={cn('h-5 w-5 text-primary-blue-100')}
                                                    aria-hidden="true"
                                                  />
                                                </span>
                                                <Typography
                                                  variant={selected ? 'regular4' : 'light4'}
                                                  className={cn(
                                                    'pl-7 text-gray-900',
                                                    behaviorSelectedOptions === 'highlight'
                                                      ? 'pl-7'
                                                      : 'pl-0.5'
                                                  )}
                                                >
                                                  {option.label}
                                                </Typography>
                                              </>
                                            )}
                                          </ComboboxHeadlessUI.Option>
                                        );
                                      })}
                                    </>
                                  )}
                                </div>
                              </ComboboxHeadlessUI.Options>
                            </Transition>
                          </div>
                        </ComboboxHeadlessUI>
                      </div>
                    ) : (
                      <div className="relative w-full">
                        <Typography
                          variant="light4"
                          className="flex cursor-default select-none justify-center rounded-lg bg-tertiary-700 py-[6px] pr-4 text-tertiary-200"
                        >
                          {t('components.select_tags.nothing_found')}
                        </Typography>
                      </div>
                    )}
                  </div>
                </div>
              </Popover.Panel>
            </Transition>
          )}
        </>
      )}
    </Popover>
  );
};

export default SelectTags;
