'use client';

import { FC, PropsWithChildren } from 'react';
import { cn } from 'utils/cn';
import Typography from './Typography';

interface IProgressBar {
  percentage: number;
  hideNumber?: boolean;
  height?: string;
  color?: string;
  className?: string;
}

const ProgressBar: FC<PropsWithChildren<IProgressBar>> = ({
  hideNumber,
  height = 'h-4',
  color = 'bg-primary-green-100',
  percentage,
  className
}) => {
  return (
    <div className={cn('flex w-full items-center justify-between gap-4', height, className)}>
      <div className={cn('relative w-full rounded-full', height)}>
        <div className={cn('absolute left-0 top-0 w-full rounded-full bg-slate-200', height)} />
        <div
          className={cn(
            'absolute left-0 top-0 rounded-full bg-primary-green-100',
            'h-4 transition-all duration-300 ease-in-out',
            color,
            height
          )}
          style={{ width: percentage + '%' }}
        />
      </div>
      {!hideNumber && (
        <Typography variant="medium3" className="!mb-2 !pb-2 text-tertiary-100">
          {percentage}%
        </Typography>
      )}
    </div>
  );
};

export default ProgressBar;
