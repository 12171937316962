import { Typography } from 'components';
import { HollowInfoIcon } from 'lib/Icons';
import { cn } from 'utils/cn';

import { FC, HTMLAttributes, PropsWithChildren, ReactNode } from 'react';

import { TypeOptions } from 'react-toastify';
import './styles.scss';

interface ISnackbar extends HTMLAttributes<HTMLDivElement> {
  /**
   * Is this the principal call to action on the page?
   */
  variant?: TypeOptions | 'danger' | 'neutral';
  name?: string | ReactNode;
  icon?: ReactNode;
  classNameTypographyName?: string;
  subtitle?: string | ReactNode;
  description?: string | ReactNode;
  isRaw?: boolean;
  noIcon?: boolean;
}

const Snackbar: FC<PropsWithChildren<ISnackbar>> = ({
  children,
  description,
  name,
  icon,
  subtitle,
  classNameTypographyName,
  variant = 'info',
  className,
  noIcon,
  isRaw,
  ...rest
}) => {
  return (
    <div className={cn(`tolq-snackbar snackbar--${variant}`, className)} {...rest}>
      <div>
        <span className="flex">
          {!noIcon && variant !== 'neutral' && variant !== 'default' && (
            <span className="flex flex-col justify-center">
              {icon ? <>{icon}</> : <HollowInfoIcon />}
            </span>
          )}
          {name && (
            <Typography
              variant="medium4"
              className={cn('my-0 pl-2 pt-0.5 text-tertiary-100', classNameTypographyName)}
            >
              {typeof name === 'string' ? `${name}:` : <>{name}</>}
            </Typography>
          )}
          {description && (
            <Typography
              variant="regular4"
              className={cn('my-0 flex items-center pl-2 leading-5 text-tertiary-100')}
            >
              {isRaw ? (
                <span dangerouslySetInnerHTML={{ __html: description as TrustedHTML }}></span>
              ) : (
                <>{description}</>
              )}
            </Typography>
          )}
        </span>
        {subtitle && (
          <Typography
            variant="regular4"
            className={cn(
              'my-0 ml-6 mt-4  flex items-center pl-2 italic leading-5 text-tertiary-100'
            )}
          >
            {subtitle}
          </Typography>
        )}
      </div>
      {children}
    </div>
  );
};

export default Snackbar;
