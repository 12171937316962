'use client';
import { forwardRef, ForwardRefRenderFunction, PropsWithChildren, ReactNode } from 'react';
import { FieldError } from 'react-hook-form';
import { cn } from 'utils/cn';
import '../input.scss';

export type TextTypes = 'number' | 'text' | 'password' | 'email';

interface ITextarea
  extends React.DetailedHTMLProps<
    React.TextareaHTMLAttributes<HTMLTextAreaElement>,
    HTMLTextAreaElement
  > {
  error?: FieldError | boolean;
  /*
   * Hint of the input
   * accepts string
   */
  /*
   * Value of the input
   */
  value?: string | number;
  endAdornment?: ReactNode;
  /**
   * Icon in the end of button
   */
  startAdornment?: ReactNode;
  /**
   * classname of close icons
   */
}

const Textarea: ForwardRefRenderFunction<HTMLTextAreaElement, PropsWithChildren<ITextarea>> = (
  { error, className, startAdornment, endAdornment, ...rest },
  ref
): JSX.Element => {
  return (
    <div className="relative">
      {startAdornment && <span className="input-icon-start">{startAdornment}</span>}
      <textarea
        className={cn('input-primary pt-1', error ? 'error-input' : '', 'min-h-[128px]', className)}
        {...rest}
        ref={ref}
      />
      {endAdornment && <span className="input-icon">{endAdornment}</span>}
    </div>
  );
};

export default forwardRef(Textarea);
