'use client';
import { Listbox, Transition } from '@headlessui/react';
import { CheckIcon } from '@heroicons/react/20/solid';
import { Typography } from 'components';
import { ArrowSelectIcon, CloseIcon } from 'lib/Icons';
import { cn } from 'utils/cn';

import {
  FC,
  Fragment,
  HTMLAttributes,
  PropsWithChildren,
  useCallback,
  useEffect,
  useMemo,
  useState
} from 'react';

import { BLANK_OPTION } from 'constants/blank';
import { FieldError } from 'react-hook-form';
import { Option } from 'types/Option';
import { compareObj } from 'utils/compareObj';
import './styles.scss';

export interface ISelect
  extends Omit<PropsWithChildren<HTMLAttributes<HTMLSelectElement>>, 'onChange'> {
  /*
   * Error of the input
   * accepts FieldError
   *
   * @example
   * {
   *  type: 'required',
   * message: 'This field is required'
   * }
   *
   */
  error?: FieldError | boolean;
  /**
   * Options
   * accepts array of object with label and value both as string
   */
  options?: Option[];
  /**
   * Selected Options
   * accepts array of object with label and value both as string
   */
  selectedOptions?: string[];
  /**
   * Selected value
   * @default ''
   * */
  defaultOption?: Option;
  /**
   * Hide close feature
   * @default undefined
   * */
  noClose?: boolean;
  /**
   * Selected value
   * @default undefined
   * */
  selectedValue?: string | boolean | object | number;
  /**
   * Button disabled
   * @default false
   */
  disabled?: boolean;
  /**
   * Optional change handler
   * @default () => {}
   */
  placeholder?: string;
  /**
   * Placeholder
   */
  onChange?: (value: Option) => void;
  /**
   * Optional classname child
   * @default '''
   */
  classNameChild?: string;
}

const Select: FC<ISelect> = ({
  selectedValue,
  options: unprocessedOptions,
  className,
  classNameChild,
  defaultOption,
  selectedOptions,
  noClose,
  placeholder = '',
  disabled,
  error,
  onChange
}) => {
  const options = useMemo(
    () => (!!unprocessedOptions?.length ? unprocessedOptions : [BLANK_OPTION]),
    [unprocessedOptions]
  );

  const availableFirstOption =
    options.find(
      (option) => !option.hidden && !option.disabled && !selectedOptions?.includes(option.label)
    ) ?? (options?.at(0) as Option);

  const initialOption = defaultOption ?? availableFirstOption;

  const [selected, setSelected] = useState(initialOption);

  const isDefault =
    (defaultOption && selected.label === defaultOption?.label) ||
    (!defaultOption && selected.label === availableFirstOption?.label);

  const emptyLabel = !selected.label || selected.label === '\u00A0';

  const handleOnChange = useCallback(
    (value: Option) => {
      setSelected(value);
      onChange && onChange(value);
    },
    [onChange]
  );

  const handleClean = useCallback(
    () => handleOnChange(initialOption),
    [initialOption, handleOnChange]
  );

  useEffect(() => {
    if (selectedValue || typeof selectedValue === 'boolean') {
      const selectedOption = options.find((option) => {
        if (!option.hidden && !option.disabled && !selectedOptions?.includes(option.label)) {
          if (typeof option.value === 'boolean') return option.value === selectedValue;

          if (typeof option.value === 'string') return option.value === selectedValue;

          if (
            typeof option.value === 'object' &&
            option.value !== null &&
            !Array.isArray(option.value)
          )
            return compareObj(option.value, selectedValue as object);
        }

        return undefined;
      });

      if (selectedOption) return setSelected(selectedOption);
    }

    return setSelected(initialOption);
  }, [initialOption, options, selectedOptions, selectedValue]);

  return (
    <div className="container-input">
      <>
        <Listbox value={selected} onChange={handleOnChange} disabled={disabled}>
          <div className={cn('relative mb-2 w-full', className)}>
            <Listbox.Button
              className={cn('select-primary', error ? 'error-input' : '', classNameChild)}
            >
              {!emptyLabel && <Typography variant="regular4">{selected.label}</Typography>}
              {emptyLabel && !!placeholder && (
                <Typography
                  variant="light4"
                  className="select-none text-tertiary-400 dark:text-tertiary-200"
                >
                  {placeholder}
                </Typography>
              )}
              <div className="arrow-select">
                <ArrowSelectIcon />
              </div>
            </Listbox.Button>
            <Transition
              as={Fragment}
              leave="transition ease-in duration-100"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Listbox.Options className="option-list">
                {options.map((option, optionsId) => {
                  if (option.hidden) return null;

                  const isSelected = selectedOptions?.includes(option.label);
                  const disabled = option.disabled || isSelected;

                  return (
                    <Listbox.Option
                      key={optionsId}
                      className={({ active }) =>
                        cn(
                          'relative cursor-pointer select-none py-2 pl-10 pr-4',
                          active ? 'bg-blue-100  text-primary-blue-100' : 'text-gray-900',
                          isSelected && 'cursor-not-allowed bg-tertiary-900 text-tertiary-100'
                        )
                      }
                      value={option}
                      disabled={disabled}
                    >
                      {({ selected }) => (
                        <>
                          <Typography
                            variant={selected ? 'regular4' : 'light4'}
                            className="text-gray-900"
                          >
                            {option.label}
                          </Typography>

                          <span
                            className={cn(
                              'absolute inset-y-0 left-0 flex items-center pl-3 text-primary-blue-100 transition-all',
                              selected || isSelected ? 'opacity-1' : 'opacity-0'
                            )}
                          >
                            <CheckIcon
                              className={cn('h-5 w-5', isSelected && 'text-tertiary-200')}
                              aria-hidden="true"
                            />
                          </span>
                        </>
                      )}
                    </Listbox.Option>
                  );
                })}
              </Listbox.Options>
            </Transition>
          </div>
        </Listbox>
        {!noClose && (!isDefault || !!availableFirstOption?.value) && (
          <div onClick={handleClean} className="input-icon-close">
            <span>
              <CloseIcon />
            </span>
          </div>
        )}
      </>
    </div>
  );
};

export default Select;
