'use client';
import { ArrowSelectIcon } from 'lib/Icons';
import { cn } from 'utils/cn';

import { FC, MouseEventHandler } from 'react';

interface IArrow {
  open?: boolean;
  className?: string;
  classNameParent?: string;
  onClick?: MouseEventHandler<HTMLDivElement>;
}

const Arrow: FC<IArrow> = ({ open, className, classNameParent, onClick }) => {
  return (
    <div
      className={cn(
        'transition-rotate  duration-500 ease-in-out',
        open ? 'rotate-180' : '',
        classNameParent,
        'tolq-arrow'
      )}
      onClick={onClick}
    >
      <ArrowSelectIcon alt="Arrow Icon" height={20} width={20} className={className} />
    </div>
  );
};

export default Arrow;
