import { Typography } from 'components';
import Button from 'components/Buttons/Button';

import { useTranslations } from 'next-intl';

interface IOnboarding {
  startConversation: () => void;
}

const Onboarding = ({ startConversation }: IOnboarding) => {
  const t = useTranslations();

  return (
    <div className="flex h-full flex-1 flex-col items-center justify-between p-4">
      <div className="mb-2 flex w-full justify-center text-center">
        <Typography className="text-tertiary-100" variant="medium3">
          {t('components.comments.onboarding.heading')}
        </Typography>
      </div>
      <div className="flex flex-1 flex-col gap-2">
        {Array.from({ length: 4 }, (_, index) => (
          <div key={index} className="rounded-md border border-tertiary-600 px-2 py-1">
            <Typography className="leading-3 text-tertiary-200	" variant="light5">
              {t(`components.comments.onboarding.explanation${index + 1}` as any)}
            </Typography>
          </div>
        ))}
      </div>
      <Button size="medium" className="w-full" onClick={startConversation}>
        {t('components.comments.onboarding.start_conversation')}
      </Button>
    </div>
  );
};

export default Onboarding;
