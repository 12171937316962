import { LeftActiveIcon, LeftInactiveIcon } from 'lib/Icons';
import { FC } from 'react';
import { PaginationSimpleProps } from '.';

type IPrev = PaginationSimpleProps;

const Prev: FC<IPrev> = ({ totalPages, page, changePage }) => {
  if (totalPages === 1) return null;

  const handlerOnClick = () => changePage(page - 1);

  return (
    <li className={page <= 1 ? 'cursor-not-allowed' : 'cursor-pointer'} onClick={handlerOnClick}>
      <span className={` flex !h-7 !w-7 items-center justify-center  `}>
        {page <= 1 ? (
          <LeftInactiveIcon className="scale-[1.5]" />
        ) : (
          <LeftActiveIcon className="scale-[1.5]" />
        )}
      </span>
    </li>
  );
};

export default Prev;
