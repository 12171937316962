'use client';
import { Typography } from 'components';
import { useTranslations } from 'next-intl';
import { FC } from 'react';

interface INewComments {
  count?: number;
}

const NewComments: FC<INewComments> = ({ count = 0 }) => {
  const t = useTranslations();

  return (
    <div className="flex h-auto w-max rounded-10xl border bg-secondary-red px-2">
      <Typography variant="medium4" className=" text-center text-white">
        {t('components.request_card.comments_new', { count })}
      </Typography>
    </div>
  );
};

export default NewComments;
