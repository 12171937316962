'use client';

import { cn } from 'utils/cn';

import Link from 'next/link';
import { FC, ReactNode } from 'react';
import Typography from '../Typography';

export interface IMenuItem {
  route: string;
  active?: boolean;
  notCollapsible?: boolean;
  visible?: boolean;
  iconActive: ReactNode;
  iconInactive: ReactNode;
  name: string;
  className?: string;
  onClick?: (event: React.MouseEvent<HTMLElement>) => void;
  childrens?: IMenuItem[];
}

const MenuItem: FC<IMenuItem> = ({
  route,
  active,
  iconActive,
  iconInactive,
  name,
  className = '',
  onClick
}) => {
  return (
    <Link
      href={route}
      className={cn(
        'flex items-center rounded-lg p-2 text-2xl font-normal',
        'text-tertiary-100 hover:bg-gray-100 hover:font-medium dark:text-tertiary-300',
        'user-select-none'
      )}
      onClick={onClick}
    >
      {active ? iconActive : iconInactive}
      <Typography
        variant={active ? 'medium3' : 'light3'}
        className={cn(
          'user-select-none ml-3 h-7 select-none transition-all duration-200 dark:text-white',
          active ? 'text-tertiary-100' : 'pt-[3px] text-tertiary-300',
          className
        )}
      >
        {name}
      </Typography>
    </Link>
  );
};

export default MenuItem;
