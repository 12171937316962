export enum ESpansException {
  GenericException = 'GenericException',
  GetIntegration = 'GetIntegration',
  GlossaryException = 'GlossaryException',
  BecomeException = 'BecomeException',
  StyleGuidesException = 'StyleGuidesException',
  AccountDetailsException = 'AccountDetailsException',
  IntegrationHubSpotException = 'IntegrationHubSpotException',
  IntegrationException = 'IntegrationException',
  IntegrationTolqAPIKeyException = 'IntegrationTolqAPIKeyException',
  TranslationMemoryException = 'TranslationMemoryException',
  BillingException = 'BillingException',
  IntlErrorException = 'IntlErrorException',
  IntlFallbackException = 'IntlFallbackException',
  AiException = 'AiException',
  ContextLibraryException = 'ContextLibraryException',
  ContentLibraryException = 'ContentLibraryException',
  ContentGenerationException = 'ContentGenerationException',
  APIErrorWith200 = 'APIErrorWith200',
  APIErrorWithout200 = 'APIErrorWithout200',
  AmplifyException = 'AmplifyException'
}
