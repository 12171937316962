'use client';

import { COMMENTS_KEY } from 'constants/keys';
import crypto from 'crypto';
import { useTranslations } from 'next-intl';
import { usePathname } from 'next/navigation';
import { Socket } from 'phoenix';
import { useSocket } from 'providers/SocketProviders/useSocket';
import { useCallback, useEffect } from 'react';
import { Slide, toast, TypeOptions } from 'react-toastify';
import queryClient from 'services/queryClient';
import { buildQueryKey } from 'utils/buildQueryKey';
import PopToast from './PopToast';
import './styles.scss';

export type TMsgs = {
  type?: TypeOptions;
  title?: string;
  copy?: string;
  content?: string;
  id?: string;
  action?: {
    link?: string;
    label?: string;
  };
};

const PopMessages = () => {
  const { data } = useSocket();
  const pathname = usePathname();
  const t = useTranslations();

  const handleToastTaskId = useCallback(
    (comment: string, taskId: string) => {
      const msgs: TMsgs = {
        title: t('notifications.comments.heading'),
        type: 'info',
        copy: t('notifications.comments.copy'),
        id: taskId,
        content: comment,
        action: {
          link: `/dashboard/show-task/${taskId}#${COMMENTS_KEY}`,
          label: t('notifications.comments.button')
        }
      };

      const customId = String(
        crypto
          .createHash('md5')
          .update(comment + taskId)
          .digest('hex')
      );

      toast.info(PopToast, {
        data: msgs,
        toastId: customId,
        closeOnClick: false,
        position: 'bottom-right',
        hideProgressBar: true,
        draggable: true,
        className: 'pop-messages-toast',
        transition: Slide
      });
    },
    [t]
  );

  const processTaskId = useCallback(
    (comment: any) => {
      const currentTaskId = pathname?.split('show-task/')?.at(1);
      const equalTasks = String(currentTaskId) === String(comment?.taskId);
      const notTheClient = comment?.writtenByClient === false;

      if ((!currentTaskId || (!!currentTaskId && !equalTasks)) && notTheClient)
        return handleToastTaskId(comment?.text, String(comment?.taskId));

      if (!!currentTaskId && equalTasks && notTheClient)
        return queryClient.refetchQueries({
          queryKey: ['useTaskShowById', ...buildQueryKey({ taskId: String(comment?.taskId) })]
        });
    },
    [handleToastTaskId, pathname]
  );

  useEffect(() => {
    if (
      data?.realtime.socketUrl &&
      data?.realtime.token &&
      data?.clientAccount.id &&
      data?.currentUser.id
    ) {
      const socket = new Socket(`${data?.realtime.socketUrl}/socket`, {
        params: { token: data?.realtime.token }
      });
      socket.connect();

      const channel = socket.channel(
        `comments:clients:${data?.clientAccount.id}:${data?.currentUser.id}`,
        {}
      );

      channel.join();
      // .receive('ok', (resp: any) => console.info('Joined successfully', resp)) // Let here for future tests
      // .receive('error', (resp: any) =>
      //   console.error('Unable to join to socket subscription', resp)
      // )
      // .receive('timeout', () => console.error('Networking issue. Still waiting...'));

      channel.on('new:comment', processTaskId);
    }
  }, [
    data?.clientAccount.id,
    data?.currentUser.id,
    data?.realtime.socketUrl,
    data?.realtime.token,
    handleToastTaskId,
    pathname,
    processTaskId
  ]);

  return null;
};

export default PopMessages;
