import { FC } from 'react';
import { cn } from 'utils/cn';
import { FlagHighlightRegExp, TypeHighlightRegExp } from 'utils/regexp';
import './styles.scss';
import { factoryClassName, HIGHLIGHT_CLASS, segmentFragments } from './util';

interface IHighlightSegment {
  text: string;
  searchQuery?: string;
  highlightedColor?: 'orange' | 'yellow';
  fullMatch?: boolean;
  type?: TypeHighlightRegExp;
  flags?: FlagHighlightRegExp;
  selectedHighlight?: number;
  skip?: boolean;
}

const HighlightSegment: FC<IHighlightSegment> = ({
  text,
  searchQuery = '',
  highlightedColor = 'yellow',
  fullMatch,
  type,
  flags,
  selectedHighlight,
  skip
}) => {
  if (skip) return <span>{text}</span>;

  const segments = segmentFragments(text, searchQuery, fullMatch, type, flags);
  const highlightIndexes = segments.reduce<number[]>(
    (acc, segment, index) => (segment.highlighted ? [...acc, index] : acc),
    []
  );
  const selectedFragmentIndex = highlightIndexes[selectedHighlight!];

  return (
    <span title={text}>
      {segments.map((fragment, index) => (
        <span
          key={`${HIGHLIGHT_CLASS}${index}`}
          className={cn(
            factoryClassName(
              fragment.highlighted,
              highlightedColor,
              selectedFragmentIndex === index
            ),
            'text-tertiary-100'
          )}
        >
          {fragment.copy}
        </span>
      ))}
    </span>
  );
};

export default HighlightSegment;
