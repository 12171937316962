export { default as AcceptedIcon } from '/public/images/icons/accepted.svg';
export { default as AddIcon } from '/public/images/icons/add.svg';
export { default as AiIcon } from '/public/images/icons/ai.svg';
export { default as AiLightIcon } from '/public/images/icons/ai_light.svg';
export { default as ApproveIcon } from '/public/images/icons/approve.svg';
export { default as ArrowRightIcon } from '/public/images/icons/arrow_right.svg';
export { default as ArrowSelectIcon } from '/public/images/icons/arrow_select.svg';
export { default as ChevronDownIcon } from '/public/images/icons/arrow_select_down.svg';
export { default as ArrowTinyLeftIcon } from '/public/images/icons/arrow_tiny_left.svg';
export { default as ArrowTinyRightIcon } from '/public/images/icons/arrow_tiny_right.svg';
export { default as ArrowUpIcon } from '/public/images/icons/arrow_up.svg';
export { default as AttachmentIcon } from '/public/images/icons/attachment.svg';
export { default as AvoidIcon } from '/public/images/icons/avoid.svg';
export { default as BallonIcon } from '/public/images/icons/ballon.svg';
export { default as BinIcon } from '/public/images/icons/bin.svg';
export { default as BusinessIcon } from '/public/images/icons/business.svg';
export { default as CalendarIcon } from '/public/images/icons/calendar.svg';
export { default as CampaignIcon } from '/public/images/icons/campaign.svg';
export { default as CheckIcon } from '/public/images/icons/check.svg';
export { default as CheckCircleIcon } from '/public/images/icons/check_circle.svg';
export { default as ChevronIcon } from '/public/images/icons/chevron.svg';
export { default as CloseCircleIcon } from '/public/images/icons/close-circle.svg';
export { default as CloseIcon } from '/public/images/icons/close.svg';
export { default as CloudIcon } from '/public/images/icons/cloud.svg';
export { default as CommentedBoxIcon } from '/public/images/icons/commented_box.svg';
export { default as CommentsIcon } from '/public/images/icons/comments.svg';
export { default as ContentCreatorIcon } from '/public/images/icons/content_creator.svg';
export { default as CopyIcon } from '/public/images/icons/copy.svg';
export { default as CopyLightIcon } from '/public/images/icons/copy_light.svg';
export { default as CorrectingIcon } from '/public/images/icons/correcting.svg';
export { default as CorrectingBoxIcon } from '/public/images/icons/correcting_box.svg';
export { default as CreditCardIcon } from '/public/images/icons/credit_card.svg';
export { default as AiSessionDashboardAiIcon } from '/public/images/icons/dashboard_ai/ai_session.svg';
export { default as CommentDashboardAiIcon } from '/public/images/icons/dashboard_ai/comment.svg';
export { default as ContentCreatorDashboardAiIcon } from '/public/images/icons/dashboard_ai/content_creator.svg';
export { default as TranslationCancelledDashboardAiIcon } from '/public/images/icons/dashboard_ai/translation_cancelled.svg';
export { default as TranslationCreatedDashboardAiIcon } from '/public/images/icons/dashboard_ai/translation_created.svg';
export { default as TranslationFinishedDashboardAiIcon } from '/public/images/icons/dashboard_ai/translation_finished.svg';
export { default as TranslationInProgressDashboardAiIcon } from '/public/images/icons/dashboard_ai/translation_in_progress.svg';
export { default as TranslationQuotedDashboardAiIcon } from '/public/images/icons/dashboard_ai/translation_quoted.svg';
export { default as TranslationReadyToReviewDashboardAiIcon } from '/public/images/icons/dashboard_ai/translation_ready_to_review.svg';
export { default as DotsIcon } from '/public/images/icons/dots.svg';
export { default as DotsVerticalIcon } from '/public/images/icons/dots_vertical.svg';
export { default as DoubleArrowSelectIcon } from '/public/images/icons/double_chevron.svg';
export { default as DownloadIcon } from '/public/images/icons/download.svg';
export { default as EarthIcon } from '/public/images/icons/earth.svg';
export { default as EditMiniIcon } from '/public/images/icons/edit-mini.svg';
export { default as EditIcon } from '/public/images/icons/edit.svg';
export { default as EditBoxIcon } from '/public/images/icons/edit_box.svg';
export { default as EditChatIcon } from '/public/images/icons/edit_chat.svg';
export { default as EditLineIcon } from '/public/images/icons/edit_line.svg';
export { default as ErrorBoxIcon } from '/public/images/icons/error_box.svg';
export { default as ErrorFeaturedIcon } from '/public/images/icons/error_featured.svg';
export { default as ErrorModalFeaturedIcon } from '/public/images/icons/error_modal_featured.svg';
export { default as FileIcon } from '/public/images/icons/file.svg';
export { default as FileAddIcon } from '/public/images/icons/file_add.svg';
export { default as FileDocsIcon } from '/public/images/icons/file_docs.svg';
export { default as FileDoneIcon } from '/public/images/icons/file_done.svg';
export { default as FileDownloadIcon } from '/public/images/icons/file_download.svg';
export { default as FileErrorIcon } from '/public/images/icons/file_error.svg';
export { default as FileProcessingIcon } from '/public/images/icons/file_processing.svg';
export { default as FilterIcon } from '/public/images/icons/filter.svg';
export { default as FilterSelectedIcon } from '/public/images/icons/filter_selected.svg';
export { default as FiltersIcon } from '/public/images/icons/filters.svg';
export { default as FinishedIcon } from '/public/images/icons/finished.svg';
export { default as FolderIcon } from '/public/images/icons/folder.svg';
export { default as GlobeIcon } from '/public/images/icons/globe.svg';
export { default as GlobeAiIcon } from '/public/images/icons/globe_ai.svg';
export { default as GreenIcon } from '/public/images/icons/green.svg';
export { default as HollowInfoIcon } from '/public/images/icons/hollow_info.svg';
export { default as ImproveIcon } from '/public/images/icons/improve.svg';
export { default as InProgressIcon } from '/public/images/icons/in_progress.svg';
export { default as InfoIcon } from '/public/images/icons/info.svg';
export { default as InfoFeaturedIcon } from '/public/images/icons/info_featured.svg';
export { default as InfoHollowIcon } from '/public/images/icons/info_hollow.svg';
export { default as CheckedIcon } from '/public/images/icons/is_checked.svg';
export { default as ItemIcon } from '/public/images/icons/item.svg';
export { default as KeyIcon } from '/public/images/icons/key.svg';
export { default as KeyFeaturedIcon } from '/public/images/icons/key_featured.svg';
export { default as LeftRightIcon } from '/public/images/icons/left_right.svg';
export { default as LineIcon } from '/public/images/icons/line.svg';
export { default as LinkIcon } from '/public/images/icons/link.svg';
export { default as ListIcon } from '/public/images/icons/list.svg';
export { default as LoadingIcon } from '/public/images/icons/loading.svg';
export { default as BaloonBigIcon } from '/public/images/icons/loading/baloon-big.svg';
export { default as BaloonSmallIcon } from '/public/images/icons/loading/baloon-small.svg';
export { default as MagicIcon } from '/public/images/icons/magic.svg';
export { default as MakeLargerIcon } from '/public/images/icons/make_larger.svg';
export { default as MakeShortenIcon } from '/public/images/icons/make_shorten.svg';
export { default as MicroIcon } from '/public/images/icons/micro.svg';
export { default as PageMainIcon } from '/public/images/icons/page_main.svg';
export { default as PageTranslatedIcon } from '/public/images/icons/page_translated.svg';
export { default as AccountActiveIcon } from '/public/images/icons/pairs/account-active.svg';
export { default as AccountInactiveIcon } from '/public/images/icons/pairs/account-inactive.svg';
export { default as AmplifyActiveIcon } from '/public/images/icons/pairs/amplify-active.svg';
export { default as AmplifyInactiveIcon } from '/public/images/icons/pairs/amplify-inactive.svg';
export { default as BillingActiveIcon } from '/public/images/icons/pairs/billing-active.svg';
export { default as BillingInactiveIcon } from '/public/images/icons/pairs/billing-inactive.svg';
export { default as ChatActiveIcon } from '/public/images/icons/pairs/chat-active.svg';
export { default as ChatInactiveIcon } from '/public/images/icons/pairs/chat-inactive.svg';
export { default as CheckBallActiveIcon } from '/public/images/icons/pairs/check_ball-active.svg';
export { default as CheckBallErrorIcon } from '/public/images/icons/pairs/check_ball-error.svg';
export { default as CheckBallInactiveIcon } from '/public/images/icons/pairs/check_ball-inactive.svg';
export { default as ContentGenerationActiveIcon } from '/public/images/icons/pairs/content_generation-active.svg';
export { default as ContentGenerationInactiveIcon } from '/public/images/icons/pairs/content_generation-inactive.svg';
export { default as ContextLibraryActiveIcon } from '/public/images/icons/pairs/context_library-active.svg';
export { default as ContextLibraryInactiveIcon } from '/public/images/icons/pairs/context_library-inactive.svg';
export { default as DashboardActiveIcon } from '/public/images/icons/pairs/dashboard-active.svg';
export { default as DashboardInactiveIcon } from '/public/images/icons/pairs/dashboard-inactive.svg';
export { default as EyeActiveIcon } from '/public/images/icons/pairs/eye-active.svg';
export { default as EyeInactiveIcon } from '/public/images/icons/pairs/eye-inactive.svg';
export { default as GlossaryActiveIcon } from '/public/images/icons/pairs/glossary-active.svg';
export { default as GlossaryInactiveIcon } from '/public/images/icons/pairs/glossary-inactive.svg';
export { default as IntegrationsActiveIcon } from '/public/images/icons/pairs/integrations-active.svg';
export { default as IntegrationsInactiveIcon } from '/public/images/icons/pairs/integrations-inactive.svg';
export { default as LeftActiveIcon } from '/public/images/icons/pairs/left-active.svg';
export { default as LeftInactiveIcon } from '/public/images/icons/pairs/left-inactive.svg';
export { default as OrganizationActiveIcon } from '/public/images/icons/pairs/organization-active.svg';
export { default as OrganizationInactiveIcon } from '/public/images/icons/pairs/organization-inactive.svg';
export { default as OverviewActiveIcon } from '/public/images/icons/pairs/overview-active.svg';
export { default as OverviewInactiveIcon } from '/public/images/icons/pairs/overview-inactive.svg';
export { default as ProjectsActiveIcon } from '/public/images/icons/pairs/projects-active.svg';
export { default as ProjectsInactiveIcon } from '/public/images/icons/pairs/projects-inactive.svg';
export { default as RightActiveIcon } from '/public/images/icons/pairs/right-active.svg';
export { default as RightInactiveIcon } from '/public/images/icons/pairs/right-inactive.svg';
export { default as SettingsActiveIcon } from '/public/images/icons/pairs/settings-active.svg';
export { default as SettingsInactiveIcon } from '/public/images/icons/pairs/settings-inactive.svg';
export { default as SmartTasksActiveIcon } from '/public/images/icons/pairs/smart_tasks-active.svg';
export { default as SmartTasksInactiveIcon } from '/public/images/icons/pairs/smart_tasks-inactive.svg';
export { default as StyleGuideActiveIcon } from '/public/images/icons/pairs/style_guide-active.svg';
export { default as StyleGuideInactiveIcon } from '/public/images/icons/pairs/style_guide-inactive.svg';
export { default as TranslationMemoryActiveIcon } from '/public/images/icons/pairs/translation_memory-active.svg';
export { default as TranslationMemoryInactiveIcon } from '/public/images/icons/pairs/translation_memory-inactive.svg';
export { default as TranslationsActiveIcon } from '/public/images/icons/pairs/translations-active.svg';
export { default as TranslationsInactiveIcon } from '/public/images/icons/pairs/translations-inactive.svg';
export { default as WhatsNewActiveIcon } from '/public/images/icons/pairs/whats_new-active.svg';
export { default as WhatsNewInactiveIcon } from '/public/images/icons/pairs/whats_new-inactive.svg';
export { default as PaperArrowUpIcon } from '/public/images/icons/paper_arrow_up.svg';
export { default as PdfAttachedIcon } from '/public/images/icons/pdf_attached.svg';
export { default as PersonBoxIcon } from '/public/images/icons/person_box.svg';
export { default as PlusIcon } from '/public/images/icons/plus.svg';
export { default as PlusCircleIcon } from '/public/images/icons/plus_circle.svg';
export { default as PostponedIcon } from '/public/images/icons/postponed.svg';
export { default as ProcessingIcon } from '/public/images/icons/processing.svg';
export { default as QuestionIcon } from '/public/images/icons/question.svg';
export { default as QuoteIcon } from '/public/images/icons/quote.svg';
export { default as QuotedIcon } from '/public/images/icons/quoted.svg';
export { default as ReadyForReviewIcon } from '/public/images/icons/ready_for_review.svg';
export { default as RegenerationIcon } from '/public/images/icons/regeneration.svg';
export { default as RetryIcon } from '/public/images/icons/retry.svg';
export { default as SearchBoldIcon } from '/public/images/icons/search-bold.svg';
export { default as SearchIcon } from '/public/images/icons/search.svg';
export { default as SendIcon } from '/public/images/icons/send.svg';
export { default as SettingsIcon } from '/public/images/icons/settings.svg';
export { default as SmartphoneIcon } from '/public/images/icons/smartphone.svg';
export { default as EmailIcon } from '/public/images/icons/social/email.svg';
export { default as FacebookIcon } from '/public/images/icons/social/facebook.svg';
export { default as GoogleIcon } from '/public/images/icons/social/google.svg';
export { default as LinkedinIcon } from '/public/images/icons/social/linkedin.svg';
export { default as SocialFacebookIcon } from '/public/images/icons/social_facebook.svg';
export { default as SocialLinkedinIcon } from '/public/images/icons/social_linkedin.svg';
export { default as SocialTwitterIcon } from '/public/images/icons/social_twitter.svg';
export { default as SortingIcon } from '/public/images/icons/sorting.svg';
export { default as SortingSelectedIcon } from '/public/images/icons/sorting_selected.svg';
export { default as StopIcon } from '/public/images/icons/stop.svg';
export { default as SuccessFeaturedIcon } from '/public/images/icons/success_featured.svg';
export { default as SyncIcon } from '/public/images/icons/sync.svg';
export { default as TaskConfigIcon } from '/public/images/icons/task_config.svg';
export { default as TextIcon } from '/public/images/icons/text.svg';
export { default as TextLetterIcon } from '/public/images/icons/text_letter.svg';
export { default as ThreeDotsIcon } from '/public/images/icons/three_dots.svg';
export { default as TranslationIcon } from '/public/images/icons/translation.svg';
export { default as TrashIcon } from '/public/images/icons/trash.svg';
export { default as UndoIcon } from '/public/images/icons/undo.svg';
export { default as UntranslatedIcon } from '/public/images/icons/untranslated.svg';
export { default as WaitingTranslatorIcon } from '/public/images/icons/waiting_translator.svg';
export { default as WalletIcon } from '/public/images/icons/wallet.svg';
export { default as WarmingIcon } from '/public/images/icons/warming.svg';
export { default as WarningMiniIcon } from '/public/images/icons/warning-mini.svg';
export { default as WarningIcon } from '/public/images/icons/warning.svg';
export { default as WarningFeaturedIcon } from '/public/images/icons/warning_featured.svg';
export { default as WarningOutlinedIcon } from '/public/images/icons/warning_outlined.svg';
export { default as WwwIcon } from '/public/images/icons/www.svg';
export { default as BoldWysiwygIcon } from '/public/images/icons/wysiwyg/bold.svg';
export { default as CenterWysiwygIcon } from '/public/images/icons/wysiwyg/center.svg';
export { default as DecreaseWysiwygIcon } from '/public/images/icons/wysiwyg/decrease.svg';
export { default as HeaderOneWysiwygIcon } from '/public/images/icons/wysiwyg/h1.svg';
export { default as HeaderTwoWysiwygIcon } from '/public/images/icons/wysiwyg/h2.svg';
export { default as HeaderThreeWysiwygIcon } from '/public/images/icons/wysiwyg/h3.svg';
export { default as HyperlinkWysiwygIcon } from '/public/images/icons/wysiwyg/hyperlink.svg';
export { default as ImageWysiwygIcon } from '/public/images/icons/wysiwyg/image.svg';
export { default as IncreaseWysiwygIcon } from '/public/images/icons/wysiwyg/increase.svg';
export { default as ItalicWysiwygIcon } from '/public/images/icons/wysiwyg/italic.svg';
export { default as LeftWysiwygIcon } from '/public/images/icons/wysiwyg/left.svg';
export { default as OrderedWysiwygIcon } from '/public/images/icons/wysiwyg/ordered.svg';
export { default as RedoWysiwygIcon } from '/public/images/icons/wysiwyg/redo.svg';
export { default as RightWysiwygIcon } from '/public/images/icons/wysiwyg/right.svg';
export { default as UnderlineWysiwygIcon } from '/public/images/icons/wysiwyg/underline.svg';
export { default as UndoWysiwygIcon } from '/public/images/icons/wysiwyg/undo.svg';
export { default as UnorderedWysiwygIcon } from '/public/images/icons/wysiwyg/unordered.svg';
