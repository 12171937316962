import { LocalSession } from './auth/LocalSession';

import { LocalSigninPayload } from 'gql/graphql';
import { gql } from 'graphql-request';
import clientUtil from 'utils/clientUtil';

export class LocalAuth {
  private session: LocalSession;

  constructor() {
    this.session = new LocalSession();
  }

  public currentAuthenticatedUser() {
    return new Promise((resolve, reject) => {
      if (this.session.isUserSignedIn()) {
        resolve(this.session.currentAuthenticatedUser());
      } else {
        reject();
      }
    });
  }

  public currentSession() {
    return this.session;
  }

  public async signIn(email: string, password?: string) {
    const client = clientUtil();

    const result = (await client.request<LocalSigninPayload>({
      data: {
        gql: this._buildSigninMutation(),
        variables: {
          input: {
            email,
            password
          }
        }
      }
    })) as unknown as LocalSigninPayload;
    if (result.success) {
      this.session.create(result.userLocalSession?.authToken ?? '');
      return result;
    } else {
      console.error(result);
      throw new Error('Invalid credentials');
    }
  }

  public signOut() {
    return new Promise((resolve) => {
      if (this.session.destroy()) {
        resolve(true);
      }
    });
  }

  public updateUserAttributes(user: string, attributes: object) {
    return true;
  }

  public forgotPassword(email: string) {
    return new Promise((resolve) => {
      if (this.session.destroy()) {
        resolve(true);
      }
    });
  }

  public changePassword(user: string, currentPassword: string, newPassword: string) {
    return new Promise((resolve) => {
      resolve(true);
    });
  }

  public verifyCurrentUserAttribute(att: string) {
    return new Promise((resolve) => {
      resolve(true);
    });
  }

  public verifyCurrentUserAttributeSubmit(att: string, confirmationCode: string) {
    return new Promise((resolve) => {
      resolve(true);
    });
  }

  public confirmSignIn(cognitoUser: any, confirmationCode: string) {
    return new Promise((resolve) => {
      resolve(true);
    });
  }

  public confirmSignUp(email: string, confirmationCode: string) {
    return new Promise((resolve) => {
      resolve(true);
    });
  }

  public resendSignUp(email: string) {
    return new Promise((resolve) => {
      resolve(true);
    });
  }

  public forgotPasswordSubmit(cognitoUser: any, confirmationCode: string) {
    return new Promise((resolve) => {
      resolve(true);
    });
  }

  public completeNewPassword(cognitoUser: any, password: string) {
    return new Promise((resolve) => {
      resolve(true);
    });
  }

  private _buildSigninMutation() {
    return gql`
      mutation userLocalSignin($input: LocalSigninInput!) {
        userLocalSignin(input: $input) {
          success
          userLocalSession {
            authToken
          }
        }
      }
    `;
  }
}
