import { PlacesType } from 'react-tooltip';

export const CACHE_TIME = 1000 * 60 * 60 * 24 * 30.4167; // Kind of one month
export const MAX_SIZE_IN_BYTES_250_MB = 250 * 1024 * 1024; // 250 MB
export const DARK = 'dark';
export const LIGHT = 'light';
export const POLL_TIME = 100;
export const FIRST_PAGE = 1;
export const MAX_PER_PAGE = 20;
export const POLL_TIME_SECOND = 1000;
export const POLL_TIME_THREE_SECONDS = 3000;
export const POLL_TIME_TEN_SECONDS = POLL_TIME_SECOND * 10;
export const POLL_TIME_FIVE_MINUTES_SECONDS = POLL_TIME_SECOND * 60 * 5;
export const PROCESSING_PERCENTAGE_FULL = 100;
export const TIMEOUT = 60 * 1000;
export const BULK_DOWNLOAD_FILES_TIMEOUT = 2 * 60 * 1000;
export const TOOLTIP_PLACES: PlacesType[] = [
  'top',
  'top-start',
  'top-end',
  'right',
  'right-start',
  'right-end',
  'bottom',
  'bottom-start',
  'bottom-end',
  'left',
  'left-start',
  'left-end'
];
