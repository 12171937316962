'use client';
import { Spinning, Typography } from 'components';
import { TypographyVariant } from 'components/Typography';
import { motion, MotionProps } from 'framer-motion';
import { cn } from 'utils/cn';

import { FC, KeyboardEvent, MouseEvent, ReactNode } from 'react';

import '../styles.scss';

export type SizeButton = 'small' | 'medium' | 'large';
export type ColorButton = 'primary' | 'secondary' | 'danger' | 'neutral';

interface IButton extends MotionProps {
  /**
   * Is this the principal call to action on the page?
   */
  variant?: 'outlined' | 'contained' | 'text-small' | 'text' | 'icon' | 'icon-btn';
  /**
   * How large should the button be?
   */
  size?: SizeButton;
  /**
   * How large should the icon be?
   */
  sizeIcon?: SizeButton;
  /**
   * What color should the button be?
   */
  color?: 'primary' | 'secondary' | 'danger' | 'neutral' | 'orange' | 'white' | 'none';
  /**
   * What color should the icon be?
   */
  colorIcon?: 'neutral' | 'blue' | 'black' | 'white' | 'danger' | 'none';
  /**
   * Should the button be rounded on border?
   */
  rounded?: boolean;
  /**
   * Classname of Typografy
   */
  classNameTypography?: string;
  /**
   * Button contents
   */
  classNameLoading?: string;
  /**
   * Button contents
   */
  loading?: boolean;
  /**
   * Button hide the label and show the loading
   */
  label?: string;
  /**
   * Icon in the start of button
   */
  startAdornment?: ReactNode;
  /**
   * Icon in the end of button
   */
  endAdornment?: ReactNode;
  /**
   * Button disabled
   */
  disabled?: boolean;
  /**
   * Class name
   */
  className?: string;
  /**
   * Is active ripple?
   */
  isRipple?: boolean;
  /**
   * Button childer
   */
  children?: ReactNode;
  /**
   * Optional click handler
   */
  onClick?: (event: MouseEvent<HTMLButtonElement>) => void;
  onKeyDown?: (event: KeyboardEvent<HTMLButtonElement>) => void;
}

const Button: FC<IButton> = ({
  children,
  onClick,
  variant = 'contained',
  size = 'large',
  sizeIcon = '',
  label = '',
  color = 'primary',
  colorIcon = '',
  isRipple = true,
  rounded = false,
  disabled = false,
  startAdornment = null,
  endAdornment = null,
  className = '',
  loading = false,
  classNameTypography = '',
  classNameLoading = '',
  ...rest
}) => {
  const typografyVariants = {
    small: 'medium4',
    medium: 'medium3',
    large: 'medium2'
  } as Record<SizeButton, TypographyVariant>;

  function handleOnClick(event: MouseEvent<HTMLButtonElement>) {
    const button = event.currentTarget;

    if (button && isRipple) {
      const circle = document.createElement('span');
      const diameter = Math.max(button.offsetWidth, button.offsetHeight);
      const radius = diameter / 2;

      circle.style.width = circle.style.height = `${diameter}px`;
      circle.style.left = `${event.clientX - (button.offsetLeft + radius)}px`;
      circle.style.top = `${event.clientY - (button.offsetTop + radius)}px`;
      circle.classList.add('ripple');

      const ripple = button.getElementsByClassName('ripple')[0];

      if (ripple) {
        ripple.remove();
      }

      button.appendChild(circle);
    }

    if (button && onClick) {
      // Add null check here
      onClick(event);
    }
  }

  return (
    <motion.button
      onClick={handleOnClick}
      className={cn(
        'tolq-button',
        `button--${variant}`,
        `button--${size}`,
        disabled
          ? variant !== 'text'
            ? 'button--disabled'
            : 'button--text--disabled'
          : `button--${color}`,
        sizeIcon ? `button--size-icon-${sizeIcon}` : '',
        colorIcon ? `button--color-icon-${colorIcon}` : '',
        rounded ? 'button--rounded' : '',
        className
      )}
      {...rest}
      whileTap={{ scale: variant.includes('icon') ? 0.9 : 0.95 }}
      transition={{
        type: 'spring',
        stiffness: 300,
        damping: 25,
        restDelta: 10,
        mass: 1
      }}
      disabled={disabled || loading}
    >
      <div className="flex flex-col items-center">
        {loading && (
          <div className="relative mt-1">
            <Spinning
              className={cn(
                'top-[2px]',
                variant === 'contained' || variant === 'outlined'
                  ? 'border-white border-t-transparent'
                  : '',
                classNameLoading
              )}
            />
          </div>
        )}
        <div className={cn(loading && 'opacity-0', 'flex items-center gap-2')}>
          {startAdornment}
          {(label || children) && (
            <Typography
              variant={typografyVariants[size]}
              className={cn(classNameTypography, '!transition-none !duration-0')}
            >
              {label}
              {children}
            </Typography>
          )}
          {endAdornment}
        </div>
      </div>
    </motion.button>
  );
};
export default Button;
