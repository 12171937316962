import { Typography } from 'components';

import { ItemIcon } from 'lib/Icons';
import { useTranslations } from 'next-intl';
import { cn } from 'utils/cn';

import 'styles/sass/dropzone.scss';

const Dropzone = () => {
  const t = useTranslations();
  return (
    <div className={'h-[184px] rounded bg-tertiary-600/[.25] p-2 shadow-custom'}>
      <div
        className={cn(
          'dropzone flex h-full w-full cursor-pointer flex-col items-center justify-center',
          'border-spacing-5 rounded-md '
        )}
      >
        <ItemIcon className="scale-110" />
        <div className="mt-2 items-center">
          <Typography variant="medium5" className="!mt-0 inline-block text-primary-blue-100">
            {t('ordering_form.drop_your_files')}
          </Typography>
        </div>
      </div>
    </div>
  );
};

export default Dropzone;
