import { Popover, Transition } from '@headlessui/react';
import { Typography } from 'components';
import Button from 'components/Buttons/Button';

import { InfoHollowIcon } from 'lib/Icons';
import { useTranslations } from 'next-intl';
import {
  ChangeEvent,
  FC,
  Fragment,
  useCallback,
  useEffect,
  useLayoutEffect,
  useRef,
  useState
} from 'react';
import { cn } from 'utils/cn';
import { IComments, StatusTags, themeColorTag } from '..';
import Tag from '../../Tag';
import Bubble from './Bubble';
import NoComment from './NoComment';

const CommentsMain: FC<IComments> = ({
  comments,
  isCommentate,
  isCorrectable,
  isLoadingComment,
  isLoadingCorrection,
  tags = [],
  updateTags,
  updateHoveredTags,
  updateStatusTags,
  onClickTag,
  addCorrections,
  addComment,
  markAsRead
}) => {
  const t = useTranslations();

  const [comment, setComment] = useState('');

  const [animate, setAnimate] = useState(false);
  const [scrollTo, setScrollTo] = useState(true);

  const [maxHeight, setMaxHeight] = useState(450);
  const [heightBox, setHeightBox] = useState(0);

  const ref = useRef<HTMLDivElement>(null);
  const refMsg = useRef<HTMLDivElement>(null);

  const commentDisabled = (!comment.length && !tags.length) || !isCommentate;
  const askDisabled = !comment.length || !tags.length || !isCorrectable;

  const handleOnRemoveTag = (tagToRemove: string) => {
    const tagSharpRemoved = tagToRemove.slice(1); // Remove the sharp;
    updateTags?.(tags?.filter((tag) => tag !== tagSharpRemoved) ?? []);
  };

  const handleAddCorrections = () => {
    addCorrections(comment, tags);
    setComment('');
    updateTags?.([]);
  };

  const handleAddComment = () => {
    addComment(comment, tags);
    setComment('');
    updateTags?.([]);
  };

  const handleChangeComment = (e: ChangeEvent<HTMLTextAreaElement>) => setComment(e.target.value);

  const handleMouseOver = (tag: string) => () => {
    const tagSharpRemoved = tag.slice(1); // Remove the sharp;

    if (updateHoveredTags) updateHoveredTags?.([tagSharpRemoved]);

    if (updateStatusTags) {
      updateStatusTags?.(StatusTags.Blue);
    }
  };

  const handleMouseLeave = () => {
    updateHoveredTags?.([]);
    updateStatusTags?.(StatusTags.Null);
  };

  const handleResizeBox = (el: HTMLDivElement) => setHeightBox(el?.getBoundingClientRect().height);

  useLayoutEffect(() => {
    if (ref?.current?.offsetHeight && heightBox)
      setMaxHeight(ref?.current?.offsetHeight - (heightBox + 46));

    if (ref?.current) ref.current.scrollTop = ref?.current?.scrollHeight ?? 0;
  }, [heightBox]);

  const resetScroll = useCallback(() => {
    if (refMsg?.current && scrollTo) {
      refMsg.current.scrollTo({
        top:
          (refMsg?.current?.scrollHeight > maxHeight
            ? refMsg?.current?.scrollHeight
            : maxHeight ?? 0) + 500,
        behavior: animate ? 'auto' : 'smooth'
      });
    }
  }, [scrollTo, maxHeight, animate]);

  useEffect(() => {
    resetScroll();
  }, [resetScroll]);

  useEffect(() => {
    setAnimate(true);
  }, []);

  useEffect(() => {
    setTimeout(() => {
      setScrollTo(false);
    }, 1000);
  }, []);

  return (
    <div className="flex flex-1 flex-col justify-between overflow-hidden">
      <div className="flex flex-1 flex-col overflow-y-auto" style={{ maxHeight }}>
        {!!comments?.length && (
          <div
            ref={refMsg}
            className={cn(
              'min-h-0 overflow-y-scroll',
              'flex w-full flex-1 flex-col justify-start gap-3 px-4 py-2'
            )}
            onTransitionEndCapture={resetScroll}
          >
            {comments?.map((comment) => (
              <Bubble
                key={comment.id}
                comment={comment}
                onMouseOver={updateHoveredTags}
                onMouseLeave={handleMouseLeave}
                updateStatusTags={updateStatusTags}
                onClickTag={onClickTag}
                markAsRead={markAsRead}
              />
            ))}
          </div>
        )}
        {!comments?.length && (
          <div className="flex flex-1">
            <NoComment />
          </div>
        )}
      </div>
      <div className="flex shrink-0">
        <div className="flex w-full shrink-0 flex-col">
          <div className="border-0 border-t-1 border-t-tertiary-600" ref={handleResizeBox}>
            {
              <div className={cn('flex flex-wrap gap-2 px-4 ', tags.length > 0 && 'pt-4')}>
                {tags.map((tag) => (
                  <Tag
                    key={tag}
                    onRemove={handleOnRemoveTag}
                    onMouseOver={handleMouseOver(tag)}
                    onMouseLeave={handleMouseLeave}
                    className={themeColorTag()}
                  >
                    {`#${tag}`}
                  </Tag>
                ))}
              </div>
            }
            <div className={cn('flex h-[160px] w-full flex-col')}>
              <textarea
                placeholder={t('components.comments.type_comment')}
                rows={4}
                value={comment || ''}
                className="my-4 w-full resize-none px-4 text-sm outline-none"
                onChange={handleChangeComment}
              />
              <div
                className={cn(
                  'flex h-12 items-center justify-between gap-2 py-2 pl-2',
                  'border-0 border-y-1 border-y-tertiary-600'
                )}
              >
                <div className="relative h-8 w-12"></div>
                <div className="fixed">
                  <Popover className="relative">
                    <>
                      <Popover.Button className={'mt-2 outline-none'}>
                        <InfoHollowIcon className="scale-[0.7] cursor-pointer" />
                      </Popover.Button>
                      <Transition
                        as={Fragment}
                        enter="transition ease-out duration-200"
                        enterFrom="opacity-0 translate-y-1"
                        enterTo="opacity-100 translate-y-0"
                        leave="transition ease-in duration-150"
                        leaveFrom="opacity-100 translate-y-0"
                        leaveTo="opacity-0 translate-y-1"
                      >
                        <Popover.Panel className="absolute bottom-14 left-[140px] z-10 mt-3 -translate-x-1/2 px-4 sm:px-0">
                          <div className="overflow-hidden rounded-5xl border border-tertiary-400 bg-primary-blue-900 shadow-md">
                            <div className="flex w-[17.5rem] flex-col items-start justify-end gap-2 rounded-lg shadow">
                              <div className="flex flex-col items-start justify-start self-stretch rounded-lg p-2">
                                <div>
                                  <Typography
                                    variant="regular4"
                                    className={cn('text-tertiary-100')}
                                  >
                                    {t('components.comments.help.one.head')}
                                  </Typography>
                                </div>
                                <ul className="mr-2 flex flex-col gap-1 self-stretch">
                                  {[
                                    t('components.comments.help.one.explanation0'),
                                    t('components.comments.help.one.explanation1')
                                  ].map((text) => (
                                    <li key={text}>
                                      <Typography
                                        variant="regular4"
                                        className={cn('mr-2 text-tertiary-100')}
                                      >
                                        ⋅
                                      </Typography>
                                      <Typography
                                        variant="light4"
                                        className={cn('text-tertiary-100')}
                                      >
                                        {text}
                                      </Typography>
                                    </li>
                                  ))}
                                </ul>
                                <div className="mt-4">
                                  <Typography
                                    variant="regular4"
                                    className={cn('text-tertiary-100')}
                                  >
                                    {t('components.comments.help.two.head')}
                                  </Typography>
                                </div>
                                <ul className="mr-2 flex flex-col gap-1 self-stretch">
                                  {[
                                    t('components.comments.help.two.explanation0'),
                                    t('components.comments.help.two.explanation1'),
                                    t('components.comments.help.two.explanation2')
                                  ].map((text) => (
                                    <li key={text}>
                                      <Typography
                                        variant="regular4"
                                        className={cn('mr-2 text-tertiary-100')}
                                      >
                                        ⋅
                                      </Typography>
                                      <Typography
                                        variant="light4"
                                        className={cn('text-tertiary-100')}
                                      >
                                        {text}
                                      </Typography>
                                    </li>
                                  ))}
                                </ul>
                              </div>
                            </div>
                          </div>
                          <div className="relative">
                            <div className="absolute -bottom-2 left-[12px] h-4 w-4 rotate-45 border-0 border-b-1 border-r-1 border-tertiary-400 bg-primary-blue-900"></div>
                          </div>
                        </Popover.Panel>
                      </Transition>
                    </>
                  </Popover>
                </div>
                <div className="flex gap-2 pr-4">
                  <Button
                    disabled={askDisabled}
                    size="small"
                    className="w-fit !px-2"
                    onClick={handleAddCorrections}
                    loading={isLoadingCorrection}
                  >
                    {t('components.comments.ask_for_corrections')}
                  </Button>
                  <Button
                    disabled={commentDisabled}
                    size="small"
                    className="w-fit !px-2"
                    onClick={handleAddComment}
                    loading={isLoadingComment}
                  >
                    {t('components.comments.comment')}
                  </Button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CommentsMain;
