'use client';
import { Combobox, DateRange, InputCode, Label, Select, SelectTags, Textarea } from 'components';
import {
  ChangeEventHandler,
  FC,
  HTMLAttributes,
  InputHTMLAttributes,
  PropsWithChildren,
  ReactNode,
  useMemo
} from 'react';
import { Control, Controller, FieldError, Merge } from 'react-hook-form';
import { Option } from 'types/Option';
import Checkbox from './Checkbox';
import CheckButtons from './CheckButtons';
import FilterInput, { IFilterInput } from './FilterInput';
import Hint from './Hint';
import Input, { TextTypes } from './Input';
import './input.scss';
import MultiplesInputs, { MultipleTypes } from './MultiplesInputs';
import { ISelect } from './Select';
import SelectItemInput from './SelectItemInput';

type InputTypes =
  | TextTypes
  | 'textarea'
  | 'checkbox'
  | 'check-buttons'
  | 'code'
  | 'combobox'
  | 'select'
  | 'select-tags'
  | 'multiples-inputs'
  | 'date-range'
  | 'filter'
  | 'select-item';

type ErrorArray = Merge<FieldError, (FieldError | undefined)[]>;

interface IControlledInput
  extends HTMLAttributes<HTMLInputElement>,
    Pick<InputHTMLAttributes<HTMLInputElement>, 'autoComplete'>,
    Partial<Pick<HTMLInputElement, 'disabled'>>,
    Partial<Pick<ISelect, 'defaultOption'>>,
    Partial<Pick<IFilterInput, 'checkboxes'>> {
  control?: Control<any>;
  /*
   * Name of the input
   */
  name: string;
  /*
   * Type of the input
   */
  type: InputTypes;
  /*
   * Type of multiples inputs
   */
  typeMultiples?: MultipleTypes;
  /*
   * code it's the identifier of the input
   */
  options?: Option[];
  /*
   * code it's the identifier of the input
   */

  code?: string;
  /*
   * Prop to say if should be accepted pasting in that input
   */
  noPaste?: boolean;
  /*
   * Label of button
   * accepts string
   */
  labelButton?: string;
  /*
   * Label of the input
   * accepts string or ReactNode
   */
  label?: string | ReactNode;
  /*
   * Error of the input
   * accepts FieldError
   *
   * @example
   * {
   *  type: 'required',
   * message: 'This field is required'
   * }
   *
   */
  error?: FieldError | ErrorArray;
  /*
   * Hint of the input
   * accepts string
   */
  hint?: string;
  /*
   * Length of the input type code
   */
  length?: number;
  /*
   * classname of parent the div
   */
  classNameParent?: string;
  /*
   * StartAdornment of the input
   */
  startAdornment?: ReactNode;
  /*
   * EndAdornment of the input
   */
  endAdornment?: ReactNode;
  /*
   * OptionalParams for inputs with different behavios
   *  asSingle is for the DatePicker Component
   *  removeFrom is for the SelectTags Component
   *  max is for the SelectTags Component
   *  labelCount is for the SelectTags Component
   *  placeholderSearch is for the SelectTags Component
   *  onAdd is for the SelectTags Component
   *  onDelete is for the SelectTags Component
   *  noClose is for the Select Component
   */
  optionalParams?: {
    asSingle?: boolean;
    removeFrom?: number;
    max?: number;
    labelCount?: string;
    placeholderSearch?: string;
    disableDefaultOption?: boolean;
    rows?: number;
    onAdd?: (value: string) => Promise<void>;
    onDelete?: (value: string) => Promise<void>;
    classNameCloseIcon?: string;
    noClose?: boolean;
  };

  disabled?: boolean;
}

const ControlledInput: FC<PropsWithChildren<IControlledInput>> = ({
  control,
  name,
  type = 'text',
  typeMultiples = 'text',
  options,
  checkboxes,
  label = '',
  error: unprocessedError,
  hint,
  length,
  classNameParent = '',
  startAdornment,
  endAdornment,
  disabled = false,
  defaultOption,
  labelButton,
  code,
  optionalParams,
  noPaste,
  ...rest
}): JSX.Element => {
  const error: FieldError | undefined = useMemo(() => {
    if (!Array.isArray(unprocessedError) || !unprocessedError?.length)
      return unprocessedError as FieldError | undefined;

    return (unprocessedError as FieldError[])?.filter(Boolean)?.at(0) as FieldError;
  }, [unprocessedError]);

  return (
    <Controller
      control={control}
      name={name}
      render={({ field: { onChange, onBlur, value, ref } }) => {
        const handlerSelect = (option: Option) => onChange(option?.value ?? options?.at(0)?.value);

        return (
          <div className={`flex flex-col ${classNameParent}`}>
            {!['checkbox', 'check-buttons'].includes(type) && label && (
              <Label name={name} className="w-fit">
                {label}
              </Label>
            )}

            {(type === 'text' || type === 'password' || type === 'email' || type === 'number') && (
              <Input
                type={type}
                value={value || ''}
                onChange={onChange}
                onBlur={onBlur}
                ref={ref}
                noPaste={noPaste}
                disabled={disabled}
                error={unprocessedError as FieldError}
                endAdornment={endAdornment}
                classNameCloseIcon={optionalParams?.classNameCloseIcon}
                {...rest}
              />
            )}

            {type === 'textarea' && (
              <Textarea
                value={value || ''}
                onChange={onChange as unknown as ChangeEventHandler<HTMLTextAreaElement>}
                onBlur={onBlur as ChangeEventHandler<HTMLTextAreaElement>}
                ref={ref}
                disabled={disabled}
                error={unprocessedError as FieldError}
                className={rest.className}
                placeholder={rest.placeholder}
                rows={optionalParams?.rows}
              />
            )}

            {type === 'check-buttons' && (
              <CheckButtons
                options={options ?? []}
                label={label}
                onChange={onChange}
                value={value}
              />
            )}

            {type === 'checkbox' && (
              <Checkbox
                startAdornment={startAdornment}
                onChange={onChange}
                label={label}
                name={name}
                checked={value}
                error={unprocessedError as FieldError}
                className={hint ? 'mb-2' : ''}
              />
            )}

            {type === 'code' && (
              <div className="flex scale-50 justify-center pb-1 md:scale-[0.65] lg:scale-75">
                <InputCode
                  id={name}
                  onChange={onChange}
                  length={length}
                  onBlur={onBlur}
                  {...rest}
                />
              </div>
            )}

            {type === 'date-range' && (
              <div className="flex justify-center pb-1">
                <DateRange
                  name={name}
                  value={value}
                  onChange={onChange}
                  asSingle={optionalParams?.asSingle}
                />
              </div>
            )}

            {type === 'multiples-inputs' && (
              <MultiplesInputs
                type={typeMultiples}
                error={unprocessedError as FieldError[]}
                value={value}
                onChange={onChange}
                labelButton={labelButton}
                options={options ?? []}
              />
            )}

            {type === 'select-item' && (
              <SelectItemInput value={value} onChange={onChange} code={code ?? ''} />
            )}

            {type === 'select' && (
              <Select
                options={options ?? []}
                selectedValue={value}
                defaultOption={defaultOption}
                placeholder={rest.placeholder}
                className={rest.className}
                disabled={disabled}
                error={error}
                onChange={handlerSelect}
                noClose={optionalParams?.noClose}
              />
            )}
            {type === 'combobox' && (
              <Combobox
                options={options ?? []}
                selectedValue={value}
                defaultOption={defaultOption}
                placeholder={rest.placeholder}
                className={rest.className}
                disabled={disabled}
                error={error}
                onChange={handlerSelect}
                wideHeight
                noClose
              />
            )}
            {type === 'select-tags' && (
              <SelectTags
                options={options ?? []}
                placeholder={rest.placeholder}
                onChange={onChange}
                selectedOptions={value}
                error={error}
                onAdd={optionalParams?.onAdd}
                onDelete={optionalParams?.onDelete}
                removeFrom={optionalParams?.removeFrom}
                max={optionalParams?.max}
                labelCount={optionalParams?.labelCount}
                placeholderSearch={optionalParams?.placeholderSearch}
                disabled={disabled}
                wideHeight
              />
            )}

            {type === 'filter' && (
              <FilterInput checkboxes={checkboxes} value={value} onChange={onChange} />
            )}

            {hint && !error ? (
              <Hint className="mt-1">{hint}</Hint>
            ) : (
              error && <Hint className="mt-1 !text-red-500">{error?.message}</Hint>
            )}
          </div>
        );
      }}
    />
  );
};

export default ControlledInput;
