export const PAGE_KEY = 'page';
export const SOURCE_LANGUAGE_KEY = 'source_language';
export const KEYS_KEY = 'keys';
export const TYPE_KEY = 'type';
export const IDS_KEY = 'ids';
export const ID_KEY = 'id';
export const INDEX_KEY = 'index';
export const EDITOR_ID_KEY = 'editorId';
export const QUERY_KEY = 'query';
export const DATE_RANGE_KEY = 'dateRange';
export const DATE_PREFERRED_KEY = 'datePreferred';
export const QUALITY_KEY = 'quality';
export const SHOW_DUPLICATES_KEY = 'showDuplicates';
export const TRANSLATION_REQUEST_VERSION_KEY = 'translationRequestVersion';
export const TASK_ID_KEY = 'taskId';
export const REDIRECT_TO_KEY = 'redirectTo';
export const TOKEN_KEY = 'token';
export const EMAIL_KEY = 'email';
export const PASSWORD_KEY = 'password';
export const LANGUAGES_KEY = 'languages';
export const CODE_KEY = 'code';
export const LANGUAGE_KEY = 'language';
export const TITLE_KEY = 'title';
export const TOPIC_KEY = 'topic';
export const CONTENT_LENGTH_KEY = 'contentLength';
export const ADDITIONAL_INSTRUCTIONS_KEY = 'additionalInstructions';
export const CONTENT_TYPE_KEY = 'contentType';
export const CONTENT_FORMAT_KEY = 'contentFormat';
export const QUOTE_KEY = 'quote';
export const SORT_ATTRIBUTE_KEY = 'sortAttribute';
export const SORT_ORDER_KEY = 'sortOrder';
export const TAG_ID_KEY = 'tagId';
export const LANGUAGE_PAIR_KEY = 'languagePair';
export const COMMENTS_KEY = 'comments';
export const STYLE_GUIDES_KEY = 'style-guides';
export const STYLE_GUIDE_ID_KEY = 'styleGuideId';
export const STYLE_GUIDE_KEY = 'styleGuide';
export const CONTEXT_IDS_KEY = 'contextIds';
export const DETAILS_KEY = 'details';
export const INTEGRATIONS_KEY = 'integrations';
export const LOGS_KEY = 'logs';
export const CONTENT_BRIEF_KEY = 'contentBrief';
export const CALLBACK_KEY = 'callback';
export const STATUS_KEY = 'status';
