'use client';
import { CloseIcon, EyeActiveIcon, EyeInactiveIcon } from 'lib/Icons';
import {
  ChangeEvent,
  forwardRef,
  ForwardRefRenderFunction,
  PropsWithChildren,
  ReactNode,
  useState
} from 'react';
import { FieldError } from 'react-hook-form';
import { cn } from 'utils/cn';
import '../input.scss';

export const textTypes = ['number', 'text', 'password', 'email'];
export const textTypesConst = [...textTypes] as const;

export type TextTypes = (typeof textTypesConst)[number];

interface IInput
  extends React.DetailedHTMLProps<React.InputHTMLAttributes<HTMLInputElement>, HTMLInputElement> {
  /*
   * Error of the input
   * accepts FieldError
   *
   * @example
   * {
   *  type: 'required',
   * message: 'This field is required'
   * }
   *
   */
  error?: FieldError | boolean;
  /*
   * Hint of the input
   * accepts string
   */
  /*
   * Type of the input
   */
  type?: TextTypes;
  /*
   * Prop to say if should be accepted pasting in that input
   */
  noPaste?: boolean;
  /*
   * Prop to say if will show the close button
   */
  noClose?: boolean;
  /*
   * Value of the input
   */
  value?: string | number;
  /**
   * Icon in the end of button
   */
  endAdornment?: ReactNode;
  /**
   * Icon in the end of button
   */
  startAdornment?: ReactNode;
  /**
   * classname of close icons
   */
  classNameCloseIcon?: ReactNode;
}

const Input: ForwardRefRenderFunction<HTMLInputElement, PropsWithChildren<IInput>> = (
  {
    error,
    className,
    endAdornment,
    startAdornment,
    defaultValue = '',
    classNameCloseIcon = '',
    value,
    onChange,
    type = 'text',
    noPaste,
    noClose,
    ...rest
  },
  ref
): JSX.Element => {
  const isDefault = value === defaultValue || !value;
  const [forceTextType, setForceTextType] = useState(false);

  const handleClean = () =>
    onChange && onChange({ target: { value: defaultValue } } as ChangeEvent<HTMLInputElement>);

  const handleToggleShowPassword = () => setForceTextType((oldState) => !oldState);

  return (
    <div className="container-input">
      <>
        <div className="input-container relative w-full">
          {startAdornment && <span className="input-icon-start">{startAdornment}</span>}
          <input
            className={cn('input-primary w-full', error ? 'error-input' : '', className)}
            value={value || defaultValue || ''}
            onChange={onChange}
            type={forceTextType ? 'text' : type}
            {...rest}
            ref={ref}
          />
          {type !== 'password' && <span className="input-icon">{endAdornment}</span>}
          {type === 'password' && (
            <div onClick={handleToggleShowPassword} className={cn('input-icon-eye')}>
              <span>{forceTextType ? <EyeActiveIcon /> : <EyeInactiveIcon />}</span>
            </div>
          )}
        </div>
        {!isDefault && type !== 'number' && !rest.disabled && !noClose && (
          <div
            onClick={handleClean}
            className={cn(
              'input-icon-close',
              classNameCloseIcon,
              !endAdornment ? '!-mr-6' : '',
              type === 'password' ? '!right-20' : ''
            )}
          >
            <span>
              <CloseIcon className="mb-[15px]" />
            </span>
          </div>
        )}
      </>
    </div>
  );
};

export default forwardRef(Input);
