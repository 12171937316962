'use client';

import { AnimatePresence, motion, useInView, Variants } from 'framer-motion';
import { FC, HTMLAttributes, PropsWithChildren, useMemo, useRef } from 'react';
import { cn } from 'utils/cn';

export interface IEntering extends PropsWithChildren<HTMLAttributes<HTMLDivElement>> {
  offset?: number;
  position?: 'left' | 'right' | 'top' | 'bottom';
  stiffness?: number;
  duration?: number;
  damping?: number;
  mass?: number;
  amount?: 'some' | 'all' | number;
  delay?: number;
  initial?: boolean;
}

const Entering: FC<IEntering> = ({
  offset = 50,
  position: initPosition = 'bottom',
  duration = 0.2,
  stiffness = 100,
  damping = 12,
  mass = 1,
  delay = 0.0,
  amount = 0.7,
  children,
  initial = true,
  className = '',
  style = {}
}) => {
  const ref = useRef(null);
  const inView = useInView(ref, {
    once: true,
    amount
  });

  const isActive = initial || inView;

  const position = useMemo(() => {
    if (initPosition === 'left') {
      return { x: -1 * offset * 2 };
    }
    if (initPosition === 'right') {
      return { x: offset };
    }
    if (initPosition === 'top') {
      return { y: -1 * offset };
    }
    if (initPosition === 'bottom') {
      return { y: offset };
    }
  }, [offset, initPosition]);

  const variants: Variants = {
    inactive: {
      opacity: 1,
      y: 0,
      x: 0
    },
    out: {
      opacity: 0
    },
    in: {
      ...position,
      opacity: 0
    }
  };

  return (
    <AnimatePresence mode="wait" initial={initial}>
      <>
        <div ref={ref} className={cn(className, isActive ? 'hidden' : 'opacity-0')} style={style}>
          {children}
        </div>
        {isActive && (
          <motion.div
            variants={variants}
            initial="in"
            animate="inactive"
            exit="out"
            className={className}
            style={style}
            transition={{
              type: 'spring',
              damping,
              stiffness,
              restDelta: 0.01,
              mass,
              duration,
              delay
            }}
          >
            {children}
          </motion.div>
        )}
      </>
    </AnimatePresence>
  );
};

export default Entering;
