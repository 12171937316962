import { FC } from 'react';
import { TypeOptions } from 'react-toastify';

export interface IMsgToastIcon {
  type?: TypeOptions;
}

const MsgToastIcon: FC<IMsgToastIcon> = ({ type = 'default' }) => {
  const colors = {
    info: {
      ring: '#E3F3FF',
      background: '#F3FAFF',
      path: '#3B7CC9'
    },
    success: {
      ring: '#E3F3FF',
      background: '#F3FAFF',
      path: '#3B7CC9'
    },
    warning: {
      ring: '#E3F3FF',
      background: '#F3FAFF',
      path: '#3B7CC9'
    },
    error: {
      ring: '#E3F3FF',
      background: '#F3FAFF',
      path: '#3B7CC9'
    },
    default: {
      ring: '#E3F3FF',
      background: '#F3FAFF',
      path: '#3B7CC9'
    }
  } as Record<
    TypeOptions,
    {
      ring: string;
      background: string;
      path: string;
    }
  >;

  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="44" height="44" fill="none" viewBox="0 0 44 44">
      <rect width="40" height="40" x="2" y="2" fill={colors[type].ring} rx="20"></rect>
      <rect
        width="40"
        height="40"
        x="2"
        y="2"
        stroke={colors[type].background}
        strokeWidth="4"
        rx="20"
      ></rect>
      <path
        fill={colors[type].path}
        fillRule="evenodd"
        d="M19.4 15a5.4 5.4 0 00-.338 10.79 1 1 0 01.938.998v.943l1.825-1.669a1 1 0 01.675-.262h2.1a5.4 5.4 0 000-10.8h-5.2zM12 20.4a7.4 7.4 0 017.4-7.4h5.2a7.4 7.4 0 010 14.8h-1.712l-3.213 2.938A1 1 0 0118 30v-2.332a7.402 7.402 0 01-6-7.268zm5-1.4a1 1 0 011-1h5a1 1 0 110 2h-5a1 1 0 01-1-1zm1 2a1 1 0 100 2h8a1 1 0 100-2h-8z"
        clipRule="evenodd"
      ></path>
    </svg>
  );
};

export default MsgToastIcon;
