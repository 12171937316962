import {
  ErrorFeaturedIcon,
  InfoFeaturedIcon,
  SuccessFeaturedIcon,
  WarningFeaturedIcon
} from 'lib/Icons';
import { FC } from 'react';
import { IconProps } from 'react-toastify';

const IconToast: FC<IconProps> = ({ type }) => {
  if (type === 'info') return <InfoFeaturedIcon />;

  if (type === 'warning') return <WarningFeaturedIcon />;

  if (type === 'success') return <SuccessFeaturedIcon />;

  if (type === 'error') return <ErrorFeaturedIcon />;

  return null;
};

export default IconToast;
