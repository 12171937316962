import { FC } from 'react';
import { cn } from 'utils/cn';

type ISpinning = React.HTMLAttributes<HTMLDivElement>;

const Spinning: FC<ISpinning> = ({ className = 'border-white' }) => {
  return (
    <div className="flex w-5 items-center justify-center">
      <div
        className={cn(
          'absolute h-5 w-5 animate-spin rounded-full border-2 border-solid border-secondary-gray',
          className,
          'border-t-transparent'
        )}
      ></div>
    </div>
  );
};

export default Spinning;
