'use client';
import { CalendarIcon, CloseIcon } from 'lib/Icons';
import { useLocale, useTranslations } from 'next-intl';
import { cn } from 'utils/cn';
import Datepicker, { DatepickerType, DateValueType } from './Datepicker';

import './styles.scss';

const BLANK_VALUE = {
  startDate: null,
  endDate: null
};
export interface IDateValueProps extends DatepickerType {
  value: DateValueType;
  name: string;
  onChange: (value: DateValueType, e?: HTMLInputElement | null | undefined) => void;
  className?: string;
  noYesterday?: boolean;
}

const DateRange = ({
  value,
  name,
  onChange,
  className,
  asSingle,
  placeholder = '--/--/--- ~ --/--/---',
  noYesterday = false,
  ...rest
}: IDateValueProps) => {
  const locale = useLocale();
  const t = useTranslations();

  const isDefault = !value || (!value.startDate && !value.endDate);

  const handleClean = () => onChange(BLANK_VALUE);

  return (
    <div className="container-input">
      <>
        <div className={cn('h-auto w-full', className)}>
          <Datepicker
            i18n={locale}
            primaryColor="blue"
            value={value}
            showShortcuts={true}
            onChange={onChange}
            placeholder={placeholder}
            separator="~"
            configs={{
              shortcuts: {
                today: t('components.date_range.shortcuts.today'),
                ...(!noYesterday && { yesterday: t('components.date_range.shortcuts.yesterday') }),
                ...(!asSingle
                  ? {
                      past: (days) => t('components.date_range.shortcuts.last_days', { days }),
                      currentMonth: t('components.date_range.shortcuts.current_month'),
                      pastMonth: t('components.date_range.shortcuts.past_month')
                    }
                  : {})
              },
              footer: {
                cancel: t('components.date_range.footer.cancel'),
                apply: t('components.date_range.footer.apply')
              }
            }}
            inputClassName={cn(
              'w-full rounded pl-3 outline-none',
              'border-1 border-tertiary-600 focus:border-primary-blue-200 dark:border-white',
              '!py-0 font-content text-sm',
              ' placeholder:text-tertiary-400 dark:bg-quaternary-200',
              'min-h-[36px] focus:ring focus:ring-primary-blue-900'
            )}
            containerClassName="daterange-container"
            toggleIcon={() => <CalendarIcon className="mt-[2px]" />}
            inputId={name}
            inputName={name}
            displayFormat="DD/MM/YYYY"
            popoverDirection="down"
            asSingle={asSingle}
            useRange={false}
            {...rest}
          />
        </div>
        {!isDefault && (
          <div onClick={handleClean} className="input-icon-close">
            <span>
              <CloseIcon className="mt-[1px]" />
            </span>
          </div>
        )}
      </>
    </div>
  );
};

export default DateRange;
