'use client';

import type * as PopperJS from '@popperjs/core';
import type React from 'react';
import { useCallback, useRef, useState } from 'react';
import { usePopper } from 'react-popper';
import { cn } from 'utils/cn';

type ITooltipTolq = {
  label: React.ReactElement;
  placement?: PopperJS.Placement;
  enable?: boolean;
  enterDelay?: number;
  className?: string;
  leaveDelay?: number;
} & React.HTMLAttributes<HTMLDivElement>;

const TooltipTolq: React.FC<ITooltipTolq> = ({
  children,
  label,
  enterDelay = 250,
  leaveDelay = 150,
  enable = true,
  className,
  placement = 'bottom'
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [referenceElement, setReferenceElement] = useState<HTMLDivElement | null>(null);
  const [popperElement, setPopperElement] = useState<HTMLDivElement | null>(null);
  const { styles, attributes } = usePopper(referenceElement, popperElement, {
    placement,
    modifiers: [{ name: 'offset', options: { offset: [0, 4] } }]
  });

  const enterTimeout = useRef<NodeJS.Timeout>();
  const leaveTimeout = useRef<NodeJS.Timeout>();

  const isOpenResult = isOpen && enable;

  const handleMouseEnter = useCallback(() => {
    leaveTimeout.current && clearTimeout(leaveTimeout.current);
    enterTimeout.current = setTimeout(() => setIsOpen(true), enterDelay);
  }, [enterDelay]);

  const handleMouseLeave = useCallback(() => {
    enterTimeout.current && clearTimeout(enterTimeout.current);
    leaveTimeout.current = setTimeout(() => setIsOpen(false), leaveDelay);
  }, [leaveDelay]);

  return (
    <div>
      <div
        ref={setReferenceElement}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
        className="relative"
      >
        {children}
      </div>

      <div
        ref={setPopperElement}
        style={{ ...styles.popper, pointerEvents: isOpenResult ? 'auto' : 'none' }}
        {...attributes.popper}
        className={cn(
          'transition-opacity',
          isOpenResult ? 'pointer-events-auto' : 'pointer-events-none',
          isOpenResult ? 'opacity-100' : 'opacity-0',
          className
        )}
      >
        {label}
      </div>
    </div>
  );
};

export default TooltipTolq;
