'use client';

import { Auth } from '@aws-amplify/auth';
import { configAws } from 'constants/aws';
import { LocalAuth } from 'lib/LocalAuth';
import { notifyException } from 'services/appsignal';

export const getToken = async () => {
  try {
    if (process.env.NEXT_PUBLIC_LOCAL_AUTH === 'true') {
      const session = new LocalAuth();

      return session.currentSession().getAccessToken().getJwtToken();
    }

    Auth.configure(configAws.Auth);

    const jwtToken = (await Auth.currentSession()).getAccessToken().getJwtToken();

    return jwtToken;
  } catch (error) {
    notifyException(error);

    return '';
  }
};
