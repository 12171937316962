'use client';
import { Typography } from 'components';
import { FALLBACK_GRAVATAR } from 'constants/images';
import { IMPERSONATE_ID_PERSISTED_STATE } from 'constants/persistedStates';
import usePersistentContext from 'hooks/usePersistenceState';
import Image from 'next/image';
import { useCurrentUser } from 'providers/UserProvider/useCurrentUser';
import { FC, useEffect, useMemo } from 'react';
import Arrow from './Arrow';

interface IPerson {
  open?: boolean;
}

const Person: FC<IPerson> = ({ open }) => {
  const { data: currentUser, refetch } = useCurrentUser();
  const [impersonateToken] = usePersistentContext(IMPERSONATE_ID_PERSISTED_STATE);

  const { data } = useCurrentUser();

  const imageUrl = useMemo(
    () => `https://gravatar.com/avatar/${data?.gravatarEmailHash}?d=${FALLBACK_GRAVATAR}&s=${200}`,
    [data?.gravatarEmailHash]
  );

  useEffect(() => {
    if (impersonateToken) refetch();
  }, [impersonateToken, refetch]);

  return (
    <span className="m-2 items-center">
      <span className="flex w-full cursor-pointer items-center">
        <Image
          alt="user-img"
          className=" h-8 shrink-0 rounded-full bg-white"
          height={32}
          width={32}
          src={imageUrl}
          priority={false}
        />
        <span className="ml-4 w-full select-none text-lg leading-normal text-white transition duration-500 ease-in-out ">
          <>
            {impersonateToken && (
              <div className="absolute top-0 h-0">
                <Typography variant="light5" align="left" className="!leading-[0rem]">
                  Impersonating:
                </Typography>
              </div>
            )}
          </>
          <Typography variant="regular3" align="right" className="text-right">
            {currentUser?.name ? currentUser?.name : 'loading...'}
          </Typography>
        </span>
        <Arrow open={open} className="fill-white" classNameParent="my-2 ml-4" />
      </span>
    </span>
  );
};

export default Person;
