'use client';
import { Typography } from 'components';
import { motion } from 'framer-motion';
import { cn } from 'utils/cn';

import { FC, HTMLAttributes, PropsWithChildren, ReactNode } from 'react';

import '../styles.scss';

interface IButtonFile extends PropsWithChildren<HTMLAttributes<HTMLInputElement>> {
  /**
   * Is this the principal call to action on the page?
   */
  variant?: 'outlined' | 'contained' | 'text-small' | 'text';
  /**
   * How large should the button be?
   */
  size?: 'small' | 'medium' | 'large';
  /**
   * What colort should the button be?
   */
  color?: 'primary' | 'secondary' | 'danger';
  /**
   * Should the button be rounded on border?
   */
  name?: string;
  /**
   * What is the name of the button?
   */
  rounded?: boolean;
  /**
   * Button contents
   */
  label?: string;
  /**
   * Button disabled
   */
  startAdornment?: ReactNode;
  endAdornment?: ReactNode;
  disabled?: boolean;
  children?: ReactNode;
}

const ButtonFile: FC<IButtonFile> = ({
  children,
  onChange,
  variant = 'contained',
  size = 'large',
  label = '',
  color = 'primary',
  rounded = false,
  disabled = false,
  startAdornment = null,
  endAdornment = null,
  className = '',
  name,
  ...rest
}) => {
  return (
    <motion.div
      whileTap={{ scale: variant.includes('icon') ? 0.9 : 0.95 }}
      transition={{
        type: 'spring',
        stiffness: 300,
        damping: 25,
        restDelta: 10,
        mass: 1
      }}
    >
      <input className="hidden" type="file" id={name} name={name} onChange={onChange} {...rest} />
      <label
        htmlFor={name}
        className={cn(
          'tolq-button',
          disabled ? 'button--disabled' : `button--${color}`,
          `button--${variant}`,
          `button--${size}`,
          rounded ? 'button--rounded' : '',
          className
        )}
      >
        {startAdornment}
        <Typography variant={variant === 'text-small' ? 'regular4' : 'medium3'}>
          {label}
          {children}
        </Typography>
        {endAdornment}
      </label>
    </motion.div>
  );
};
export default ButtonFile;
