import { useTranslations } from 'next-intl';
import CountUp from 'react-countup';
import Typography from './Typography';

interface ISummaryCard {
  description?: string;
  value?: number | string | null;
  segment?: number | null;
  percentage?: boolean;
}

const SummaryCard = ({ description, value = 0, segment = 0 }: ISummaryCard) => {
  const t = useTranslations();

  const isPercentage = String(value).includes('%');
  const valueParsed = isPercentage ? String(value).replace('%', '') : value;
  return (
    <div className="flex flex-col justify-evenly gap-4 rounded-[5px] bg-white py-2 pl-5 shadow-custom dark:bg-quaternary-200">
      <Typography variant="medium4" className="text-tertiary-100 dark:text-white">
        {description}
      </Typography>

      <Typography
        variant="bold1"
        className="text-5xl text-primary-blue-100 dark:text-primary-blue-200"
      >
        <CountUp delay={2} end={Number(valueParsed)} />
        {isPercentage && '%'}
      </Typography>
      <Typography
        variant="light5"
        className="flex flex-row items-center gap-4 text-tertiary-200 dark:text-tertiary-300"
      >
        {t('components.summary_card.segments_containing')}
        <Typography variant="medium5" className="text-tertiary-100 dark:text-white">
          <CountUp delay={2} end={Number(segment)} /> {t('components.summary_card.words')}
        </Typography>
      </Typography>
    </div>
  );
};

export default SummaryCard;
