'use client';
import { Button, Select } from 'components';
import { AddIcon, CloseIcon } from 'lib/Icons';
import { createRef, FC, KeyboardEvent, useCallback, useMemo, useState } from 'react';
import { FieldError, Merge } from 'react-hook-form';
import { Option } from 'types/Option';
import { isArray } from 'underscore';
import { cn } from 'utils/cn';
import Input, { textTypes, TextTypes } from '../Input';

import './styles.scss';

export type MultipleTypes = TextTypes | 'select';

interface IMultiplesInputs {
  error?: Merge<FieldError, (FieldError | undefined)[]>;
  /*
   * Hint of the input
   * accepts string
   */
  /*
   * Type of the input
   */
  type: MultipleTypes;
  /**
   * Options
   * accepts array of object with label and value both as string
   */
  options?: Option[];
  /*
   * Label of button
   */
  labelButton?: string;
  /*
   * Value of the input
   */
  value?: string[] | Option[];
  /**
   * Selected value
   * @default ''
   * */
  defaultOption?: Option;
  /*
   * onChange function of the input
   */
  onChange?: (values: (string | Option)[]) => void;
  /**
   * Optional change handler
   * @default () => {}
   */
}

const MultiplesInputs: FC<IMultiplesInputs> = ({
  error,
  type,
  options = [],
  labelButton,
  value: unprocessedValues,
  defaultOption,
  onChange
}): JSX.Element => {
  const [processing, setProcessing] = useState(false);
  const refs = Array(5)
    .fill(null)
    .map(() => createRef<HTMLInputElement>());

  const values = useMemo(
    () => (unprocessedValues?.length ? unprocessedValues : ['']),
    [unprocessedValues]
  );

  const [autoFocus, setAutoFocus] = useState(false);

  const deleteLine = useCallback(
    (index: number) => {
      if (onChange && !processing) {
        setProcessing(true);
        onChange([...((values as string[]) ?? [])].filter((_, i) => i !== index));
        setTimeout(() => setProcessing(false), 1000);
      }
    },
    [onChange, processing, values]
  );

  const handlerOnChange = useCallback(
    (index: number) => (e: React.ChangeEvent<HTMLInputElement>) => {
      if (onChange && !!values?.length) {
        e?.stopPropagation?.();
        e?.preventDefault?.();
        setAutoFocus(true);
        const newValues = [...values];

        if (!e.target.value) return deleteLine(index);

        newValues[index] = e.target.value;

        return onChange(newValues as string[]);
      }
    },
    [deleteLine, onChange, values]
  );

  const handlerOnChangeSelect = useCallback(
    (index: number) => (option: Option) => {
      if (onChange && !!values?.length) {
        setAutoFocus(true);
        const newValues = [...values];
        newValues[index] = option;

        onChange(newValues as Option[]);
      }
    },
    [onChange, values]
  );

  const handlerAddLine = useCallback(() => {
    if (onChange) {
      setAutoFocus(true);
      return onChange([...(values ?? []), '']);
    }
  }, [onChange, values]);

  const handleKeyDown = useCallback(
    (value: string, index: number) => (e: KeyboardEvent<HTMLInputElement>) => {
      if (value === '' && e.key === 'Backspace') return deleteLine(index);
    },
    [deleteLine]
  );

  const handleRemoveSelect = (index: number) => () => {
    if (onChange) return onChange([...((values as Option[]) ?? [])].filter((_, i) => i !== index));
  };

  return (
    <div className="tolq-multiples-inputs">
      {values?.map((value, index) => {
        if (textTypes.includes(type))
          return (
            <Input
              key={`mutiple-input-${index}`}
              ref={refs[index]}
              id={`mutiple-input-${index}`}
              value={value as string}
              type={'text'}
              error={error && isArray(error) ? ((error as FieldError[]) ?? [])?.at(index) : false}
              onKeyDown={handleKeyDown(value as string, index)}
              onChange={handlerOnChange(index)}
              autoFocus={autoFocus}
            />
          );

        return (
          <div
            key={`mutiple-input-${index}`}
            className={cn('w-full', index === 0 ? '' : 'flex items-center gap-2')}
          >
            <Select
              options={options ?? []}
              selectedValue={(value as Option)?.value as string}
              defaultOption={defaultOption}
              onChange={handlerOnChangeSelect(index)}
            />
            {index !== 0 && (
              <Button
                size="small"
                variant="icon"
                color="neutral"
                className="!mt-0 mb-2 h-7 w-8"
                onClick={handleRemoveSelect(index)}
              >
                <CloseIcon />
              </Button>
            )}
          </div>
        );
      })}
      <Button
        label={labelButton}
        startAdornment={<AddIcon className="icon" />}
        variant="text-small"
        size="small"
        colorIcon="none"
        onClick={handlerAddLine}
        className="!flex !justify-start !pl-0"
      />
    </div>
  );
};

export default MultiplesInputs;
