import { useQuery, UseQueryOptions } from '@tanstack/react-query';
import { User } from 'gql/graphql';
import { gql } from 'graphql-request';
import { TGenericError } from 'types/TGenericError';
import clientUtil from 'utils/clientUtil';

type TData = User;

export const useCurrentUser = (
  options: Omit<UseQueryOptions<unknown, TGenericError, TData>, 'queryKey'> = {}
) => {
  const client = clientUtil();

  const currentUserGQL = gql`
    query currentUser {
      currentUser {
        id
        admin
        impersonatingUser
        canDeleteAccount
        email
        firstName
        gravatarEmailHash
        lastName
        locale
        name
        phoneNumberVerificationCodeSentCount
        provider
        timeZone
        twoFactorAuthenticationEnabled
        unconfirmedEmail
        verifiedPhoneNumber
        role
        accessKey
        emailPreferences {
          dailyClientNotification
          reviewStyleGuide
          translatorComments
        }
      }
    }
  `;

  return useQuery<unknown, TGenericError, TData>({
    ...options,
    queryKey: ['currentUser'],
    queryFn: async () =>
      client.request({
        data: {
          gql: currentUserGQL
        }
      })
  });
};
