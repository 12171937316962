import { i18n, Locale } from 'i18n-config';

const importLocale = (locale: Locale) =>
  import(`../locales/${locale}.json`, {
    assert: { type: 'json' }
  });

const loadLocale = (locale: Locale) => async () =>
  importLocale(locale)
    .then((module) => module.default)
    .catch(async () => importLocale(i18n.defaultLocale).then((module) => module.default));

const locales = i18n.locales.reduce(
  (acc, locale) => ({
    ...acc,
    [locale]: loadLocale(locale)
  }),
  {} as Record<Locale, () => Promise<Record<string, string>>>
);

export const getLocalesWithoutCache = (locale: Locale) => {
  const foundLocale = (i18n.locales.includes(locale) ? locale : i18n.defaultLocale) as Locale;

  return locales[foundLocale]();
};
