import { RightActiveIcon, RightInactiveIcon } from 'lib/Icons';
import { FC } from 'react';
import { cn } from 'utils/cn';
import { PaginationSimpleProps } from '.';

type INext = PaginationSimpleProps;

const Next: FC<INext> = ({ totalPages, page, changePage }) => {
  const handlerOnClick = () => changePage(page + 1);

  return (
    <li
      className={cn(
        page === 10 || page === totalPages ? 'cursor-not-allowed' : 'cursor-pointer',
        totalPages === 1 || page === totalPages ? 'opacity-0' : 'opacity-1'
      )}
      onClick={handlerOnClick}
    >
      <span className={cn('flex h-7 w-7 items-center justify-center')}>
        {page === totalPages ? (
          <RightInactiveIcon className="scale-[1.5]" />
        ) : (
          <RightActiveIcon className="scale-[1.5]" />
        )}
      </span>
    </li>
  );
};

export default Next;
