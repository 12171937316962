import { useQuery, UseQueryOptions } from '@tanstack/react-query';
import { ClientAccount } from 'gql/graphql';
import { gql } from 'graphql-request';
import { camelize } from 'humps';
import { FeatureFlagEnum, FeatureFlags } from 'types/TFeatureFlag';
import { TGenericError } from 'types/TGenericError';
import clientUtil from 'utils/clientUtil';

type TData = FeatureFlags;

export const useFeatureFlags = (
  options: Omit<UseQueryOptions<unknown, TGenericError, TData>, 'queryKey'> = {}
) => {
  const client = clientUtil();

  const graphql = gql`
    query clientAccount {
      clientAccount {
        features {
          enabled
          feature
        }
      }
    }
  `;

  const factoryFlags = (clientAccount?: ClientAccount, bypass?: boolean) =>
    Object.values(FeatureFlagEnum).reduce((acc: FeatureFlags, value: string) => {
      const key = `${camelize(value)}Enabled`;
      const foundEnabled = clientAccount?.features?.find((f) => f.feature === value)?.enabled;

      return {
        ...acc,
        [key]: bypass || !!foundEnabled
      };
    }, {} as FeatureFlags);

  return useQuery<unknown, TGenericError, TData>({
    ...options,
    queryKey: ['featureFlags'],
    queryFn: async () => {
      const response: ClientAccount = await client.request({
        data: {
          gql: graphql
        }
      });

      return factoryFlags(response);
    }
  });
};
