import { Typography } from 'components';
import { FC, HTMLAttributes, PropsWithChildren } from 'react';
import { cn } from 'utils/cn';

interface ILabel extends Omit<HTMLAttributes<HTMLLabelElement>, 'htmlFor'> {
  name?: string;
}

const Label: FC<PropsWithChildren<ILabel>> = ({ name, children, className, ...rest }) => {
  return (
    <label htmlFor={name} className={cn('my-2 inline-block', className)} {...rest}>
      <Typography variant="regular4" className={cn('text-tertiary-100 !delay-0 dark:text-white')}>
        {children}
      </Typography>
    </label>
  );
};

export default Label;
