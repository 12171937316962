'use client';

import {
  AccountActiveIcon,
  AccountInactiveIcon,
  AmplifyActiveIcon,
  AmplifyInactiveIcon,
  BillingActiveIcon,
  BillingInactiveIcon,
  ChatActiveIcon,
  ChatInactiveIcon,
  ContentGenerationActiveIcon,
  ContentGenerationInactiveIcon,
  ContextLibraryActiveIcon,
  ContextLibraryInactiveIcon,
  DashboardActiveIcon,
  DashboardInactiveIcon,
  GlossaryActiveIcon,
  GlossaryInactiveIcon,
  IntegrationsActiveIcon,
  IntegrationsInactiveIcon,
  OverviewActiveIcon,
  OverviewInactiveIcon,
  ProjectsActiveIcon,
  ProjectsInactiveIcon,
  SettingsActiveIcon,
  SettingsInactiveIcon,
  SmartTasksActiveIcon,
  SmartTasksInactiveIcon,
  StyleGuideActiveIcon,
  StyleGuideInactiveIcon,
  TranslationMemoryActiveIcon,
  TranslationMemoryInactiveIcon,
  TranslationsActiveIcon,
  TranslationsInactiveIcon
} from 'lib/Icons';
import { useTranslations } from 'next-intl';
import { usePathname } from 'next/navigation';
import { useCurrentAuthTime } from 'providers/AWSProvider/useCurrentAuthTime';
import { useFeatureFlags } from 'providers/ClientProviders/useFeatureFlags';
import { useCurrentUser } from 'providers/UserProvider/useCurrentUser';
import { FC, useCallback, useMemo } from 'react';
import { ProductFruits } from 'react-product-fruits';
import { cn } from 'utils/cn';
import MenuExpansive from './MenuExpansive';
import MenuItem, { IMenuItem } from './MenuItem';

const Menu: FC<unknown> = () => {
  const pathname = usePathname();
  const t = useTranslations();

  const { data: currentUser, isFetched } = useCurrentUser();
  const { data: currentAuthTime, isFetched: isFetchedCurrentAuthTime } = useCurrentAuthTime();

  const { data: featureFlags } = useFeatureFlags();

  const factoryActiveRule = (route: string, pathname: string) =>
    route === '/dashboard'
      ? pathname?.search(route) !== -1 && pathname?.search('/dashboard') === -1
      : pathname?.search(route) !== -1;

  const factoryMenu = useCallback(
    (items: IMenuItem[]) =>
      items
        .filter((item) => item.visible)
        .map((item) => ({
          ...item,
          childrens: item?.childrens?.map((child) => ({
            ...child,
            active: factoryActiveRule(child?.route, pathname)
          })),
          active:
            pathname?.search(item?.route) !== -1 ||
            item?.childrens?.some((child) => factoryActiveRule(child?.route, pathname))
        })),
    [pathname]
  );

  const menuPartI = useMemo(
    () =>
      factoryMenu([
        {
          iconActive: <DashboardActiveIcon className="h-6 w-6" />,
          iconInactive: <DashboardInactiveIcon className="h-6 w-6" />,
          name: t('components.menu.title.dashboard'),
          route: '/dashboard',
          visible: true
        },
        {
          iconActive: <ProjectsActiveIcon className="h-6 w-6" />,
          iconInactive: <ProjectsInactiveIcon className="h-6 w-6" />,
          name: t('components.menu.title.projects'),
          route: '/projects',
          visible: featureFlags?.projectsEnabled
        },
        // {
        //   iconActive: <OrganizationActiveIcon className="h-6 w-6"/>,
        //   iconInactive: <OrganizationInactiveIcon className="h-6 w-6"/>,
        //   name: t('components.menu.title.organization'),
        //   route: '/organization'
        // },
        {
          iconActive: <SmartTasksActiveIcon className="h-6 w-6 transition-all" />,
          iconInactive: <SmartTasksInactiveIcon className="h-6 w-6 transition-all" />,
          name: t('components.menu.title.smart_tasks'),
          route: '/smart-tasks/chat-ai',
          visible: true,
          notCollapsible: true,
          childrens: [
            {
              iconActive: <ChatActiveIcon className="h-6 w-6 transition-all" />,
              iconInactive: <ChatInactiveIcon className="h-6 w-6 transition-all" />,
              name: t('components.menu.title.chat'),
              route: '/smart-tasks/chat-ai',
              visible: true
            },
            {
              iconActive: <ContextLibraryActiveIcon className="h-6 w-6 transition-all" />,
              iconInactive: <ContextLibraryInactiveIcon className="h-6 w-6 transition-all" />,
              name: t('components.menu.title.context_library'),
              route: '/smart-tasks/context-library',
              visible: true
            }
          ]
        },
        {
          iconActive: <ContentGenerationActiveIcon className="h-6 w-6 transition-all" />,
          iconInactive: <ContentGenerationInactiveIcon className="h-6 w-6 transition-all" />,
          name: t('components.menu.title.content_generation'),
          route: '/content-creator',
          visible: featureFlags?.contentGenerationEnabled
        },
        {
          iconActive: <AmplifyActiveIcon className="h-6 w-6 transition-all" />,
          iconInactive: <AmplifyInactiveIcon className="h-6 w-6 transition-all" />,
          name: t('components.menu.title.amplify'),
          route: '/amplify',
          visible: featureFlags?.amplifyEnabled
        },
        {
          iconActive: <TranslationsActiveIcon className="h-6 w-6 transition-all" />,
          iconInactive: <TranslationsInactiveIcon className="h-6 w-6 transition-all" />,
          name: t('components.menu.title.translation'),
          route: '/dashboard/translation-requests',
          visible: true,
          childrens: [
            {
              iconActive: <OverviewActiveIcon className="h-6 w-6 transition-all" />,
              iconInactive: <OverviewInactiveIcon className="h-6 w-6 transition-all" />,
              name: t('components.menu.title.overview'),
              route: '/dashboard/translation-requests',
              visible: true
            },
            {
              iconActive: <StyleGuideActiveIcon className="h-6 w-6 transition-all" />,
              iconInactive: <StyleGuideInactiveIcon className="h-6 w-6 transition-all" />,
              name: t('components.menu.title.style_guides'),
              route: '/style-guides',
              visible: true
            },
            {
              iconActive: <GlossaryActiveIcon className="h-6 w-6 transition-all" />,
              iconInactive: <GlossaryInactiveIcon className="h-6 w-6 transition-all" />,
              name: t('components.menu.title.glossary'),
              route: '/glossary',
              visible: true
            },
            {
              iconActive: <TranslationMemoryActiveIcon className="h-6 w-6 transition-all" />,
              iconInactive: <TranslationMemoryInactiveIcon className="h-6 w-6 transition-all" />,
              name: t('components.menu.title.translation_memory'),
              route: '/translation-memory',
              visible: true
            }
          ]
        }
      ]),
    [featureFlags, pathname, t]
  );

  const menuPartII = useMemo(
    () =>
      factoryMenu([
        {
          iconActive: <SettingsActiveIcon className="h-6 w-6 transition-all" />,
          iconInactive: <SettingsInactiveIcon className="h-6 w-6 transition-all" />,
          name: t('components.menu.title.settings'),
          route: '/integrations',
          visible: true,
          childrens: [
            {
              iconActive: <IntegrationsActiveIcon className="h-6 w-6 transition-all" />,
              iconInactive: <IntegrationsInactiveIcon className="h-6 w-6 transition-all" />,
              name: t('components.menu.title.integrations'),
              route: '/integrations',
              visible: true
            },
            {
              iconActive: <AccountActiveIcon className="h-6 w-6 transition-all" />,
              iconInactive: <AccountInactiveIcon className="h-6 w-6 transition-all" />,
              name: t('components.menu.title.account_details'),
              route: '/account-details',
              visible: true
            },
            {
              iconActive: <BillingActiveIcon className="h-6 w-6 transition-all" />,
              iconInactive: <BillingInactiveIcon className="h-6 w-6 transition-all" />,
              name: t('components.menu.title.billing'),
              route: '/billing',
              visible: true
            }
          ]
        }
      ]),
    [featureFlags?.projectsEnabled, pathname, t]
  );

  return (
    <>
      <aside className="fixed h-[calc(100vh-80px)] self-stretch" aria-label="Sidebar">
        <div
          className={cn(
            'flex h-full w-60 flex-1 flex-col overflow-y-auto px-3 py-4',
            'bg-white dark:bg-quaternary-200',
            'justify-between'
          )}
        >
          <ul className="space-y-1">
            {menuPartI.map(
              ({ route, iconActive, iconInactive, name, childrens, active, notCollapsible }) => (
                <li key={route + name + active}>
                  {!!childrens?.length ? (
                    <MenuExpansive
                      title={name}
                      active={active}
                      iconActive={iconActive}
                      iconInactive={iconInactive}
                      notCollapsible={notCollapsible}
                      route={route}
                    >
                      <>
                        {childrens.map((items) => (
                          <MenuItem key={items.name + items.route + items.active} {...items} />
                        ))}
                      </>
                    </MenuExpansive>
                  ) : (
                    <MenuItem
                      {...{
                        route,
                        active,
                        iconActive,
                        iconInactive,
                        name
                      }}
                    />
                  )}
                </li>
              )
            )}
          </ul>
          <ul className="space-y-1">
            {menuPartII.map(
              ({
                route,
                active,
                iconActive,
                iconInactive,
                name,
                className = '',
                childrens,
                onClick
              }) => (
                <li key={route + name + active}>
                  {childrens ? (
                    <MenuExpansive
                      title={name}
                      active={active}
                      iconActive={iconActive}
                      iconInactive={iconInactive}
                      route={route}
                    >
                      <>
                        {childrens.map((items) => (
                          <MenuItem key={items.name + items.route + items.active} {...items} />
                        ))}
                      </>
                    </MenuExpansive>
                  ) : (
                    <MenuItem
                      {...{
                        route,
                        active,
                        iconActive,
                        iconInactive,
                        name,
                        className,
                        onClick
                      }}
                    />
                  )}
                </li>
              )
            )}
          </ul>
        </div>
      </aside>
      {typeof window !== 'undefined' && !!currentUser?.id && isFetchedCurrentAuthTime && (
        <ProductFruits
          workspaceCode={String(process.env.NEXT_PUBLIC_PRODUCT_FRUITS_ID)}
          user={{
            username: String(currentUser?.id),
            email: String(currentUser?.email),
            firstname: String(currentUser?.firstName),
            lastname: String(currentUser?.lastName),
            signUpAt: currentAuthTime,
            role: String(currentUser?.role),
            props: { platform: 'clients' }
          }}
          lifeCycle="neverUnmount"
          language="en"
          config={{ disableLocationChangeDetection: true }}
        />
      )}
    </>
  );
};

export default Menu;
