'use client';
import { Dialog, Transition } from '@headlessui/react';
import { CloseCircleIcon } from 'lib/Icons';
import { FC, Fragment, PropsWithChildren } from 'react';
import { cn } from 'utils/cn';
import Button from './Buttons/Button';
import Typography from './Typography';

interface IModal {
  open: boolean;
  header?: boolean;
  title?: string;
  zDialog?: string;
  zDialogBackdrop?: string;
  onClose: () => void;
  className?: string;
  classNameChild?: string;
}

const Modal: FC<PropsWithChildren<IModal>> = ({
  title,
  open,
  children,
  className = '',
  classNameChild = '',
  zDialog = 'z-50',
  zDialogBackdrop = 'z-[49]',
  onClose
}) => (
  <Transition appear show={open} as={Fragment}>
    <Dialog
      as="div"
      className={cn('fixed inset-0 z-10 overflow-y-auto', zDialog)}
      onClose={onClose}
    >
      <div className="flex min-h-screen items-center justify-center px-4 text-center">
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className={cn('fixed inset-0 bg-black/30', zDialogBackdrop)} />
        </Transition.Child>
        {/* This element is to trick the browser into centering the modal contents. */}
        <span className="inline-block h-screen align-middle" aria-hidden="true">
          &#8203;
        </span>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0 scale-95"
          enterTo="opacity-100 scale-100"
          leaveFrom="opacity-30 scale-100"
          leaveTo="opacity-0 scale-95 height-0"
        >
          <div
            className={cn(
              'inline-block align-middle',
              'w-full p-6 text-left transition-all',
              'overflow-hidden',
              zDialog,
              classNameChild
            )}
          >
            <div className="flex w-full justify-center">
              <Dialog.Panel className={cn('relative z-50 flex justify-center ', className)}>
                {title && (
                  <div className="flex h-16 items-center justify-between bg-white py-5 pl-6 pr-5 shadow-xl">
                    <Typography variant="bold3" className={'w-full text-tertiary-100'}>
                      {title}
                    </Typography>
                    <Button
                      variant="icon-btn"
                      onClick={onClose}
                      size="small"
                      color="neutral"
                      startAdornment={<CloseCircleIcon height={30} width={30} />}
                    />
                  </div>
                )}
                {children}
              </Dialog.Panel>
            </div>
          </div>
        </Transition.Child>
      </div>
    </Dialog>
  </Transition>
);

export default Modal;
