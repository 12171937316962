import { CurrencyEnum } from 'gql/graphql';
import { useLocale } from 'next-intl';
import { FC } from 'react';
import { cn } from 'utils/cn';

interface IPrettyCurrency {
  currency?: CurrencyEnum | string;
  amount?: number;
  maximumFractionDigits?: number;
  minimumFractionDigits?: number;
  className?: string;
}

const PrettyCurrency: FC<IPrettyCurrency> = ({
  currency,
  amount: incomingAmout,
  maximumFractionDigits = 3,
  minimumFractionDigits = 2,
  className = 'currency_amount'
}) => {
  const amount = incomingAmout || 0;
  const locale = useLocale();

  const config = {
    maximumFractionDigits,
    minimumFractionDigits,
    ...(currency ? { currency, style: 'currency' } : {})
  };

  let formatParts;
  try {
    formatParts = Intl.NumberFormat(locale, config as any).formatToParts(amount);
  } catch (error) {
    formatParts = Intl.NumberFormat(undefined, config as any).formatToParts(amount);
  }

  // Possible types: currency, integer, group, decimal, fraction
  // currency is the money symbol or series of characters like € or EUR
  // group and decimal are the symbols separating digits like the comma (,) and dot (.) in 1,235.12
  // integer and fraction are the digits of the amount, with fraction corresponding to decimals
  return (
    <span className={cn(`${className}__wrapper`)}>
      {formatParts?.map(({ type, value }, index) => (
        <span key={`currency-part-${index}`} className={`${className}__${type}`}>
          {value}
        </span>
      ))}
    </span>
  );
};

export default PrettyCurrency;
