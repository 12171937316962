import { LoadingLogo, Transition } from 'components';

const Default = () => (
  <Transition>
    <div className="max-w-[ ] absolute z-50 h-[100vh]  w-full dark:bg-quaternary-100">
      <LoadingLogo size="small" />
    </div>
  </Transition>
);

export default Default;
