import { FC, MouseEvent } from 'react';
import Next from '../PaginationSimple/Next';
import Prev from '../PaginationSimple/Prev';
import FreeSelector from './FreeSelector';
import Numbers from './Numbers';

// Props
// page:          only way to define the current page, the only way to change it is external
// totalPages
// className:     optional, class the ul inside the nav has in addition to *pagination*
// windowSize:    optional, defines the max number of shown clickable pages. If it is not possible to show all pages
//                within the given size ellipsis will be used to skip them
// changePage:    callback to call every time a page change is attempted

const WINDOW_SIZE = 7;

export interface PaginationProps {
  totalPages: number;
  page: number;
  changePage: (page: number, e?: MouseEvent<HTMLLIElement>) => void;
  windowSize: number;
}
interface IPagination extends Omit<PaginationProps, 'windowSize'> {
  changePage: (page: number) => void;
  className?: string;
  freeSelector?: boolean;
  windowSize?: number;
}

const Pagination: FC<IPagination> = ({
  totalPages,
  page,
  className,
  windowSize = WINDOW_SIZE,
  freeSelector = true,
  changePage
}) => {
  const handlerChangePage = (page: number, e?: MouseEvent<HTMLLIElement>) => {
    if (e) e.preventDefault();

    if (page > 0 && page <= totalPages) {
      changePage(page);
    }
  };

  const paginationProps = {
    totalPages,
    page,
    changePage: handlerChangePage,
    windowSize
  };

  if (totalPages < 2) return null;

  return (
    <nav className={className}>
      <ul className={`flex !h-[28px] flex-row !gap-2`}>
        <Prev {...paginationProps} />
        <Numbers {...paginationProps} />
        <Next {...paginationProps} />
        {freeSelector && <FreeSelector {...paginationProps} />}
      </ul>
    </nav>
  );
};

export default Pagination;
