import { Input, Typography } from 'components';
import { useTranslations } from 'next-intl';
import { ChangeEvent, FC } from 'react';
import { cn } from 'utils/cn';
import { PaginationProps } from '.';

type IFreeSelector = PaginationProps;

const FreeSelector: FC<IFreeSelector> = ({ totalPages, page, changePage, windowSize }) => {
  const t = useTranslations();
  const fixValue = (value: string) => {
    const valueNumber = parseInt(value, 10);

    if (valueNumber > totalPages) return totalPages;
    if (valueNumber < 1) return 1;
    return valueNumber;
  };

  const handlerChangePage = (e: ChangeEvent<HTMLInputElement>) =>
    changePage(fixValue(e.target.value));

  if (totalPages <= windowSize) return null;

  return (
    <li>
      <div className="flex w-full">
        <Typography
          variant="regular4"
          className={cn('w-full min-w-[95px] text-center', 'text-quaternary-600')}
        >
          {t('components.pagination.go_to_page')}
        </Typography>
        <Input
          className="!h-[28px] !min-h-0 w-16"
          type="number"
          value={page}
          onChange={handlerChangePage}
          max={totalPages}
          min={1}
        />
      </div>
    </li>
  );
};

export default FreeSelector;
