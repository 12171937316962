import { Typography } from 'components';

import { FC, PropsWithChildren } from 'react';
import { cn } from 'utils/cn';

const TooltipContent: FC<PropsWithChildren<unknown>> = ({ children }) => (
  <div className="cursor-pointer rounded-3xl border border-tertiary-400 bg-primary-blue-900 p-2 shadow">
    <Typography variant="regular4" className={cn('text-tertiary-100')}>
      {children}
    </Typography>
  </div>
);

export default TooltipContent;
