import { Typography } from 'components';

import { CommentsIcon } from 'lib/Icons';
import { useTranslations } from 'next-intl';
import { FC } from 'react';

const NoComment: FC = () => {
  const t = useTranslations();

  return (
    <div className="flex h-full w-full flex-col justify-center">
      <div className="flex flex-col items-center justify-between">
        <div>
          <CommentsIcon className="h-11 w-11" />
        </div>
        <Typography variant="light5" className="text-center text-tertiary-200">
          <div className="max-w-[124px]">{t('components.comments.no_comment')}</div>
        </Typography>
      </div>
    </div>
  );
};

export default NoComment;
