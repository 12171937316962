'use client';

import { Typography } from 'components';

import { Comment } from 'gql/graphql';
import { useTranslations } from 'next-intl';
import { FC, MouseEvent, useEffect, useRef } from 'react';
import { cn } from 'utils/cn';
import { dateTimeShort } from 'utils/time/dateTimeShort';
import { StatusTags, themeColorTag } from '..';
import Tag from '../../Tag';

interface IBubble {
  comment: Comment;
  onClickTag?: (tags: string) => void;
  onMouseOver?: (tags: string[]) => void;
  onMouseLeave: () => void;
  updateStatusTags?: (statusTags: StatusTags) => void;
  markAsRead?: (id: string) => void;
}

const Bubble: FC<IBubble> = ({
  comment,
  onClickTag,
  onMouseOver,
  onMouseLeave,
  updateStatusTags,
  markAsRead
}) => {
  const t = useTranslations();
  const targetRef = useRef<HTMLDivElement>(null);

  const themeColor = (() => {
    if (comment?.isCorrection) return 'border-secondary-yellow/50 bg-primary-orange-1200';

    if (comment?.writtenByClient) return 'border-primary-green-100/80 bg-primary-green-300';

    return 'border-secondary-blue/50 bg-primary-blue-900';
  })();

  const themeDirection = (() => {
    if (comment?.writtenByClient) return 'rounded-l-7xl rounded-br-7xl';

    return 'rounded-r-7xl rounded-bl-7xl';
  })();

  const handleMouseTagBubble = () => {
    if (onMouseOver) onMouseOver(comment.refs);

    if (updateStatusTags)
      updateStatusTags(
        (() => {
          if (comment?.isCorrection) return StatusTags.OrangeLight;

          if (comment?.writtenByClient) return StatusTags.GreenLight;

          return StatusTags.BlueLight;
        })()
      );
  };

  const handleMouseOverTag = (tag: string) => (e: MouseEvent<HTMLDivElement>) => {
    if (onMouseOver) onMouseOver([tag]);
    if (updateStatusTags)
      updateStatusTags(
        (() => {
          if (comment?.isCorrection) return StatusTags.Orange;

          if (comment?.writtenByClient) return StatusTags.Green;

          return StatusTags.Blue;
        })()
      );

    e.stopPropagation();
    e.preventDefault();
  };
  const handleOnClickTag = (tag: string) => () => onClickTag?.(tag);

  useEffect(() => {
    if (!comment.read) {
      {
        const observer = new IntersectionObserver(
          ([entry]) => {
            // Update state based on whether the target element is visible
            if (entry.isIntersecting) markAsRead?.(comment.task.id);
          },
          {
            root: null, // Use the viewport as the root
            rootMargin: '0px', // No margin
            threshold: 0.5 // Trigger when 50% of the target is visible
          }
        );

        const targetElement = targetRef.current;
        if (targetElement) {
          observer.observe(targetElement);
        }

        return () => {
          // Clean up the observer when the component unmounts
          observer.disconnect();
        };
      }
    }
  }, [comment.id, comment.read, comment.task.id, comment.writtenByClient, markAsRead]); // Empty dependency array means this effect runs once after mount

  return (
    <div
      className={cn('flex w-full', comment?.writtenByClient ? 'justify-end' : 'justify-start')}
      onMouseOver={handleMouseTagBubble}
      onMouseLeave={onMouseLeave}
      ref={targetRef}
    >
      <div className="flex w-fit max-w-[80%] flex-col">
        <div
          className={cn(
            'flex justify-between gap-4',
            comment?.writtenByClient ? 'flex-row' : 'flex-row-reverse'
          )}
        >
          <Typography variant="light6" className="text-tertiary-200 ">
            {dateTimeShort(comment.createdAt)}
          </Typography>
          <Typography variant="medium5" className="font text-tertiary-100">
            {comment?.writtenByClient
              ? t('components.comments.you')
              : comment?.translatorAccount?.user?.name ?? ''}
          </Typography>
        </div>
        <div>
          <div
            className={cn(
              'cursor-pointer border px-2 shadow-md hover:shadow-custom-task',
              'transition-all duration-200 ease-in-out',
              comment.text ? 'pb-1' : 'pb-2',
              !!comment?.refs.length ? 'pt-2' : 'pt-1',
              themeColor,
              themeDirection
            )}
          >
            {!!comment?.refs.length && (
              <div className="flex flex-wrap gap-2">
                {comment?.refs?.map((reference) => (
                  <Tag
                    key={reference}
                    onMouseOver={handleMouseOverTag(reference)}
                    onMouseLeave={onMouseLeave}
                    className={themeColorTag(comment?.isCorrection, comment?.writtenByClient)}
                    onClick={handleOnClickTag(reference)}
                  >
                    {`#${reference}`}
                  </Tag>
                ))}
              </div>
            )}
            {comment.text && (
              <Typography className="break-all leading-3 text-gray-800" variant="light4">
                {comment.text}
              </Typography>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Bubble;
