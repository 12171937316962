'use client';

import { DARK, LIGHT } from 'constants/constants';
import { DARK_MODE_PERSISTED_STATE } from 'constants/persistedStates';
import usePersistentContext from 'hooks/usePersistenceState';
import { FC } from 'react';
import ToggleButton from './ToggleButton';

const DarkModeButton: FC = () => {
  const [darkMode, setDarkMode] = usePersistentContext<string>(DARK_MODE_PERSISTED_STATE);

  const handleActivation = () => {
    setDarkMode(darkMode === DARK ? LIGHT : DARK);
  };

  const enabled = darkMode === DARK;

  return <ToggleButton enabled={enabled} onClick={handleActivation} />;
};

export default DarkModeButton;
