'use client';
import Button from 'components/Buttons/Button';
import { CloseIcon } from 'lib/Icons';
import { useTranslations } from 'next-intl';
import { FC, ReactNode, RefObject, useCallback } from 'react';
import { Option } from 'types/Option';
import { cn } from 'utils/cn';
import Checkbox from '../Checkbox';
import '../input.scss';

export type Checkboxes = (Omit<Option, 'label'> & { label: string | ReactNode })[];

export interface IFilterInput {
  /*
   * checkboxes of the input
   */
  checkboxes?: Checkboxes;
  /*
   * value of the input
   */
  value?: string[];
  /*
   * onChange function of the input
   */
  noClear?: boolean;
  /*
   * noClear parameter to disable clear
   */
  className?: string;
  /*
   * className
   */
  onChange?: (values: string[]) => void;
  /*
   * Refs for the checkboxes
   */
  refs?: Map<string, RefObject<HTMLDivElement>>;
}

const FilterInput: FC<IFilterInput> = ({
  checkboxes = [],
  value = [],
  className,
  noClear = false,
  refs,
  onChange
}): JSX.Element => {
  const t = useTranslations();
  const isChecked = (checkboxValue: string) => value.includes(checkboxValue);

  const isDefault = !checkboxes.some((item) => isChecked(item?.value as string));

  const handleOnChange = useCallback(
    (checkboxValue: string) => () => {
      const result = value.includes(checkboxValue)
        ? value.filter((value) => value !== checkboxValue)
        : [...value, checkboxValue];

      if (onChange) onChange(result);
    },
    [onChange, value]
  );

  const handleClean = () => onChange && onChange([]);

  return (
    <div className={cn('container-input', className)}>
      <>
        <div className="gap-4 rounded dark:bg-quaternary-100">
          {checkboxes?.map(({ label, icon, value }, index) => {
            const checked = isChecked(value as string);
            const isLast = index === checkboxes?.length - 1;
            const isFirst = index === 0;

            return (
              <div
                ref={refs?.get(String(value))}
                key={String(label && typeof label !== 'object' ? label : (value as string))}
                className={cn(
                  'flex min-h-[36px] items-center border-x-1 border-tertiary-600 py-2 pl-4',
                  checked ? '' : 'bg-white',
                  isFirst ? 'rounded-t border-t-1' : '',
                  isLast ? 'rounded-b border-b-1' : 'border-b-1',
                  className
                )}
              >
                <Checkbox
                  startAdornment={icon}
                  checked={checked}
                  onChange={handleOnChange(value as string)}
                  name={value as string}
                  label={label}
                />
              </div>
            );
          })}
        </div>
        {!noClear && !isDefault && (
          <div onClick={handleClean} className="input-icon-close !-top-9 !right-[76px]">
            <Button
              size="small"
              variant="text"
              color="secondary"
              label={t('components.filter_input.clear')}
              className="filter-input !m-0 !h-7 w-fit !rounded-full !px-2 !shadow-none"
              classNameTypography="text-xs !m-0"
              endAdornment={<CloseIcon className="w-4" />}
              onClick={handleClean}
            />
          </div>
        )}
      </>
    </div>
  );
};

export default FilterInput;
