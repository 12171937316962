'use client';

import { FC, ReactNode } from 'react';
import { Option } from 'types/Option';
import { cn } from 'utils/cn';
import Button from '../../Buttons/Button';
import Label from '../Label';

export type ValueOptionCheckButtons<T extends string | number | symbol = string> = Record<
  T,
  boolean
>;
interface ICheckButtons {
  /**
   * What is the name of the CheckButtons?
   */
  name?: string;
  /**
   *CheckButtons label
   */
  label?: string | ReactNode;
  /**
   * checkbox checked
   */
  value: ValueOptionCheckButtons;
  /**
   * Options buttons
   */
  options: Option[];
  /**
   * Change handler
   */
  onChange: (value: ValueOptionCheckButtons) => void;
}

const CheckButtons: FC<ICheckButtons> = ({ options, label, value: values, onChange }) => {
  const handleOnChange = (value: string) => () => onChange({ ...values, [value]: !values[value] });

  return (
    <div className="mb-[2px] mt-2 flex w-full items-center gap-2">
      <Label>{label}</Label>
      <div className="flex w-full shrink gap-2">
        {options.map(({ label, value, icon }) => (
          <Button
            key={label}
            size="small"
            variant="text-small"
            onClick={handleOnChange(value as string)}
            label={label}
            colorIcon={values[value as string] ? 'white' : 'blue'}
            sizeIcon="small"
            className={cn(
              'delay-100 duration-200',
              '!h-8 !w-fit !rounded-11xl !border',
              '!m-0 gap-1 !px-2 !py-1',
              values[value as string]
                ? '!border-transparent !bg-primary-blue-100'
                : '!border-primary-blue-200 !bg-tertiary-900'
            )}
            classNameTypography={cn(
              '!m-0 !p-0 text-sm',
              values[value as string] ? 'font-light !text-white' : 'font-light !text-tertiary-200'
            )}
            startAdornment={icon}
          />
        ))}
      </div>
    </div>
  );
};

export default CheckButtons;
