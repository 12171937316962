import { Transition } from '@headlessui/react';
import { Typography } from 'components';

import { FC, Fragment, PropsWithChildren, ReactNode, useEffect, useState } from 'react';
import { ArrowContainer, Popover, PopoverProps } from 'react-tiny-popover';

import './styles.scss';
import { defaultArrowProps } from './_constants';

interface IBasicPopover extends Omit<PopoverProps, 'content'> {
  isOpen: boolean;
  msg: string | ReactNode;
}

const BasicPopover: FC<PropsWithChildren<IBasicPopover>> = ({
  isOpen,
  children,
  msg,
  positions = ['bottom']
}) => {
  const [open, setOpen] = useState(false);

  const direction = positions.includes('top') ? '-translate-y-2' : 'translate-y-2';

  useEffect(() => {
    setTimeout(() => {
      setOpen(isOpen);
    }, 300);
  }, [isOpen]);

  return (
    <Popover
      isOpen={isOpen}
      positions={positions}
      containerStyle={{
        maxWidth: '400px',
        overflow: 'visible'
      }}
      content={({ position, childRect, popoverRect }) => (
        <ArrowContainer {...{ position, childRect, popoverRect, ...defaultArrowProps }}>
          <Transition
            show={open}
            as={Fragment}
            enter="transition ease-out duration-200"
            enterFrom={`opacity-0 ${direction}`}
            enterTo="opacity-100 translate-y-0"
            leave="transition ease-in duration-150"
            leaveFrom="opacity-100 translate-y-0"
            leaveTo={`opacity-0 ${direction}`}
          >
            <div className="popover">
              <Typography
                variant="regular4"
                className=" relative text-tertiary-100 dark:text-tertiary-300"
              >
                {msg}
              </Typography>
            </div>
          </Transition>
        </ArrowContainer>
      )}
    >
      {children}
    </Popover>
  );
};

export default BasicPopover;
