'use client';

import { Typography } from 'components';

import { FileDownloadIcon } from 'lib/Icons';
import { FC, PropsWithChildren } from 'react';
import Button from './Buttons/Button';

interface IListButtons {
  options?: { label: string; onClick?: () => void; labelClick?: string; loading?: boolean }[];
  title?: string;
  labelClickDefault?: string;
}

const ListButtons: FC<PropsWithChildren<IListButtons>> = ({
  title,
  labelClickDefault,
  options
}) => (
  <div>
    {title && (
      <div className="mb-2">
        <Typography variant="light4" className=" text-tertiary-200 dark:text-tertiary-400">
          {title}
        </Typography>
      </div>
    )}
    <div className="flex flex-col gap-2">
      {options?.map(({ label, labelClick, onClick, loading }) => (
        <div className="flex justify-between rounded-lg bg-tertiary-900 p-2 px-4" key={label}>
          <span className="mt-1 flex h-6 gap-4">
            <FileDownloadIcon className="mt-[6px] scale-150" />
            <Typography
              variant="regular4"
              className="!m-0 text-tertiary-200 dark:text-tertiary-400"
            >
              {label.length < 20 ? label : label?.slice(0, 20) + '...'}
            </Typography>
          </span>
          <Button
            size="large"
            variant="text"
            label={labelClick ?? labelClickDefault}
            className="!m-0 !h-8 !p-0 text-primary-blue-100"
            onClick={onClick}
            classNameTypography="!text-lg text-primary-blue-100"
            classNameLoading="border-primary-blue-100 border-t-transparent top-[4px]"
            loading={loading}
          />
        </div>
      ))}
    </div>
  </div>
);

export default ListButtons;
