'use client';

import { getImpersonationIdUtil } from './getImpersonationIdUtil';
import { getToken } from './getToken';

export const buildHeadersUtil = async () => {
  const jwtToken = await getToken();

  const impersonatedUserId = getImpersonationIdUtil();

  return {
    ...(jwtToken ? { Authorization: `Bearer ${jwtToken}` } : {}),
    ...(impersonatedUserId ? { 'X-Impersonated-User-Id': impersonatedUserId } : {})
  };
};
