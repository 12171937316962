import { QueryClient } from '@tanstack/react-query';

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: true,
      gcTime: Infinity,
      retryDelay: 3000, //3s
      staleTime: 1000 //1s
    }
  }
});

export default queryClient;
