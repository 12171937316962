'use client';
import { DARK, LIGHT } from 'constants/constants';
import { DARK_MODE_PERSISTED_STATE } from 'constants/persistedStates';
import usePersistentContext from 'hooks/usePersistenceState';
import { usePathname } from 'next/navigation';
import { useFeatureFlags } from 'providers/ClientProviders/useFeatureFlags';
import { useCallback, useEffect, useMemo } from 'react';

export default function ThemeSelector({ children }: any) {
  const [darkMode, setDarkMode] = usePersistentContext(DARK_MODE_PERSISTED_STATE);
  const { data: featureFlags } = useFeatureFlags();

  const pathname = usePathname();

  const darkModeResult = useMemo(() => {
    if (typeof window !== 'undefined' && featureFlags?.darkModeEnabled) {
      if (darkMode) return darkMode === DARK ? DARK : LIGHT;
    }

    return LIGHT;
  }, [darkMode]);

  const darkModeInitial = useCallback(() => {
    const languageAuthRegex = /^\/([a-z]{2})\/auth\//;
    const match = pathname.match(languageAuthRegex);

    if (match && darkMode) return setDarkMode(LIGHT);

    if (typeof window !== 'undefined' && !darkMode) {
      const darkModeMedia = window.matchMedia('(prefers-color-scheme: light)');
      if (darkModeMedia.matches) return setDarkMode(LIGHT);

      return setDarkMode(DARK);
    }
  }, [darkMode, pathname, setDarkMode]);

  useEffect(() => {
    darkModeInitial();
  }, [darkModeInitial]);

  return <div className={darkModeResult}>{children}</div>;
}
