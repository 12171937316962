import { FC, Fragment, ReactNode } from 'react';
import { Option } from 'types/Option';
import { dateShort } from 'utils/time/dateShort';
import Typography from './Typography';

interface ICardList extends React.HTMLAttributes<HTMLDivElement> {
  icon: ReactNode;
  title: string;
  time: string;
  uptitle?: string;
  noColon?: boolean;
  subtitles: Option[];
  buttonsLeft: ReactNode[];
  buttonsRight: ReactNode | ReactNode[];
}

const CardList: FC<ICardList> = ({
  icon,
  title,
  time,
  uptitle,
  noColon,
  subtitles,
  buttonsLeft,
  buttonsRight,
  ...rest
}) => {
  return (
    <div
      className="flex h-full flex-1 flex-col justify-between rounded-md bg-white p-4 shadow-custom"
      {...rest}
    >
      <div className="flex justify-start">
        <div className="mr-4 h-12 w-10">{icon}</div>
        <div className="flex w-full flex-col">
          <div className="relative">
            <div className="mb-2">
              {uptitle && (
                <Typography variant="medium4" className="text-tertiary-200">
                  {uptitle}
                </Typography>
              )}
              <Typography
                variant="medium1"
                className="block w-[calc(100%_-_80px)] break-words !leading-6 text-tertiary-100"
              >
                {title}
              </Typography>
            </div>

            <Typography
              variant="light4"
              className="absolute right-0 top-0 w-28 text-end text-tertiary-200"
            >
              {dateShort(time)}
            </Typography>
          </div>
          <div className="flex flex-wrap gap-2">
            {subtitles?.length ? (
              <>
                {subtitles.map(({ label, value, icon, component }, index) => (
                  <Fragment key={index}>
                    <>
                      {(icon || label || noColon) && (
                        <Typography variant="light4" className=" text-tertiary-200">
                          {icon}
                          {label}
                          {noColon ? '' : ':'}
                        </Typography>
                      )}
                      {value && (
                        <Typography variant="medium4" className="text-primary-blue-100">
                          {(value as string) ?? ''}
                        </Typography>
                      )}
                      {component}
                    </>
                  </Fragment>
                ))}
              </>
            ) : (
              ''
            )}
          </div>
        </div>
      </div>
      <div className="shrink-0">
        <div className="my-4 border-0 border-t-1 border-transparent border-t-tertiary-600" />
        <div className="flex justify-between">
          <span className="flex gap-4">
            {buttonsLeft.map((button, index) => (
              <Fragment key={index}>{button}</Fragment>
            ))}
          </span>
          <div className="flex gap-4">{buttonsRight}</div>
        </div>
      </div>
    </div>
  );
};

export default CardList;
