import { Typography } from 'components';
import { cn } from 'utils/cn';

import { FC } from 'react';
import { PaginationProps } from '.';

type IAllNumbers = PaginationProps;

const AllNumbers: FC<IAllNumbers> = ({ page, totalPages, changePage }) => {
  const allPages = Array.from({ length: totalPages }).map((_, i) => i + 1);

  const handlerOnClick = (numberPage: number) => (e: React.MouseEvent<HTMLLIElement>) => {
    e.preventDefault();
    changePage(numberPage, e);
  };

  return (
    <>
      {allPages.map((p) => (
        <li
          key={`all-number${p}`}
          className={cn(
            'cursor-pointer',
            'flex !h-7 !w-7 items-center justify-center rounded',
            p === page ? 'bg-primary-blue-100 ' : 'border-1 border-quaternary-700 bg-white'
          )}
          onClick={handlerOnClick(p)}
        >
          <a href="#" className={'flex text-white'}>
            <Typography
              variant="regular4"
              className={cn(
                'w-full pt-[2px] text-center',
                p === page ? ' text-white' : ' text-quaternary-600'
              )}
            >
              {p}
            </Typography>
          </a>
        </li>
      ))}
    </>
  );
};

export default AllNumbers;
