export const buildQueryKey = (variables: object) => {
  type Keys = keyof typeof variables;

  return Object.keys(variables)
    .sort()
    .reduce(
      (acc, key) => [
        ...acc,
        JSON.stringify({
          [key]:
            variables[key as Keys] === undefined || variables[key as Keys] === null
              ? 'null'
              : variables[key as Keys]
        }).replace(/[{}"']|,(\w+:)/g, '')
      ],
      [] as string[]
    );
};
