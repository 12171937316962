'use client';
import { ARROW_LEFT, ARROW_RIGHT, BACKSPACE, CLEAR } from 'constants/keyboard';
import {
  ChangeEvent,
  FC,
  FocusEvent,
  InputHTMLAttributes,
  KeyboardEvent,
  useCallback,
  useEffect,
  useRef,
  useState
} from 'react';
import { cn } from 'utils/cn';
import './../input.scss';
import './styles.scss';

export interface InputCodeProps
  extends Omit<InputHTMLAttributes<HTMLInputElement>, 'value' | 'name'> {
  length?: number;
  value?: string;
  name?: string;
}

const LIMIT_CHAR = 1;

const InputCode: FC<InputCodeProps> = ({ length = 4, value = '', name, onChange, ...isProps }) => {
  const containerRef = useRef<HTMLDivElement | null>(null);
  const [currentInput, setCurrentInput] = useState(0);

  function handlePaste(e: ClipboardEvent) {
    e.preventDefault();
    const clipboardData = e.clipboardData || (window as any).clipboardData;
    const pastedText = clipboardData.getData('text').slice(0, 6);

    containerRef.current?.querySelectorAll('input').forEach((input, index) => {
      input.value = pastedText[index] || '';
      handleChange(input);
    });
  }

  function handleFocus(e: FocusEvent<HTMLTextAreaElement | HTMLInputElement>) {
    e.target.select();

    if (!e.target.value) {
      containerRef.current?.querySelectorAll('input')[currentInput]?.focus();
    }
    setCurrentInput(Number(e.target.getAttribute('role')?.replace('input-', '')));
  }

  function currentInputIsValid(e?: HTMLTextAreaElement | HTMLInputElement) {
    const input = e || containerRef.current?.querySelectorAll('input')[currentInput];
    return input && input.value;
  }

  function manageCurrentInput(newIndex: number, e?: HTMLTextAreaElement | HTMLInputElement) {
    if (currentInputIsValid(e) && newIndex < length && newIndex >= 0) {
      setCurrentInput(newIndex);
    }
  }

  function handleChange(e: ChangeEvent<HTMLTextAreaElement | HTMLInputElement> | HTMLInputElement) {
    let value = '';
    containerRef.current?.querySelectorAll('input').forEach((evt) => {
      value += evt.value;
    });
    onChange && onChange({ target: { value, name } } as ChangeEvent<HTMLInputElement>);
    manageCurrentInput(currentInput + 1, 'target' in e ? e.target : e);
    if ('preventDefault' in e) {
      e.stopPropagation();
      e.preventDefault();
    }
  }

  function handleKeyChange(e: KeyboardEvent<HTMLInputElement | HTMLTextAreaElement>) {
    const lastItem = containerRef.current?.querySelectorAll('input')[currentInput - 1];
    switch (e.key) {
      case CLEAR:
      case BACKSPACE:
        if (!currentInputIsValid(e.target as HTMLInputElement)) {
          manageCurrentInput(currentInput - 1, lastItem);
        }
        break;
      case ARROW_LEFT:
        manageCurrentInput(currentInput - 1, lastItem);
        break;
      case ARROW_RIGHT:
        manageCurrentInput(currentInput + 1);
        break;
    }
  }

  useEffect(() => {
    const input = containerRef.current?.querySelectorAll('input')[currentInput] as HTMLInputElement;
    if (input && !input.value) {
      input.focus();
      input.setSelectionRange(LIMIT_CHAR, LIMIT_CHAR);
    }
  }, [currentInput]);

  useEffect(() => {
    if (typeof window !== 'undefined') window.document?.addEventListener('paste', handlePaste);
    return () => {
      window.document?.removeEventListener('paste', handlePaste);
    };
  }, []);

  const updateContainer = useCallback(() => {
    containerRef.current?.querySelectorAll('input').forEach((input, index) => {
      input.value = value[index] || '';
      handleChange(input);
    });
  }, [value]);

  useEffect(() => {
    updateContainer();
  }, [updateContainer]);

  return (
    <div ref={containerRef} className={cn('input-code-highlight flex items-center justify-center')}>
      {Array.from({ length }, (_, i) => i).map((i) => (
        <input
          key={i}
          {...isProps}
          role={`input-${i}`}
          maxLength={LIMIT_CHAR}
          onKeyDown={handleKeyChange}
          onFocus={handleFocus}
          onChange={handleChange}
          className={`input-code`}
          name={name}
          placeholder={'0'}
          autoComplete="off"
          autoCorrect="off"
        />
      ))}
    </div>
  );
};

export default InputCode;
