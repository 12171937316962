import { FC } from 'react';
import LoadingLogo from './LoadingLogo';

const Loading: FC = () => {
  return (
    <div className="absolute z-50 h-[100vh] w-[100vw]  dark:bg-quaternary-100">
      <LoadingLogo />
    </div>
  );
};

export default Loading;
