'use client';
import { Typography } from 'components';
import { cn } from 'utils/cn';

import { FC } from 'react';
import './RadioButton.scss';

interface RadioButtonProps {
  /**
   *Radio  contents
   */
  label: string;

  value: string;
  /**
   * Radio checked
   */
  checked?: boolean;

  /**
   * Radio Disabled
   */
  disabled?: boolean;
  /**
   * Optional change handler
   */
  onChange: (value: string) => void;
}
const RadioButton: FC<RadioButtonProps> = ({
  label,
  value,
  checked,
  onChange,
  disabled = false
}) => {
  const handleOnChange = () => {
    onChange(value);
  };
  return (
    <label className="mb-4 flex cursor-pointer items-center" htmlFor={value}>
      <input
        type="radio"
        id={value}
        className={cn('hidden')}
        value={value}
        checked={checked}
        disabled={disabled}
        onChange={handleOnChange}
      />
      <span
        className={cn(
          'radio-button',
          checked ? ' border-primary-green-100' : 'border-tertiary-400'
        )}
        onClick={!disabled ? handleOnChange : undefined}
      >
        {checked && <span className="h-2 w-2 rounded-full  bg-primary-green-100" />}
        {disabled && checked && <span className="h-2 w-2 rounded-full  bg-tertiary-400" />}
        {disabled && !checked && <span className="h-2 w-2 rounded-full " />}
      </span>
      <Typography
        variant="regular4"
        className={disabled ? 'text-tertiary-400' : 'text-tertiary-100'}
      >
        {label}
      </Typography>
    </label>
  );
};

export default RadioButton;
