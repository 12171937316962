'use client';

import { Disclosure, DisclosureButton, DisclosurePanel, Transition } from '@headlessui/react';
import { Typography } from 'components';
import Arrow from 'components/Arrow';
import { useRouter } from 'next/navigation';

import { FC, MouseEvent, PropsWithChildren, ReactNode, useCallback } from 'react';
import { cn } from 'utils/cn';

interface IMenuExpansive {
  title?: string;
  route?: string;
  active?: boolean;
  notCollapsible?: boolean;
  iconActive?: ReactNode;
  iconInactive?: ReactNode;
}

const MenuExpansive: FC<PropsWithChildren<IMenuExpansive>> = ({
  children,
  active,
  notCollapsible,
  iconActive,
  iconInactive,
  title,
  route
}) => {
  const router = useRouter();

  const handleOnClick = useCallback((e: MouseEvent<HTMLButtonElement>) => {
    if (!active && route) router.push(route);

    if (notCollapsible) {
      e.stopPropagation();
      e.preventDefault();
    }
  }, []);

  return (
    <div className="w-full">
      <div className="w-full max-w-md">
        <Disclosure defaultOpen={active || notCollapsible}>
          {({ open }) => {
            const result = open || active;

            return (
              <div
                className={cn(
                  'rounded-5xl transition-colors duration-200 ease-in-out',
                  'outline-none'
                )}
              >
                <DisclosureButton
                  className={cn(
                    'transition-colors duration-200 ease-in-out',
                    'group flex w-full items-center justify-between',
                    'text-left text-sm font-medium',
                    'focus-visible:ring-offset-primary-blue-500/75',
                    'focus:outline-none focus-visible:ring',
                    'mb-1 rounded-lg px-2 py-2 hover:bg-gray-100',
                    'text-tertiary-100'
                  )}
                  onClick={handleOnClick}
                >
                  <div className="flex gap-2">
                    {active ? iconActive : iconInactive}
                    <Typography
                      variant={active ? 'medium3' : 'light3'}
                      className={cn(
                        'user-select-none h-7 select-none transition-all duration-200 dark:text-white',
                        active ? 'text-tertiary-100' : 'pt-[3px] text-tertiary-300'
                      )}
                    >
                      {title}
                    </Typography>
                  </div>
                  <span className="mr-2">
                    <Arrow
                      open={!result}
                      className={notCollapsible ? 'fill-transparent' : 'fill-tertiary-300'}
                    />
                  </span>
                </DisclosureButton>
                <Transition
                  enter="transition duration-200 ease-out"
                  enterFrom="transform h-0 opacity-0"
                  enterTo="transform h-full opacity-100"
                  leave="transition duration-75 ease-out"
                  leaveFrom="transform h-full opacity-100"
                  leaveTo="transform h-0 opacity-0"
                >
                  <DisclosurePanel className="flex flex-col gap-1 pl-6 text-sm">
                    {children}
                  </DisclosurePanel>
                </Transition>
              </div>
            );
          }}
        </Disclosure>
      </div>
    </div>
  );
};

export default MenuExpansive;
