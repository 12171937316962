import { i18n, Locale } from 'i18n-config';
import { get } from 'lodash';
import { getLocalesWithoutCache } from './getLocalesWithoutCache';

const i18nUtil = async (pathname: string | null) => {
  if (pathname) {
    const pathnameHasLocale = i18n.locales.some(
      (locale) => !pathname.startsWith(`/${locale}/`) && pathname !== `/${locale}`
    );

    const pathnameLocale = pathname.split('/')?.at(1);

    if (pathnameHasLocale && pathnameLocale) {
      const object = await getLocalesWithoutCache(pathnameLocale as Locale);

      return {
        t: (key: string) => get(object, key, key)
      };
    }
  }

  const object = await getLocalesWithoutCache(i18n.defaultLocale as Locale);

  return {
    t: (key: string) => get(object, key, key)
  };
};

export default i18nUtil;
