'use client';
import { Typography } from 'components';
import { ColorsStatus } from 'constants/status';
import { motion } from 'framer-motion';
import Link from 'next/link';
import { FC, PropsWithChildren, ReactNode } from 'react';
import { cn } from 'utils/cn';
import './styles.scss';

interface IBadge {
  icon?: JSX.Element;
  transparent?: boolean;
  color?: ColorsStatus;
  href?: string;
  endAdornment?: ReactNode;
  target?: string;
  className?: string;
  classNameTypography?: string;
  onClick?: () => void;
}

const Badge: FC<PropsWithChildren<IBadge>> = ({
  children = '',
  icon,
  color = 'cyan',
  transparent,
  href = '#',
  target = '',
  className = '',
  classNameTypography = '',
  endAdornment,
  onClick
}) => (
  <Link
    className={cn(
      'tolq-badge',
      transparent ? 'tolq-badge-transparent' : '',
      href ? 'cursor-pointer' : 'cursor-default'
    )}
    href={href}
    target={target}
    onClick={onClick}
  >
    <motion.button
      whileTap={{ scale: 0.95 }}
      transition={{
        type: 'spring',
        stiffness: 300,
        damping: 25,
        restDelta: 10,
        mass: 1
      }}
      className={cn(
        'flex h-7 w-max flex-row items-center gap-2 rounded-10xl pl-1 pr-2 outline-none',
        `badge--${color}`,
        className
      )}
    >
      {icon && <>{icon}</>}
      {children && (
        <Typography
          variant={transparent ? 'medium4' : 'regular4'}
          className={cn('!w-fit select-none text-white', classNameTypography)}
        >
          {children}
        </Typography>
      )}
      {endAdornment && <>{endAdornment}</>}
    </motion.button>
  </Link>
);
export default Badge;
