import escapeStringRegexp from 'escape-string-regexp';

export enum TypeHighlightRegExp {
  Default = 'default',
  Word = 'word'
}
export enum FlagHighlightRegExp {
  CaseSensitive = 'g',
  CaseInsensitive = 'gi'
}

export const highlightingRegExp = (
  searchQuery: string,
  type: TypeHighlightRegExp = TypeHighlightRegExp.Default,
  flags: FlagHighlightRegExp = FlagHighlightRegExp.CaseSensitive
) => {
  const escapedQuery = escapeStringRegexp(searchQuery);

  switch (type) {
    case TypeHighlightRegExp.Word:
      return new RegExp(`\\b${escapedQuery}\\b`, flags);
    default:
      return new RegExp(escapedQuery, flags);
  }
};

export const highlightRanges = (
  text: string,
  searchQuery: string,
  type?: TypeHighlightRegExp,
  flags?: FlagHighlightRegExp
) => {
  const ranges = [];
  let match;
  const rgx = highlightingRegExp(searchQuery, type, flags);

  if (searchQuery == '') return [];

  while ((match = rgx.exec(text))) {
    ranges.push({
      from: match.index,
      to: match.index + match[0].length
    });
  }

  return ranges;
};

export const contentIsJustSpaces = (text: string) => {
  return /^\s+$/.test(text);
};
