import { Typography } from 'components';
import { cn } from 'utils/cn';

import { FC, useMemo } from 'react';
import { PaginationProps } from '.';
import AllNumbers from './AllNumbers';

type INumbers = PaginationProps;

const Numbers: FC<INumbers> = ({ totalPages, page, windowSize, changePage }) => {
  const changeablePages = windowSize - 3; // defines how many positions can vary since showing first, last and current is a must
  const endRangeStart = totalPages - changeablePages; // if the current page is >= than this will show all pages
  const extraMiddleItems = Math.floor((changeablePages - 2) / 2); // if page in the middle the ellipsis take 2 spaces, so we can show half of the remaining to left and right

  const rangeAtStart = page <= changeablePages,
    rangeAtEnd = page >= endRangeStart,
    rangeAtMiddle = !rangeAtEnd && !rangeAtStart;

  let newPage = page - extraMiddleItems;

  const realPages = useMemo(
    () =>
      Array(windowSize)
        ?.fill(1)
        ?.map((_, i) => {
          const iterator = (i as number) + 1;

          if (iterator === 1) return { type: 'link', pageToRender: 1 };

          if (iterator === windowSize) return { type: 'link', pageToRender: totalPages };

          if (rangeAtStart && iterator <= windowSize - 2)
            return { type: 'link', pageToRender: iterator };

          if (rangeAtEnd && iterator > 2)
            return { type: 'link', pageToRender: totalPages - windowSize + iterator };

          if (rangeAtMiddle && iterator > 2 && iterator <= windowSize - 2) {
            const realPage = { type: 'link', pageToRender: newPage };
            newPage = newPage + 1;
            return realPage;
          }

          return { type: 'ellipsis', pageToRender: 0 };
        }),
    [windowSize, totalPages]
  );

  const handlerOnClick = (numberPage: number) => (e: React.MouseEvent<HTMLLIElement>) => {
    e.preventDefault();
    changePage(numberPage, e);
  };

  if (totalPages <= windowSize)
    return <AllNumbers {...{ totalPages, page, windowSize, changePage }} />;

  return (
    <>
      {realPages?.map(({ pageToRender, type }, i) => {
        if (type === 'ellipsis')
          return (
            <li className="mx-2 flex h-16 justify-center" key={`ellipsis-${i}`}>
              <span className="">...</span>
            </li>
          );

        return (
          <li
            className={cn(
              'cursor-pointer',
              'flex !h-7 !w-7 items-center justify-center rounded',
              pageToRender === page
                ? 'bg-primary-blue-100 '
                : 'border-1 border-quaternary-700 bg-white'
            )}
            key={`pagination-page-${pageToRender}`}
            onClick={handlerOnClick(pageToRender)}
          >
            <a href="#" className={'flex text-white'}>
              <Typography
                variant="regular4"
                className={cn(
                  'w-full pt-[2px] text-center',
                  pageToRender === page ? ' text-white' : ' text-quaternary-600'
                )}
              >
                {pageToRender}
              </Typography>
            </a>
          </li>
        );
      })}
    </>
  );
};

export default Numbers;
