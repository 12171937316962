'use client';

import { useRouter } from 'next/navigation';
import queryString from 'query-string';
import { useCallback, useEffect, useMemo, useState } from 'react';

// How do I get the pathname with hash.
// source: https://github.com/vercel/next.js/discussions/49465
export const useHashState = ({ noRedirect }: { noRedirect?: boolean } = { noRedirect: false }) => {
  const getCurrentHash = useMemo(
    () => () => typeof window !== 'undefined' ? window.location.hash.replace(/^#!?/, '') : '',
    []
  );

  const router = useRouter();
  const [hash, setHash] = useState<string>(getCurrentHash());

  const updateHash = useCallback(
    (newHash: string) => {
      const currentHash = getCurrentHash();
      if (currentHash !== newHash && !noRedirect) {
        let updatedUrl = window.location.href;
        updatedUrl = queryString.stringifyUrl({
          url: updatedUrl.split('#')[0],
          fragmentIdentifier: newHash
        });

        setHash(newHash);
        router.replace(updatedUrl);
      }
    },
    [getCurrentHash, router]
  );

  const handleHashChange = useCallback(() => {
    const currentHash = getCurrentHash();
    setHash(currentHash);
  }, [getCurrentHash]);

  useEffect(() => {
    const currentHash = getCurrentHash();
    setHash(currentHash);
  }, [getCurrentHash]);

  useEffect(() => {
    window.addEventListener('hashchange', handleHashChange);

    return () => {
      window.removeEventListener('hashchange', handleHashChange);
    };
  }, [handleHashChange]);

  return [hash, updateHash] as const;
};
