'use client';

import { CorrectingIcon, MicroIcon, UntranslatedIcon } from 'lib/Icons';
import Link from 'next/link';
import { FC, PropsWithChildren, useState } from 'react';

const SideMenu: FC<PropsWithChildren<unknown>> = ({ children }) => {
  const [dashBoard, setDashBoard] = useState(false);
  const [correcting, setCorrecting] = useState(false);
  const [micro, setMicro] = useState(false);

  const handleSetDashBoard = () => {
    setDashBoard(true);
    setCorrecting(false);
    setMicro(false);
  };

  const handleSetCorrecting = () => {
    setDashBoard(false);
    setCorrecting(true);
    setMicro(false);
  };

  const handleSetMicro = () => {
    setDashBoard(false);
    setCorrecting(false);
    setMicro(true);
  };

  const onClickDashBoard = handleSetDashBoard;
  const onClickCorrecting = handleSetCorrecting;
  const onClickMicro = handleSetMicro;
  return (
    <div className="hidden w-20 grid-cols-1 bg-tertiary-900 dark:bg-quaternary-300">
      <aside className="flex h-full" aria-label="Sidebar">
        <div>
          <ul className="ml-4 mt-4 space-y-6">
            <li
              onClick={onClickDashBoard}
              className={
                dashBoard
                  ? 'flex h-12 w-12 items-center justify-center rounded-full border-2 border-green-400 transition duration-500 ease-in-out'
                  : 'flex h-12 w-12 items-center justify-center  text-base font-normal text-tertiary-100 '
              }
            >
              <Link href="/dashboard?email=1">
                <UntranslatedIcon className="h-9 w-9" />
              </Link>
            </li>
            <li
              onClick={onClickCorrecting}
              className={
                correcting
                  ? 'flex h-12 w-12 items-center justify-center rounded-full border-2 border-green-400 transition duration-500 ease-in-out'
                  : 'flex h-12 w-12 items-center justify-center  text-base font-normal text-tertiary-100  '
              }
            >
              <Link href="dashboard?email=2">
                <CorrectingIcon className="h-9 w-9" />
              </Link>
            </li>
            <li
              onClick={onClickMicro}
              className={
                micro
                  ? 'flex h-12 w-12 items-center justify-center rounded-full border-2 border-green-400 transition duration-500 ease-in-out'
                  : 'flex h-12 w-12 items-center justify-center  text-base font-normal text-tertiary-100  '
              }
            >
              <Link href="dashboard?email=3">
                <MicroIcon className="h-9 w-9" />
              </Link>
            </li>
          </ul>
        </div>
      </aside>
      <div className="grow-[9]">{children}</div>
    </div>
  );
};

export default SideMenu;
