'use client';
import { Transition } from '@headlessui/react';
import { BaloonBigIcon, BaloonSmallIcon } from 'lib/Icons';
import { FC, Fragment } from 'react';
import { cn } from 'utils/cn';

interface ILoadingLogo {
  size?: 'x-small' | 'small' | 'default';
  show?: boolean;
  className?: string;
}

const LoadingLogo: FC<ILoadingLogo> = ({ size = 'small', show = true, className }) => {
  const scale = {
    'x-small': 'scale-[0.6]',
    small: 'scale-[0.8]',
    default: 'scale-[1.2]'
  };

  const spacing = {
    'x-small': 'left-10',
    small: 'left-8',
    default: 'left-2'
  };

  return (
    <Transition
      show={show}
      as={Fragment}
      enter={'transition ease-out duration-200'}
      enterFrom={`opacity-0 translate-y-1`}
      enterTo={'opacity-100 translate-y-0'}
      leave={'transition ease-out duration-[0.5s]'}
      leaveFrom={'opacity-100 translate-y-0'}
      leaveTo={`opacity-0 translate-y-1`}
    >
      <div className={'my-auto flex h-full w-full flex-1 flex-col items-center justify-center'}>
        <div
          className={cn('mr-6 flex h-full w-full flex-1 items-center justify-center', className)}
        >
          <span className={cn('relative bottom-8', spacing[size])}>
            <div className="animate-bounce-re">
              <BaloonBigIcon className={scale[size]} />
            </div>
          </span>
          <div className="z-20 animate-bounce">
            <BaloonSmallIcon className={scale[size]} />
          </div>
        </div>
      </div>
    </Transition>
  );
};

export default LoadingLogo;
