'use client';
import { CheckedIcon } from 'lib/Icons';
import { cn } from 'utils/cn';

import Typography from 'components/Typography';
import { FC, ReactNode } from 'react';
import { FieldError } from 'react-hook-form';
import './Checkbox.scss';

interface CheckboxButtonProps {
  /*
   * Error of the input
   * accepts FieldError
   *
   * @example
   * {
   *  type: 'required',
   * message: 'This field is required'
   * }
   *
   */
  error?: FieldError;
  /**
   * What is the name of the button?
   */
  name?: string;
  /**
   *Checkbox label
   */
  label?: string | ReactNode;
  /**
   * Checkbox checked?
   */
  checked?: boolean;
  /*
   * StartAdornment of the input
   */
  startAdornment?: ReactNode;
  /**
   * Checkbox is disabled?
   */
  disabled?: boolean;
  /**
   * Optional change handler
   */
  onChange: (value: boolean) => void;
  /**
   * Classname for the checkbox
   */
  className?: string;
}
const Checkbox: FC<CheckboxButtonProps> = ({
  label,
  checked = false,
  startAdornment,
  onChange,
  name,
  disabled = false,
  error,
  className
}) => {
  const handleOnChange = () => onChange(!checked);

  return (
    <div className={cn('select-none', className)}>
      <input
        type="checkbox"
        id={name}
        className={cn('hidden')}
        defaultChecked={checked}
        disabled={disabled}
      />
      <label
        className={cn('container-checkbox-button mt-1', startAdornment ? 'gap-2' : 'gap-4')}
        htmlFor={name}
        onClick={!disabled ? handleOnChange : undefined}
      >
        <span
          className={cn(
            !error
              ? cn('checkbox-button', checked ? 'checkbox-active' : 'checkbox-inactive')
              : 'error-input-checkbox'
          )}
        >
          <CheckedIcon
            className={cn(
              'ml-[2px] mt-[2px] h-[15px] w-[15px] pt-[2px]',
              'transition-all duration-300',
              checked ? 'scale-100' : 'scale-0'
            )}
          />
        </span>
        {(startAdornment || label) && (
          <span className={cn('flex  items-center', startAdornment ? 'gap-2' : '')}>
            {startAdornment && <span className="flex justify-center">{startAdornment}</span>}
            {typeof label !== 'object' ? (
              <Typography
                variant="regular4"
                className={disabled ? 'text-tertiary-400' : 'text-tertiary-100 dark:text-white'}
              >
                {label}
              </Typography>
            ) : (
              <>{label}</>
            )}
          </span>
        )}
      </label>
    </div>
  );
};

export default Checkbox;
