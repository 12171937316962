import { Auth } from '@aws-amplify/auth';
import { configAws } from 'constants/aws';
import { LocalAuth } from 'lib/LocalAuth';
import { useMemo } from 'react';

const useAuth = () => {
  const auth = useMemo(() => {
    if (process.env.NEXT_PUBLIC_LOCAL_AUTH === 'true') return new LocalAuth();

    Auth.configure(configAws.Auth);

    return Auth;
  }, []);

  return auth;
};

export default useAuth;
