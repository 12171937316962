import Button, { ColorButton } from 'components/Buttons/Button';
import Snackbar from 'components/Snackbar';
import { FC } from 'react';

import { ToastContentProps, TypeOptions } from 'react-toastify';
import Typography from '../Typography';
import { TMsgs } from './';
import MsgIconToast from './MsgToastIcon';

export type IPopToast = TMsgs;

const PopToast: FC<ToastContentProps<IPopToast>> = ({
  data: { type = 'default', copy, title, content, action: { link, label } = {} }
}) => {
  const colorButton = {
    info: 'secondary',
    success: 'primary',
    warning: 'danger',
    error: 'danger',
    default: 'primary'
  } as Record<TypeOptions, ColorButton>;
  return (
    <div className="flex cursor-pointer flex-col">
      <div className="mb-2">
        <MsgIconToast type={type} />
      </div>
      {title && (
        <div className="mb-2">
          <Typography
            variant="medium1"
            className="cursor-pointer select-none leading-8 text-tertiary-100 dark:text-white"
          >
            {title}!
          </Typography>
        </div>
      )}
      {copy && (
        <div className="mb-2">
          <Typography variant="light3" className="cursor-pointer select-none text-tertiary-200">
            <span dangerouslySetInnerHTML={{ __html: copy ?? '' }}></span>
          </Typography>
        </div>
      )}
      {content && (
        <Snackbar
          noIcon
          className="mb-4"
          variant={type}
          description={
            <Typography
              variant="light4"
              className="cursor-pointer select-none leading-4 text-tertiary-200"
            >
              <span dangerouslySetInnerHTML={{ __html: content ?? '' }}></span>
            </Typography>
          }
        />
      )}
      {link && label && (
        <a href={link}>
          <Button variant="contained" color={colorButton[type]} label={label} />
        </a>
      )}
    </div>
  );
};

export default PopToast;
