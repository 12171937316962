'use client';
import { Typography } from 'components';

import { FC, PropsWithChildren } from 'react';
import { cn } from 'utils/cn';

interface INoResults {
  className?: string;
}

const NoResults: FC<PropsWithChildren<INoResults>> = ({ children, className }) => (
  <div className={cn('flex h-1/3 flex-1 justify-center', className)}>
    <Typography className="text-quaternary-700" variant={'medium1'}>
      {children}
    </Typography>
  </div>
);

export default NoResults;
