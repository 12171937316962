'use client';

import { Comment, Maybe } from 'gql/graphql';
import { useState } from 'react';
import CommentsMain from './CommentsMain';
import Onboarding from './Onboarding';

export enum StatusTags {
  Blue = 'blue',
  BlueLight = 'blue-light',
  Green = 'green',
  GreenLight = 'green-light',
  Orange = 'orange',
  OrangeLight = 'orange-light',
  Null = ''
}

export interface IComments {
  isCommentate?: boolean;
  isCorrectable?: boolean;
  comments?: Maybe<Array<Comment>>;
  tags?: string[];
  hoveredTags?: string[];
  isLoadingComment?: boolean;
  isLoadingCorrection?: boolean;
  updateTags?: (tags: string[]) => void;
  onClickTag?: (tag: string) => void;
  updateStatusTags?: (statusTags: StatusTags) => void;
  markAsRead?: (id: string) => void;
  updateHoveredTags?: (tags: string[]) => void;
  addCorrections: (comment: string, tags: string[]) => void;
  addComment: (comment: string, tags: string[]) => void;
}

export const themeColorTag = (isCorrection?: boolean, writtenByClient?: boolean) => {
  if (isCorrection) return 'bg-secondary-yellow';

  if (writtenByClient) return 'bg-secondary-green';

  return 'bg-secondary-blue';
};

const Comments = ({
  comments,
  isCommentate = true,
  isCorrectable = true,
  tags = [],
  hoveredTags = [],
  isLoadingComment,
  isLoadingCorrection,
  updateTags,
  updateHoveredTags,
  updateStatusTags,
  onClickTag,
  addCorrections,
  addComment,
  markAsRead
}: IComments) => {
  const [start, setStart] = useState(false);

  const handleStartConversation = () => setStart(true);

  if (!start && !comments?.length)
    return <Onboarding startConversation={handleStartConversation} />;

  return (
    <CommentsMain
      comments={comments}
      isCommentate={isCommentate}
      isCorrectable={isCorrectable}
      tags={tags}
      hoveredTags={hoveredTags}
      isLoadingComment={isLoadingComment}
      isLoadingCorrection={isLoadingCorrection}
      addComment={addComment}
      addCorrections={addCorrections}
      updateTags={updateTags}
      onClickTag={onClickTag}
      updateHoveredTags={updateHoveredTags}
      updateStatusTags={updateStatusTags}
      markAsRead={markAsRead}
    />
  );
};

export default Comments;
