'use client';

import { FC, PropsWithChildren, ReactNode, useState } from 'react';
import { PopoverProps } from 'react-tiny-popover';
import BasicPopover from './BasicPopover';

interface IHoverPopover extends Omit<PopoverProps, 'content'> {
  msg: string | ReactNode;
}

const HoverPopover: FC<PropsWithChildren<Pick<IHoverPopover, 'msg' | 'positions'>>> = ({
  children,
  msg,
  positions = ['bottom']
}) => {
  const [hovering, setHovering] = useState(false);

  const handleMouseEnter = () => setHovering(true);
  const handleMouseLeave = () => setHovering(false);

  return (
    <BasicPopover isOpen={hovering} msg={msg} positions={positions}>
      <span onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
        {children}
      </span>
    </BasicPopover>
  );
};

export default HoverPopover;
