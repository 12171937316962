import { useQuery, UseQueryOptions } from '@tanstack/react-query';
import { gql } from 'graphql-request';
import { TGenericError } from 'types/TGenericError';
import clientUtil from 'utils/clientUtil';

type TData = {
  currentUser: {
    id: string;
  };
  clientAccount: {
    id: string;
  };
  realtime: {
    socketUrl: string;
    token: string;
  };
};

export const useSocket = (
  options: Omit<UseQueryOptions<unknown, TGenericError, TData>, 'queryKey'> = {}
) => {
  const client = clientUtil();

  const graphql = gql`
    query socket {
      currentUser {
        id # Realtime user ID
      }
      clientAccount {
        id # Realtime account ID
      }
      realtime {
        socketUrl
        token
      }
    }
  `;

  return useQuery<unknown, TGenericError, TData>({
    ...options,
    queryKey: ['useSocket'],
    queryFn: async () =>
      client.request({
        data: {
          gql: graphql
        }
      })
  });
};
