export enum FeatureFlagEnum {
  DarkMode = 'dark_mode',
  NewStyleGuides = 'new_style_guides',
  NewBillings = 'new_billings',
  NewIntegrations = 'new_integrations',
  Projects = 'projects',
  TolqAi = 'tolq_ai',
  ContentGeneration = 'content_generation',
  ImproveContentGeneration = 'improve_content_generation',
  IntegrationHubSpot = 'integration_hub_spot',
  IntegrationGoogle = 'integration_google',
  AiLlama = 'ai_llama',
  Amplify = 'amplify'
}

export type FeatureFlags = {
  [Key in FeatureFlagEnum as `${Uncapitalize<
    string & keyof typeof FeatureFlagEnum
  >}Enabled`]: boolean;
};
