import { uniq } from 'underscore';
import { FlagHighlightRegExp, highlightRanges, TypeHighlightRegExp } from 'utils/regexp';

export const HIGHLIGHT_CLASS = 'segment-highlighted-';

type Segment = {
  copy: string;
  highlighted: boolean;
};

export const isHighlightedRange = (from: number, to: number, highlightedRanges: any[]) =>
  highlightedRanges.find((r) => r.from === from && r.to === to) !== undefined;

export const segmentFragments = (
  text: string,
  searchQuery: string,
  fullMatch?: boolean,
  type?: TypeHighlightRegExp,
  flags?: FlagHighlightRegExp
) => {
  if (fullMatch) return [{ copy: text, highlighted: text === searchQuery }] as Segment[];

  const highlightedRanges = highlightRanges(text, searchQuery, type, flags);
  const hFroms = highlightedRanges.map((h) => h.from);
  const hTos = highlightedRanges.map((h) => h.to);
  const divisions = [0, text.length, ...hFroms, ...hTos].filter(uniq).sort((a, b) => a - b);

  return divisions.reduce<Segment[]>((acc, from, index) => {
    if (index === divisions.length - 1) return acc;

    const to = divisions[index + 1];

    return [
      ...acc,
      {
        copy: text.slice(from, to),
        highlighted: isHighlightedRange(from, to, highlightedRanges)
      }
    ];
  }, []);
};

export const factoryClassName = (
  highlighted: boolean,
  highlightedColor: string,
  isSelected: boolean
) => {
  const mainSelection = highlighted && isSelected && `${HIGHLIGHT_CLASS}orange`;

  const selection = highlighted && `${HIGHLIGHT_CLASS}${highlightedColor}`;

  return mainSelection || selection || '';
};
