'use client';

import { Disclosure, Transition } from '@headlessui/react';
import { Typography } from 'components';
import Arrow from 'components/Arrow';
import { ChevronIcon } from 'lib/Icons';

import { FC, PropsWithChildren } from 'react';
import { cn } from 'utils/cn';

interface IAccordion {
  title?: string;
  arrowBefore?: boolean;
  className?: string;
  classNameChildren?: string;
  classNamePanel?: string;
  classNameDisclosure?: string;
  classNameContainer?: string;
  classNameTitle?: string;
  defaultOpen?: boolean;
}

const Accordion: FC<PropsWithChildren<IAccordion>> = ({
  children,
  title,
  defaultOpen,
  arrowBefore,
  className,
  classNameChildren,
  classNamePanel,
  classNameDisclosure,
  classNameContainer,
  classNameTitle
}) => {
  return (
    <div className={cn('my-4 w-full', className)}>
      <div className={cn('w-full max-w-md', classNameContainer)}>
        <Disclosure defaultOpen={defaultOpen}>
          {({ open }) => (
            <div
              className={cn(
                'rounded-5xl transition-colors duration-200 ease-in-out',
                open ? 'bg-primary-blue-900' : 'bg-white',
                open && 'border-2 border-primary-blue-300 outline-none',
                classNameDisclosure
              )}
            >
              <Disclosure.Button
                className={cn(
                  'transition-colors duration-200 ease-in-out',
                  'group flex w-full items-center justify-between',
                  'text-left text-sm font-medium',
                  'focus-visible:ring-offset-primary-blue-500/75',
                  'focus:outline-none focus-visible:ring',
                  'py-2 pl-4 pr-2',
                  'text-tertiary-100',
                  classNameChildren,
                  arrowBefore ? 'justify-start' : 'justify-between',
                  open
                    ? 'rounded-t-3xl bg-primary-blue-900 hover:bg-primary-blue-600'
                    : 'rounded-4xl bg-primary-blue-600 hover:bg-primary-blue-900'
                )}
              >
                {arrowBefore && (
                  <span className="mb-0.5 mr-4">
                    <ChevronIcon
                      className={cn(
                        'transition-rotate h-7 w-7 cursor-pointer text-primary-blue-100 duration-500 ease-in-out',
                        open ? 'mt-1 rotate-0' : '-rotate-90'
                      )}
                    />
                  </span>
                )}
                <Typography variant="regular3" className={classNameTitle}>
                  {title}
                </Typography>
                {!arrowBefore && <Arrow open={open} className="fill-tertiary-100" />}
              </Disclosure.Button>
              <Transition
                enter="transition duration-100 ease-out"
                enterFrom="transform scale-95 opacity-0"
                enterTo="transform scale-100 opacity-100"
                leave="transition duration-75 ease-out"
                leaveFrom="transform scale-100 opacity-100"
                leaveTo="transform scale-95 opacity-0"
              >
                <Disclosure.Panel className={cn('p-4 text-sm', classNamePanel)}>
                  {children}
                </Disclosure.Panel>
              </Transition>
            </div>
          )}
        </Disclosure>
      </div>
    </div>
  );
};

export default Accordion;
