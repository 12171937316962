'use client';
import { Tab } from '@headlessui/react';
import { Entering, Typography } from 'components';

import { useHashState } from 'hooks/useHash';
import {
  cloneElement,
  FC,
  Fragment,
  HTMLAttributes,
  ReactElement,
  useCallback,
  useEffect,
  useMemo,
  useState
} from 'react';
import { cn } from 'utils/cn';
import TabCustom from './TabCustom';

interface ITabBar extends Omit<HTMLAttributes<HTMLElement>, 'children'> {
  /**
   * Text contents
   */
  isActive?: boolean;
  /**
   * Active tab index
   */
  activeTabIndex?: number;
  /**
   * Tabs contents
   */
  tabs: {
    label: string;
    className?: '';
    onClick?: (index?: number) => void;
    href?: string;
    hash?: string;
  }[];
  /**
   * Button disabled
   */
  disabled?: boolean;
  /**
   * Optional click handler
   */
  onClick?: () => void;
  /**
   * Notification object with position and number of notifications.
   */
  notification?: { index: number; count: number };
  /**
   * Scale variant a number that comprehend the major of the variant
   */
  scaleVariant?: string;
  /**
   * AddPadding adds padding in y of 16px
   */
  addPadding?: boolean;
  /**
   * SmallGap reduce the size of gap
   */
  smallGap?: boolean;
  /**
   * Badges style of tags
   */
  badges?: boolean;
  /**
   * classNameGroup
   */
  noRedirect?: boolean;
  /**
   * classNameGroup
   */
  classNameGroup?: string;
  /**
   * Children custom
   */
  children?:
    | ReactElement<{ selectedIndex: number }>
    | ((props: { selectedIndex: number }) => ReactElement);
}
[];

const TabBar: FC<ITabBar> = ({
  tabs,
  activeTabIndex,
  scaleVariant,
  addPadding,
  smallGap,
  badges,
  notification,
  children,
  classNameGroup,
  noRedirect,
  ...rest
}) => {
  const [selectedIndex, setSelectedIndex] = useState(0);

  const [hash, setHash] = useHashState({ noRedirect });

  const allTabsHaveHash = useMemo(() => tabs.every(({ hash }) => hash), [tabs]);

  const isFunctionChildren = typeof children === 'function';

  const handleHash = useCallback(() => {
    if (allTabsHaveHash && !hash)
      return setHash(tabs?.at(activeTabIndex ?? selectedIndex)?.hash ?? '');
  }, [activeTabIndex, allTabsHaveHash, hash, selectedIndex, setHash, tabs]);

  useEffect(() => {
    if (
      typeof activeTabIndex !== 'undefined' &&
      !(activeTabIndex < 0 || activeTabIndex > tabs.length - 1)
    )
      return setSelectedIndex(activeTabIndex ?? 0);
  }, [activeTabIndex, tabs.length]);

  useEffect(() => {
    handleHash();
  }, [handleHash]);

  return (
    <div className={'mt-6'} {...rest}>
      {!!tabs?.length && (
        <Entering offset={5} delay={0.4} duration={0.2} position="top" className={classNameGroup}>
          <Tab.Group selectedIndex={selectedIndex} onChange={setSelectedIndex}>
            <Tab.List
              className={cn(
                'mb-0 flex dark:text-white',
                smallGap ? ' gap-0' : 'gap-4',
                addPadding ? 'px-4' : '',
                badges ? 'flex-wrap gap-2' : 'border-0 border-b-1 border-tertiary-400'
              )}
            >
              {tabs.map(({ label, href, onClick }, index) => (
                <Fragment key={label + 'tab-custom' + index}>
                  <TabCustom
                    index={index}
                    label={label}
                    href={href}
                    onClick={onClick}
                    scaleVariant={scaleVariant}
                    smallGap={smallGap}
                    badges={badges}
                  />
                  {index === notification?.index && !!notification?.count && (
                    <div className="mt-[2px]">
                      <div className="flex h-5 w-5 items-center justify-center rounded-10xl bg-secondary-red px-2">
                        <Typography
                          variant="medium5"
                          className="mb-2 !px-0 !pt-0 pb-2 text-center text-white"
                        >
                          {notification?.count}
                        </Typography>
                      </div>
                    </div>
                  )}
                </Fragment>
              ))}
            </Tab.List>
          </Tab.Group>
        </Entering>
      )}
      {!!tabs?.length && (
        <>
          {isFunctionChildren && <>{children({ selectedIndex })}</>}
          {!isFunctionChildren && <>{cloneElement(children ?? <></>, { selectedIndex })}</>}
        </>
      )}
    </div>
  );
};

export default TabBar;
