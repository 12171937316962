'use client';

import { FC, PropsWithChildren } from 'react';
import { cn } from 'utils/cn';

import './styles.scss';

interface IFloatingFooter {
  disabled?: boolean;
}

const FloatingFooter: FC<PropsWithChildren<IFloatingFooter>> = ({ disabled, children }) => (
  <div
    className={cn('floating-footer shadow-custom-dark', 'transition-all  duration-500 ease-in-out')}
    style={{
      bottom: disabled ? -130 : 0
    }}
  >
    <div className="flex w-full flex-1">
      <div className="ml-60 w-full md:mx-4 lg:mx-8 ">
        <div className="flex h-full w-full items-center justify-center">
          <div className="w-full max-w-7xl 3xl-max:mr-20">{children}</div>
        </div>
      </div>
    </div>
  </div>
);

export default FloatingFooter;
