'use client';

import { Switch } from '@headlessui/react';
import { FC, useEffect, useState } from 'react';

interface IToggleButton {
  enabled?: boolean;
  disabled?: boolean;
  onClick?: (enabled: boolean) => void;
}

const ToggleButton: FC<IToggleButton> = ({ enabled: enabledIncoming, disabled, onClick }) => {
  const [enabled, setEnabled] = useState(enabledIncoming);

  const handleOnClick = () => {
    setEnabled(!enabled);
    onClick?.(!enabled);
  };

  useEffect(() => {
    setEnabled(enabledIncoming);
  }, [enabledIncoming]);

  return (
    <Switch
      checked={enabled}
      disabled={disabled}
      onChange={disabled ? undefined : handleOnClick}
      className={`h-6 w-10 ${
        enabled ? 'bg-primary-green-100' : ' bg-tertiary-600'
      } relative inline-flex h-6 w-11 items-center rounded-full`}
    >
      <span
        className={`${
          enabled ? 'translate-x-6 bg-white' : 'translate-x-1 bg-white'
        } inline-block h-4 w-4 rounded-full bg-white transition`}
      />
    </Switch>
  );
};

export default ToggleButton;
